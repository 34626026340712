const headers = {
  name: "Item brands",
  components: [
    {
      type: "search",
      name: "search",
      placeholder: "Search",
      value: "",
    },
    {
      type: "filter",
      name: "status",
      allowClear: true,
      showSearch: false,
      filterOption: true,
      placeholder: "Status",
      value: null,
      options: [
        { value: 0, label: "Inactive" },
        { value: 1, label: "Active" },
      ],
    },
    {
      type: "add",
      path: "/app/items/brands/add",
    },
  ],
  navigate: {
    tabs: [
      {
        name: "Items",
        path: "/app/items",
      },
      {
        name: "Units",
        path: "/app/items/units",
      },
      {
        name: "Categories",
        path: "/app/items/categories",
      },
      {
        name: "Brands",
        path: "",
      },
    ],
    total: [
      { value: 0, label: "Total" },
      { value: 0, label: "Active" },
      { value: 0, label: "Inactive" },
    ],
  },
};

export default headers;
