import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_item_category = async (category) => {
  try {
    const data = {
      name: category?.name,
      status: category?.status,
      branch: category?.branch,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/create-item-category`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_item_category = async (category) => {
  try {
    const data = {
      id: category?.id,
      name: category?.name,
      status: category?.status,
      branch: category?.branch,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/update-item-category`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_item_category = async (category) => {
  try {
    const data = {
      id: category?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-item-category`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_item_categories = async (category) => {
  try {
    const data = {
      search: category?.search,
      status: category?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-item-categories`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_item_category_log = async (category) => {
  try {
    const data = {
      search: category?.search,
      sort: category?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-item-category-log`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_item_category_logs = async (category) => {
  try {
    const data = {
      search: category?.search,
      sort: category?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-item-category-logs`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export {
  create_item_category,
  update_item_category,
  get_item_category,
  get_all_item_categories,
  get_item_category_log,
  get_all_item_category_logs,
};
