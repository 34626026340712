import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_project_job = async (project_job) => {
  try {
    let data = new FormData();
    data.append("project", project_job?.project);
    data.append("image", project_job?.image[0]);
    data.append("name", project_job?.name);
    data.append("description", project_job?.description);
    data.append("number", project_job?.number);
    data.append("status", project_job?.status);

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/create-project-job`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_project_job = async (project_job) => {
  try {
    let data = new FormData();
    data.append("id", project_job?.id);
    data.append("project", project_job?.project);
    data.append("image", project_job?.image[0]);
    data.append("name", project_job?.name);
    data.append("description", project_job?.description);
    data.append("number", project_job?.number);
    data.append("status", project_job?.status);

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/update-project-job`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_project_job = async (project_job) => {
  try {
    const data = {
      id: project_job?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-project-job`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_project_jobs = async (project_job) => {
  try {
    const data = {
      project: project_job?.project,
      search: project_job?.search,
      item: project_job?.item,
      employee: project_job?.employee,
      project_job_status: project_job?.project_job_status,
      status: project_job?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-project-jobs`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export {
  create_project_job,
  update_project_job,
  get_project_job,
  get_all_project_jobs,
};
