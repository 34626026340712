import {
  CalendarOutlined,
  EnvironmentOutlined,
  FormOutlined,
  MailOutlined,
  PhoneOutlined,
  UpCircleFilled,
  UserOutlined,
} from "@ant-design/icons";
import React from "react";
import { Progress } from "antd";
import DotIcon from "../../../../Assets/Svg/dot";
import { useNavigate } from "react-router-dom";
import URL from "../../../../Main/url";
import DefaultImage from "../../../../Assets/Images/defaultimage.png";
import moment from "moment";
import ActionButton from "../../../../Componets/Buttons/action";
import Status from "../../../../Componets/Status";

const Projecttable = (props) => {
  let navigate = useNavigate();

  const body = props?.body;
  const actiondata = props?.actiondata;

  return (
    <table className="w-full">
      <tbody className="">
        {body?.map((value, index) => {
          return (
            <tr
              className="m-0 p-0 project"
              onClick={(e) => {
                // navigate(`/app/projects/view/${value?.id}`);
                navigate(`/app/projects/jobs/${value?._id}`);
              }}
            >
              <td className="m-0 p-0 ">
                <div className="p-4 bg-white rounded-tl-md rounded-bl-md my-2 cursor-pointer card-hover">
                  <div className="flex">
                    {/* image */}
                    <div className="">
                      <img
                        src={
                          value?.image
                            ? `${URL}/uploads${value?.image}`
                            : DefaultImage
                        }
                        className="rounded-md w-[130px] h-[130px]"
                      />
                    </div>
                    {/* name & customer*/}
                    <div className="px-4">
                      {/* name */}
                      <div>
                        <div>
                          <span className="pr-2 text-black">{value?.name}</span>
                          <span className="bg-gray-200 text-sm px-2 py-1 rounded-lg text-gray-500">
                            #PNO{value?.number}
                          </span>
                        </div>
                      </div>
                      {/* customer */}
                      <div className="pt-2 text-gray-700">
                        {/* customer name */}
                        <div className="flex">
                          <div>
                            <UserOutlined />
                          </div>
                          <div className="text-sm px-2">
                            {value?.customer?.name
                              ? value?.customer?.name
                              : "N/A"}
                          </div>
                        </div>
                        {/* customer contact */}
                        <div className="flex">
                          <div>
                            <div className="flex">
                              <div className="">
                                <PhoneOutlined
                                  style={{ transform: "rotate(90deg)" }}
                                />
                              </div>
                              <div className="text-sm px-2">
                                {value?.customer?.phone
                                  ? value?.customer?.phone
                                  : "N?A"}
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="flex pl-2">
                              <div className="">
                                <MailOutlined />
                              </div>
                              <div className="text-sm px-2">
                                {value?.customer?.email
                                  ? value?.customer?.email
                                  : "N/A"}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* customer address */}
                        <div className="flex">
                          <div>
                            <EnvironmentOutlined />
                          </div>
                          <div className="text-sm px-2">
                            {value?.area && value?.area + ", "}
                            {value?.city && value?.city + ", "}
                            {value?.state && value?.state + ", "}
                            {value?.country && value?.country}
                          </div>
                        </div>
                        {/* date */}
                        <div className="flex">
                          <div>
                            <CalendarOutlined />
                          </div>
                          <div className="text-sm px-2 flex">
                            <div>
                              {value?.start_date
                                ? moment?.(value?.start_date)?.format(
                                    "MMMM Do YYYY"
                                  )
                                : "N/A"}
                              &nbsp;-&nbsp;{" "}
                              {value?.end_date
                                ? moment?.(value?.end_date)?.format(
                                    "MMMM Do YYYY"
                                  )
                                : "N/A"}
                            </div>
                            <div className="ml-2">
                              {new Date(value?.end_date) < new Date() ? (
                                <span className="px-2 text-xs text-medium bg-rose-500 text-white rounded-full">
                                  Delayed
                                </span>
                              ) : (
                                <span className="px-2 text-xs text-medium bg-green-500 text-white rounded-full">
                                  Ontime
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              {/* project cost */}
              <td className="m-0 p-0">
                <div className="p-4 bg-white card-hover">
                  <div className="h-[130px]">
                    <div className="text-gray-500">Est. expense</div>
                    <div className="pt-3 text-gray-700">
                      {value?.estimated
                        ? value?.estimated?.toFixed(3)
                        : "0.000"}
                    </div>
                  </div>
                </div>
              </td>
              {/* project revenue */}
              <td className="m-0 p-0">
                <div className="p-4 bg-white card-hover">
                  <div className="h-[130px]">
                    <div className="text-gray-500">Expense</div>
                    <div className="pt-3 text-gray-700">
                      {value?.expense ? value?.expense?.toFixed(3) : "0.000"}
                    </div>
                  </div>
                </div>
              </td>
              {/* progress */}
              <td className="m-0 p-0">
                <div className="p-4 bg-white card-hover">
                  <div className="h-[130px]">
                    <div className="text-gray-500">Progress</div>
                    <div className="pt-3 flex text-gray-700 ">
                      <div>
                        <Progress
                          type="circle"
                          percent={value?.progress ? value?.progress : 0}
                          size={25}
                          format={(percent) => ``}
                          strokeColor="#10B981"
                          strokeWidth={16}
                        />
                      </div>
                      <div className="my-auto px-2">
                        {value?.progress ? value?.progress : 0}%
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              {/* project status */}
              <td className="m-0 p-0">
                <div className="p-4 bg-white card-hover">
                  <div className="h-[130px]">
                    <div className="text-gray-500">Status</div>
                    <div className="pt-3 text-gray-700">
                      {<Status status={value?.status} />}
                    </div>
                  </div>
                </div>
              </td>
              {/* actions */}
              <td className="m-0 p-0">
                <div className="p-4 bg-white rounded-tr-md rounded-br-md card-hover">
                  <div className="h-[130px] flex flex-col justify-between">
                    <div className="text-end">
                      <ActionButton
                        edit={`/app/projects/edit/${value?._id}`}
                        id={value?._id}
                        api="delete-project"
                        actiondata={actiondata}
                      />
                    </div>
                    <div className="text-end text-sm">
                      {value?.project_status && (
                        <>
                          <span
                            role="img"
                            aria-label="up-circle"
                            className={`anticon anticon-up-circle ${
                              value?.project_status == 1
                                ? "text-orange-500"
                                : value?.project_status == 2
                                ? "text-cyan-500"
                                : value?.project_status == 3
                                ? "text-green-500"
                                : "text-red-500"
                            }`}
                          >
                            <DotIcon />
                          </span>
                          <span className="pl-1">
                            {value?.project_status == 1
                              ? "Pending"
                              : value?.project_status == 2
                              ? "Ongoing"
                              : value?.project_status == 3
                              ? "Completed"
                              : "Cancelled"}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Projecttable;
