import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import toast from "react-hot-toast";
import Header from "../../../../Componets/Header";
import {
  create_item,
  get_item,
  update_item,
} from "../../../../Controllers/Items/Items";
import forms from "../../../../Data/Forms/Items/Categories/forms";
import headers from "../../../../Data/Forms/Items/Categories/headers";
import {
  create_item_category,
  get_item_category,
  update_item_category,
} from "../../../../Controllers/Items/Categories";
import Loadercomponent from "../../../../Componets/Loader";

const CategoriesForm = () => {
  let { id } = useParams();

  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
      setButtonLoader(true);

      const category = id
        ? await update_item_category({ id: id, ...data?.data })
        : await create_item_category(data?.data);

      if (category?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(category?.message);
        setButtonLoader(false);
      } else {
        toast?.error(category?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = (category) => {
    let name = category?.name ? category?.name : "";
    let status = category?.status ? category?.status : 0;

    forms[0].options[0].form.value = name;
    forms[1].options[0].form.value = status;

    setLoader(false);
    setValues([...values]);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      headers.navigate.path[1].name = "Edit";

      const data = {
        id: id,
      };

      const category = await get_item_category(data);

      if (category?.status) {
        handleData(category?.data);
      } else {
        toast?.error(category?.message);
      }
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        />
      </Loadercomponent>
    </Header>
  );
};

export default CategoriesForm;
