const forms = [
  {
    name: "Details",
    options: [
      {
        name: "Name",
        type: "input",
        form: {
          type: "text",
          name: "name",
          placeholder: "User name",
          class: "",
          value: "",
          required: true,
        },
      },
    ],
  },
];

export default forms;
