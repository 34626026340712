import { Progress } from "antd";
import React from "react";

const Progresscircle = (props) => {
  let percentage = props?.percentage ? props?.percentage : 0;

  return (
    <div className="flex">
      <div>
        <Progress
          type="circle"
          percent={percentage}
          size={25}
          format={(percent) => ``}
          strokeColor="#10B981"
          strokeWidth={16}
        />
      </div>
      <div className="my-auto px-2">{percentage}%</div>
    </div>
  );
};

export default Progresscircle;
