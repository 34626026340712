import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import toast from "react-hot-toast";
import Header from "../../../../Componets/Header";
import forms from "../../../../Data/Forms/Suppliers/Suppliers/forms";
import headers from "../../../../Data/Forms/Suppliers/Suppliers/headers";
import Loadercomponent from "../../../../Componets/Loader";
import {
  create_supplier,
  get_supplier,
  update_supplier,
} from "../../../../Controllers/Suppliers";

const SuppliersForm = () => {
  let { id } = useParams();

  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
      setButtonLoader(true);

      const suppliers = id
        ? await update_supplier({ id: id, ...data?.data })
        : await create_supplier(data?.data);

      if (suppliers?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(suppliers?.message);
        setButtonLoader(false);
      } else {
        toast?.error(suppliers?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = (supplier) => {
    let name = supplier?.name ? supplier?.name : "";
    let email = supplier?.email ? supplier?.email : "";
    let phone = supplier?.phone ? supplier?.phone : "";
    let tax = supplier?.tax ? supplier?.tax : 0;
    let area = supplier?.area ? supplier?.area : "";
    let city = supplier?.city ? supplier?.city : "";
    let state = supplier?.state ? supplier?.state : "";
    let country = supplier?.country ? supplier?.country : "";
    let status = supplier?.status ? supplier?.status : 0;

    let catalog = [];
    let catalog_options = [];
    if (supplier?.catalog?.length > 0) {
      supplier?.catalog?.map((value, index) => {
        catalog?.push(value?._id);
        catalog_options?.push({
          value: value?._id,
          label: value?.name,
        });
      });
    } else {
      catalog_options = null;
      catalog = null;
    }

    values[0].options[0].form.value = name;

    values[1].options[0].form.value = email;
    values[1].options[1].form.value = phone;

    values[2].options[0].form.value = catalog;
    values[2].options[0].form.options = catalog_options;

    values[3].options[0].form.value = tax;

    values[4].options[0].form.value = area;
    values[4].options[1].form.value = city;
    values[4].options[2].form.value = state;
    values[4].options[3].form.value = country;

    values[5].options[0].form.value = status;

    setLoader(false);
    setValues([...values]);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      headers.navigate.path[1].name = "Edit";

      const data = {
        id: id,
      };

      const suppliers = await get_supplier(data);

      if (suppliers?.status) {
        handleData(suppliers?.data);
      } else {
        toast?.error(suppliers?.message);
      }
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [buttonLoader]);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        />
      </Loadercomponent>
    </Header>
  );
};

export default SuppliersForm;
