const headers = {
  name: "Sales Invoice",
  navigate: {
    path: [
      {
        name: "Invoices",
        path: "/app/sales",
      },
      {
        name: "Add",
        path: "",
      },
    ],
  },
};

export default headers;
