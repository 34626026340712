import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import toast from "react-hot-toast";
import Header from "../../../../Componets/Header";
import forms from "../../../../Data/Forms/Items/Units/forms";
import headers from "../../../../Data/Forms/Items/Units/headers";
import {
  create_item_unit,
  get_item_unit,
  update_item_unit,
} from "../../../../Controllers/Items/Units";
import dayjs from "dayjs";
import Loadercomponent from "../../../../Componets/Loader";

const UnitsForm = () => {
  let { id } = useParams();

  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
      setButtonLoader(true);

      const unit = id
        ? await update_item_unit({ id: id, ...data?.data })
        : await create_item_unit(data?.data);

      if (unit?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(unit?.message);
        setButtonLoader(false);
      } else {
        toast?.error(unit?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = (unit) => {
    let name = unit?.name ? unit?.name : "";
    let status = unit?.status ? unit?.status : 0;

    forms[0].options[0].form.value = name;
    forms[1].options[0].form.value = status;

    setLoader(false);
    setValues([...values]);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      headers.navigate.path[1].name = "Edit";

      const data = {
        id: id,
      };

      const unit = await get_item_unit(data);

      if (unit?.status) {
        handleData(unit?.data);
      } else {
        toast?.error(unit?.message);
      }
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [buttonLoader]);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        />
      </Loadercomponent>
    </Header>
  );
};

export default UnitsForm;
