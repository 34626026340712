const forms = [
  {
    name: "Timesheet",
    className: "bg-white p-4 rounded-md p-8 w-[90%] mx-auto",
    options: [
      {
        name: "Date",
        type: "date",
        form: {
          type: "text",
          name: "date",
          placeholder: "Date",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Task",
        type: "selectandadd",
        path: "/app/projects/tasks/add/",
        form: {
          name: "project_task",
          allowClear: true,
          showSearch: true,
          filterOption: false,
          placeholder: "Timesheet task",
          api: "",
          params: {
            api: "get-project-tasks",
            status: 1,
            project: "",
          },
          options: null,
          value: null,
          required: true,
        },
      },
    ],
  },
];

export default forms;
