const headers = {
  name: "Employee",
  navigate: {
    path: [
      {
        name: "Employees",
        path: "/app/employees",
      },
      {
        name: "Add",
        path: "",
      },
    ],
  },
};

export default headers;
