import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_item_brand = async (brand) => {
  try {
    const data = {
      name: brand?.name,
      status: brand?.status,
      branch: brand?.branch,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/create-item-brand`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_item_brand = async (brand) => {
  try {
    const data = {
      id: brand?.id,
      name: brand?.name,
      status: brand?.status,
      branch: brand?.branch,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/update-item-brand`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const delete_item_brand = async (brand) => {
  try {
    const data = {
      id: brand?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/delete-item-brand`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_item_brand = async (brand) => {
  try {
    const data = {
      id: brand?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-item-brand`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_item_brands = async (brand) => {
  try {
    const data = {
      search: brand?.search,
      status: brand?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-item-brands`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_item_brand_log = async (brand) => {
  try {
    const data = {
      search: brand?.search,
      sort: brand?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-item-brand-log`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_item_brand_logs = async (brand) => {
  try {
    const data = {
      search: brand?.search,
      sort: brand?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-item-brand-logs`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export {
  create_item_brand,
  update_item_brand,
  delete_item_brand,
  get_item_brand,
  get_all_item_brands,
  get_item_brand_log,
  get_all_item_brand_logs,
};
