import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_employee_role = async (role) => {
  try {
    const data = {
      name: role?.name,
      status: role?.status,
      branch: role?.branch,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/create-employee-role`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_employee_role = async (role) => {
  try {
    const data = {
      id: role?.id,
      name: role?.name,
      status: role?.status,
      branch: role?.branch,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/update-employee-role`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const delete_employee_role = async (role) => {
  try {
    const data = {
      id: role?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/delete-employee-role`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_employee_role = async (role) => {
  try {
    const data = {
      id: role?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-employee-role`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_employee_roles = async (role) => {
  try {
    const data = {
      search: role?.search,
      sort: role?.sort,
      status: role?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-employee-roles`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_employee_role_log = async (role) => {
  try {
    const data = {
      id: role?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-employee-role-log`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_employee_roles_log = async (role) => {
  try {
    const data = {
      role: role?.role,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-employee-roles-log`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export {
  create_employee_role,
  update_employee_role,
  delete_employee_role,
  get_employee_role,
  get_all_employee_roles,
  get_employee_role_log,
  get_all_employee_roles_log,
};
