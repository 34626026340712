import React, { useEffect, useState } from "react";
import Header from "../../../../Componets/Header";
import TableImage from "../../../../Componets/Image";
import Status from "../../../../Componets/Status";
import ActionButton from "../../../../Componets/Buttons/action";
import { get_all_project_timesheets } from "../../../../Controllers/Projects/Timesheet";
import toast from "react-hot-toast";
import tables from "../../../../Data/Tables/Projects/Timesheets/tables";
import headers from "../../../../Data/Tables/Projects/Timesheets/headers";
import Loadercomponent from "../../../../Componets/Loader";
import { useParams } from "react-router-dom";
import Table from "../../../../Componets/Table";
import Progresscircle from "../../../../Componets/Progress";
import ProjectsTimesheetsTab from "../../../../Data/Tables/Projects/Timesheets/tabs";
import moment from "moment";

const ProjectsTimesheetsTable = () => {
  const { project } = useParams();

  headers.components[
    headers?.components?.length - 1
  ].path = `/app/projects/timesheets/add/${project}`;

  const [loader, setLoader] = useState(false);
  const [body, setBody] = useState();
  const [projectData, setProjectData] = useState();

  const formdata = (data) => {
    fetchData(data);
  };

  const actiondata = (data) => {
    fetchData(data);
  };

  const fetchData = async (tabledata) => {
    try {
      setLoader(true);

      const data = {
        project: project,
        search: tabledata?.search,
        sort: tabledata?.sort,
        customer: tabledata?.customer,
        project_status: tabledata?.project_status,
        start_date: tabledata?.start_date,
        end_date: tabledata?.end_date,
        status: tabledata?.status,
      };

      const projects = await get_all_project_timesheets(data);
      if (projects?.status) {
        let data = [];

        projects?.data?.timesheets?.map((value, index) => {
          data?.push({
            _id: value?._id,
            Date: moment?.(value?.date)?.format?.("DD/MM/YYYY"),
            Area: value?.number,
            Task: value?.project_task?.name,
            Status: <Status status={value?.status} />,
            Action: (
              <ActionButton
                edit={`/app/projects/timesheets/edit/${project}/${value?._id}`}
                id={value?._id}
                api="delete-timesheets"
                actiondata={actiondata}
              />
            ),
          });
        });

        setBody(data);
        setProjectData(projects?.data?.project);
        setLoader(false);
      } else {
        toast?.error(projects?.message);
        setLoader(false);
      }
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers} formdata={formdata}>
      <Loadercomponent loader={loader}>
        <ProjectsTimesheetsTab projectData={projectData} />
        <Table head={tables} body={body} />
      </Loadercomponent>
    </Header>
  );
};

export default ProjectsTimesheetsTable;
