import { AutoComplete, DatePicker, Form, Input, Select } from "antd";
import React, { createRef, useEffect, useRef, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";
import AddButton from "../../../../Componets/Buttons/add";
import { select_search, select_id } from "../../../../Controllers/Global";
import moment from "moment";

const Projecttaskitemstable = React.forwardRef((props, ref) => {
  const { itemDetails, setItemDetails, itemTotals, setItemTotals } = props;

  const handleSearch = async (e, index) => {
    let data = [...itemDetails];

    const select = {
      api: "get-all-items",
      search: e,
      status: 1,
    };

    const search = await select_search(select);

    if (search?.status) {
      let options = [];
      search?.data?.map((value, index) => {
        options?.push({ value: JSON?.stringify(value), label: value?.name });
      });

      data[index].options = options;

      setItemDetails(data);
    } else {
      toast?.error(search?.message);
    }
  };

  const handlePurchasePriceSearch = async (e, index) => {
    let data = [...itemDetails];

    if (data[index].description) {
      const select = {
        api: "get-all-purchase-details",
        id: JSON?.parse(data[index].description)?._id,
      };

      const search = await select_id(select);
      if (search?.status) {
        let options = [];
        search?.data?.map((value, index) => {
          options?.push({
            value: JSON?.stringify(value),
            label:
              "" +
              value?.purchase_price?.toFixed(3) +
              " (" +
              value?.purchase?.supplierorcontractor?.name +
              ", " +
              moment?.(value?.purchase?.date)?.format?.("DD/MM/YYYY") +
              ", #PO" +
              value?.purchase?.number +
              ")",
          });
        });

        data[index].purchase_options = options;
        setItemDetails(data);
      } else {
        toast?.error(search?.message);
      }
    }
  };

  const handleDescription = (e, index) => {
    let data = [...itemDetails];

    if (e) {
      let data_description = e ? e : "";
      let data_unit = JSON?.parse(e)?.unit?.name
        ? JSON?.parse(e)?.unit?.name
        : "";
      let data_purchase_price = JSON?.parse(e)?.purchase_price
        ? parseFloat(JSON?.parse(e)?.purchase_price)
        : "";
      let data_tax = JSON?.parse(e)?.tax ? parseFloat(JSON?.parse(e)?.tax) : 0;

      if (data_description) {
        let ids = [];
        data?.map((v, i) => {
          if (v?.description) {
            ids?.push?.(JSON?.parse(v?.description)?._id);
          }
        });

        if (ids?.includes(JSON?.parse(e)?._id)) {
          let old_index = ids?.indexOf(JSON?.parse(e)?._id);
          let data_quantity = data[old_index]?.quantity
            ? data[old_index]?.quantity
            : 0;

          let event = {
            target: {
              name: "quantity",
              value: data_quantity + 1,
            },
          };
          handleTotal(event, old_index);
        } else {
          let event = {
            target: {
              name: "purchase_price",
              value: data_purchase_price,
            },
          };
          data[index].description = data_description;
          data[index].unit = data_unit;
          setItemDetails(data);
          handleTotal(event, index, "");
          handleTotal(data_tax, index, "tax");
        }
      }
    } else {
      let event = {
        target: {
          name: "purchase_price",
          value: "",
        },
      };
      data[index].description = null;
      data[index].unit = "";
      setItemDetails(data);
      handleTotal(event, index, "");
      handleTotal(0, index, "tax");
    }
  };

  const handlePurchase = (e, index, key) => {
    if (e) {
      let event = {
        target: {
          name: "purchase_price",
          value: JSON?.parse(e)?.purchase_price,
        },
      };

      handleTotal(event, index, key);
      handleTotal(JSON?.parse(e)?.tax, index, "tax");
    }
  };

  const handleTotal = (e, index, key) => {
    let name = key ? key : e?.target?.name;
    let value = key && e ? e : !key && e?.target?.value ? e?.target?.value : "";

    if (value >= 0) {
      let data = [...itemDetails];

      let data_purchase_price = data[index]?.purchase_price
        ? data[index]?.purchase_price
        : 0;
      let data_quantity = data[index]?.quantity ? data[index]?.quantity : 0;
      let data_tax = data[index]?.tax ? data[index]?.tax : 0;

      let purchase_price =
        name === "purchase_price" ? value : data_purchase_price;
      let quantity = name === "quantity" ? value : data_quantity;
      let tax = name === "tax" ? value : data_tax;

      let tax_amount = tax
        ? parseFloat(purchase_price) *
          parseFloat(quantity ? quantity : 1) *
          (parseFloat(tax) / 100)
        : 0;

      let total =
        parseFloat(purchase_price) * parseFloat(quantity ? quantity : 1) +
        parseFloat(tax_amount);

      data[index][name] = value;
      data[index].tax = tax ? tax : 0;
      data[index].tax_amount = tax_amount ? tax_amount?.toFixed(3) : 0;
      data[index].total = total ? total?.toFixed(3) : 0;
      data[index].tax_amount = tax_amount ? tax_amount?.toFixed(3) : 0;

      let subtotal =
        data?.reduce(
          (acc, currentValue) =>
            parseFloat(acc) + parseFloat(currentValue?.total),
          0
        ) -
        data?.reduce(
          (acc, currentValue) =>
            parseFloat(acc) + parseFloat(currentValue?.tax_amount),
          0
        );

      let taxamount = data?.reduce(
        (acc, currentValue) =>
          parseFloat(acc) + parseFloat(currentValue?.tax_amount),
        0
      );

      let grandtotal = data?.reduce(
        (acc, currentValue) =>
          parseFloat(acc) + parseFloat(currentValue?.total),
        0
      );

      itemTotals.subtotal = subtotal;
      itemTotals.taxamount = taxamount;
      itemTotals.total = grandtotal;

      setItemDetails(data);
      setItemTotals({ ...itemTotals });
    }
  };

  const handleRemove = (index) => {
    let data = [...itemDetails];

    data?.splice(index, 1);

    setItemDetails(data);
  };

  const handleAdd = () => {
    setItemDetails((previtemDetails) => [
      ...previtemDetails,
      {
        id: "",
        description: null,
        unit: "",
        purchase_price: "",
        purchase_options: null,
        quantity: "",
        tax: 0,
        total: 0,
      },
    ]);
  };

  return (
    <div>
      <table className="w-full">
        <thead>
          <tr>
            <td className="border p-4 text-black">S.No</td>
            <td className="border p-4 text-black">Description</td>
            <td className="border p-4 text-black">Unit</td>
            <td className="border p-4 text-black">Purchase Price</td>
            <td className="border p-4 text-black">Quantity</td>
            <td className="border p-4 text-black">Tax</td>
            <td className="border p-4 text-black">Total</td>
            <td className="border p-4 text-black">Delete</td>
          </tr>
        </thead>
        <tbody>
          {itemDetails?.map((value, index) => {
            return (
              <React.Fragment>
                <tr>
                  <td className="border p-4 text-center">{index + 1}</td>
                  <td className="border p-4">
                    <Select
                      className="w-[200px] text-regular"
                      name="description"
                      placeholder="Description"
                      allowClear={true}
                      showSearch={true}
                      filterOption={false}
                      options={value?.options}
                      value={value?.description}
                      onSearch={(e) => handleSearch(e, index)}
                      onClick={(e) => handleSearch("", index)}
                      onChange={(e) => handleDescription(e, index)}
                      autoComplete="off"
                    />
                  </td>
                  <td className="border p-4 text-center">{value?.unit}</td>
                  <td className="border p-4 text-center">
                    <AutoComplete
                      className="w-[350px] text-regular"
                      name="purchase_price"
                      placeholder="Purchase price"
                      allowClear={false}
                      showSearch={true}
                      filterOption={false}
                      options={value?.purchase_options}
                      value={value?.purchase_price}
                      onClick={(e) => handlePurchasePriceSearch("", index)}
                      onSelect={(e) => handlePurchase(e, index)}
                      autoComplete="off"
                    />
                  </td>
                  <td className="border p-4 text-center">
                    <Input
                      type="number"
                      className="w-full"
                      name="quantity"
                      placeholder="Quantity"
                      value={value?.quantity}
                      onChange={(e) => handleTotal(e, index)}
                      autoComplete="off"
                      required="required"
                    />
                  </td>
                  <td className="border p-4 text-center">{value?.tax}%</td>
                  <td className="border p-4 text-center">
                    {parseFloat?.(value?.total)?.toFixed(3)}
                  </td>
                  <td className="border p-4 text-center">
                    <button
                      type="button"
                      className={
                        index == 0
                          ? "text-lg text-red-900 cursor-not-allowed"
                          : "text-lg text-red-500 cursor-pointer hover:text-red-800"
                      }
                      disabled={index == 0}
                      onClick={() => handleRemove(index)}
                    >
                      <DeleteOutlined />
                    </button>
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td className="border p-4" colSpan={5}></td>
            <td className="border p-4">Subtotal</td>
            <td className="border p-4" colSpan={2}>
              {itemTotals?.subtotal > 0
                ? itemTotals?.subtotal?.toFixed(3)
                : "0.000"}
            </td>
          </tr>
          <tr>
            <td className="border p-4" colSpan={5}></td>
            <td className="border p-4">Tax&nbsp;amount</td>
            <td className="border p-4" colSpan={2}>
              {itemTotals?.taxamount > 0
                ? itemTotals?.taxamount?.toFixed(3)
                : "0.000"}
            </td>
          </tr>
          <tr>
            <td className="border p-4" colSpan={5}></td>
            <td className="border p-4 text-black text-medium">Total</td>
            <td className="border p-4 text-black text-medium" colSpan={2}>
              {itemTotals?.total > 0 ? itemTotals?.total?.toFixed(3) : "0.000"}
            </td>
          </tr>
        </tfoot>
      </table>
      <div className="flex justify-end p-3">
        <AddButton onClick={handleAdd} />
      </div>
    </div>
  );
});

export default Projecttaskitemstable;
