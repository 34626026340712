import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import toast from "react-hot-toast";
import Header from "../../../../Componets/Header";
import {
  create_item,
  get_item,
  update_item,
} from "../../../../Controllers/Items/Items";
import forms from "../../../../Data/Forms/Items/Items/forms";
import headers from "../../../../Data/Forms/Items/Items/headers";
import Loadercomponent from "../../../../Componets/Loader";
import { convert_to_file } from "../../../../Controllers/Global";
import Formdata from "../../../../Componets/Formcomponents/formdata";
import Headercomponent from "../../../../Componets/Formcomponents/header";
import Bodycomponent from "../../../../Componets/Formcomponents/body";
import Titlecomponent from "../../../../Componets/Formcomponents/title";
import Itemunittable from "../../../../Data/Forms/Items/Items/table";

const ItemsForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [details, setDetails] = useState([
    {
      id: "",
      name: null,
      options: "",
      quantity: "",
    },
  ]);

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const focus = useRef([]);

  const validateDetails = (data) => {
    if (data?.length > 0) {
      let total = data?.length;
      let count = 0;

      let validated = [];
      data?.map((v, i) => {
        if (!v?.name && data?.length > 1) {
          document.getElementById(`name${i}`).textContent = `Name is required`;
          if (i == count) {
            focus.current[`name${i}`].current.focus();
          }
        } else {
          document.getElementById(`name${i}`).textContent = "";
          validated?.push(v);
          count++;
        }
      });

      if (total == count) {
        return { status: true, data: validated };
      } else {
        return { status: false, data: validated };
      }
    } else {
      return { status: true, data: data };
    }
  };

  const formdata = async (data) => {
    if (data?.status) {
      const validatedetails = validateDetails(details);

      if (validatedetails?.status) {
        setButtonLoader(true);

        const item = id
          ? await update_item({ ...data?.data, id: id, details: details })
          : await create_item({ ...data?.data, details: details });

        if (item?.status) {
          if (id) {
            navigate(-1);
          }
          toast?.success(item?.message);
          setButtonLoader(false);
        } else {
          toast?.error(item?.message);
          setButtonLoader(false);
        }
      }
    }
  };

  const handleData = async (item) => {
    let image = item?.image ? item?.image : "";
    let file = await convert_to_file(image);
    let name = item?.name ? item?.name : "";
    let barcode = item?.barcode ? item?.barcode : "";

    let unit = item?.unit?._id ? item?.unit?._id : null;
    let category = item?.category?._id ? item?.category?._id : null;
    let brand = item?.brand?._id ? item?.brand?._id : null;

    let stock = item?.stock ? item?.stock : "";
    let expiry = item?.expiry ? item?.expiry : "";

    let type = item?.type ? item?.type : 0;
    let status = item?.status ? item?.status : 0;

    let unit_option = item?.unit?._id
      ? [{ value: item?.unit?._id, label: item?.unit?.name }]
      : null;
    let category_option = item?.category?._id
      ? [{ value: item?.category?._id, label: item?.category?.name }]
      : null;
    let brand_option = item?.brand?._id
      ? [{ value: item?.brand?._id, label: item?.brand?.name }]
      : null;

    values[0].options[0].form.value = file;
    values[0].options[1].form.value = name;
    values[0].options[2].form.value = barcode;

    values[1].options[0].form.value = unit;
    values[1].options[0].form.options = unit_option;
    values[1].options[1].form.value = category;
    values[1].options[1].form.options = category_option;
    values[1].options[2].form.value = brand;
    values[1].options[2].form.options = brand_option;

    values[2].options[0].form.value = stock;
    values[2].options[1].form.value = expiry;

    values[3].options[0].form.value = type;

    values[4].options[0].form.value = status;

    if (item?.item_units_details?.length > 0) {
      let item_unit_details = [];
      item?.item_units_details?.map((v, i) => {
        item_unit_details?.push({
          id: v?._id,
          name: v?.name?._id,
          options: [{ value: v?.name?._id, label: v?.name?.name }],
          quantity: v?.quantity,
        });
      });
      setDetails([...item_unit_details]);
    }

    setLoader(false);
    setValues([...values]);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      const data = {
        id: id,
      };

      const item = await get_item(data);
      if (item?.status) {
        handleData(item?.data);
      } else {
        toast?.error(item?.message);
      }
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        >
          <Bodycomponent>
            <Titlecomponent index={1} title={"Units"} />
            <Formdata>
              <Headercomponent index={0} name={"Details"} required={false} />
              <Itemunittable
                details={details}
                setDetails={setDetails}
                ref={focus}
              />
            </Formdata>
          </Bodycomponent>
        </Forms>
      </Loadercomponent>
    </Header>
  );
};

export default ItemsForm;
