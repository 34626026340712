import { Checkbox } from "antd";

const forms = [
  {
    name: "Details",
    options: [
      {
        name: "Role",
        type: "input",
        form: {
          type: "text",
          name: "name",
          placeholder: "Role name",
          class: "",
          value: "",
          required: true,
        },
      },
    ],
  },
  {
    name: "Permissions",
    options: [
      {
        name: "",
        type: "component",
        form: (
          <div>
            <table className="w-full border">
              <thead className="">
                <tr className="text-black border">
                  <td className="text-left p-2 border">Name</td>
                  <td className="text-center p-2 border">Full access</td>
                  <td className="text-center p-2 border">View</td>
                  <td className="text-center p-2 border">Create</td>
                  <td className="text-center p-2 border">Update</td>
                  <td className="text-center p-2 border">Delete</td>
                  {/* <td className="text-center p-2 border">Approve</td> */}
                </tr>
              </thead>
              <tbody>
                <tr className="text-gray-500 border">
                  <td className="text-left p-2 border">Dashboard</td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  {/* <td className="text-center p-2 border">
                    <Checkbox />
                  </td> */}
                </tr>
                <tr className="text-gray-500 border">
                  <td className="text-left p-2 border">Projects</td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  {/* <td className="text-center p-2 border">
                    <Checkbox />
                  </td> */}
                </tr>
                <tr className="text-gray-500 border">
                  <td className="text-left p-2 border">Items</td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  {/* <td className="text-center p-2 border">
                    <Checkbox />
                  </td> */}
                </tr>
                <tr className="text-gray-500 border">
                  <td className="text-left p-2 border">Employees</td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  {/* <td className="text-center p-2 border">
                    <Checkbox />
                  </td> */}
                </tr>
                <tr className="text-gray-500 border">
                  <td className="text-left p-2 border">Customers</td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  {/* <td className="text-center p-2 border">
                    <Checkbox />
                  </td> */}
                </tr>
                <tr className="text-gray-500 border">
                  <td className="text-left p-2 border">Suppliers</td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  {/* <td className="text-center p-2 border">
                    <Checkbox />
                  </td> */}
                </tr>
                <tr className="text-gray-500 border">
                  <td className="text-left p-2 border">Quotes</td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  {/* <td className="text-center p-2 border">
                    <Checkbox />
                  </td> */}
                </tr>
                <tr className="text-gray-500 border">
                  <td className="text-left p-2 border">Invoice</td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  {/* <td className="text-center p-2 border">
                    <Checkbox />
                  </td> */}
                </tr>
                <tr className="text-gray-500 border">
                  <td className="text-left p-2 border">Purchases</td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  {/* <td className="text-center p-2 border">
                    <Checkbox />
                  </td> */}
                </tr>
                <tr className="text-gray-500 border">
                  <td className="text-left p-2 border">Contractors</td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  {/* <td className="text-center p-2 border">
                    <Checkbox />
                  </td> */}
                </tr>
                <tr className="text-gray-500 border">
                  <td className="text-left p-2 border">Reports</td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  {/* <td className="text-center p-2 border">
                    <Checkbox />
                  </td> */}
                </tr>
                <tr className="text-gray-500 border">
                  <td className="text-left p-2 border">Settings</td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  <td className="text-center p-2 border">
                    <Checkbox />
                  </td>
                  {/* <td className="text-center p-2 border">
                    <Checkbox />
                  </td> */}
                </tr>
              </tbody>
            </table>
          </div>
        ),
      },
    ],
  },
  {
    name: "Status",
    options: [
      {
        name: "Status",
        type: "select",
        form: {
          name: "status",
          allowClear: false,
          placeholder: "Status",
          value: 1,
          options: [
            {
              value: 1,
              label: "Active",
            },
            {
              value: 0,
              label: "In-active",
            },
          ],
          required: false,
        },
      },
    ],
  },
];

export default forms;
