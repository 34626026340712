const headers = {
  name: "Project job",
  navigate: {
    path: [
      {
        name: "Jobs",
        path: "/app/projects/jobs",
      },
      {
        name: "Add",
        path: "",
      },
    ],
  },
};

export default headers;
