import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import toast from "react-hot-toast";
import Header from "../../../../Componets/Header";
import forms from "../../../../Data/Forms/Employees/Employees/forms";
import headers from "../../../../Data/Forms/Employees/Employees/headers";
import Loadercomponent from "../../../../Componets/Loader";
import {
  create_employee,
  get_employee,
  update_employee,
} from "../../../../Controllers/Employees/Employees";

const EmployeesForm = () => {
  let { id } = useParams();

  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
      setButtonLoader(true);

      const employee = id
        ? await update_employee({ id: id, ...data?.data })
        : await create_employee(data?.data);

      if (employee?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(employee?.message);
        setButtonLoader(false);
      } else {
        toast?.error(employee?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = (employee) => {
    let name = employee?.name ? employee?.name : "";
    let role = employee?.role?._id ? employee?.role?._id : null;
    let email = employee?.email ? employee?.email : "";
    let phone = employee?.phone ? employee?.phone : "";
    let working_hours = employee?.working_hours ? employee?.working_hours : 0;
    let working_cost = employee?.working_cost ? employee?.working_cost : 0;
    let area = employee?.area ? employee?.area : "";
    let city = employee?.city ? employee?.city : "";
    let state = employee?.state ? employee?.state : "";
    let country = employee?.country ? employee?.country : "";
    let status = employee?.status ? employee?.status : 0;

    let role_option = employee?.role?._id
      ? [{ value: employee?.role?._id, label: employee?.role?.name }]
      : null;

    forms[0].options[0].form.value = name;
    forms[0].options[1].form.value = role;
    forms[0].options[1].form.options = role_option;

    forms[1].options[0].form.value = email;
    forms[1].options[1].form.value = phone;

    forms[2].options[0].form.value = working_hours;
    forms[2].options[1].form.value = working_cost;

    forms[3].options[0].form.value = area;
    forms[3].options[1].form.value = city;
    forms[3].options[2].form.value = state;
    forms[3].options[3].form.value = country;

    forms[4].options[0].form.value = status;

    setLoader(false);
    setValues([...values]);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      headers.navigate.path[1].name = "Edit";

      const data = {
        id: id,
      };

      const employee = await get_employee(data);

      if (employee?.status) {
        handleData(employee?.data);
      } else {
        toast?.error(employee?.message);
      }
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [buttonLoader]);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        />
      </Loadercomponent>
    </Header>
  );
};

export default EmployeesForm;
