import { Input, Select } from "antd";
import React, { createRef, useState } from "react";
import AddButton from "../../../../Componets/Buttons/add";
import { select_search } from "../../../../Controllers/Global";
import toast from "react-hot-toast";
import { DeleteOutlined } from "@ant-design/icons";
import Error from "../../../../Componets/Error";

const Itemunittable = React.forwardRef((props, focus) => {
  const { details, setDetails } = props;

  const handleSearch = async (e, index) => {
    let data = [...details];

    const select = {
      api: "get-all-item-units",
      search: e,
      status: 1,
    };

    const search = await select_search(select);

    if (search?.status) {
      let options = [];
      search?.data?.map((value, index) => {
        options?.push({ value: value?._id, label: value?.name });
      });

      data[index].options = options;

      setDetails(data);
    } else {
      toast?.error(search?.message);
    }
  };

  const handleName = (e, index) => {
    let data = [...details];

    let ids = [];

    data?.map((v, i) => {
      ids?.push(v?.name);
    });

    if (e) {
      if (ids?.includes(e)) {
        data[index].name = null;
        setDetails(data);
        toast?.error("Unit exists");
      } else {
        data[index].name = e;
        setDetails(data);
      }
    } else {
      data[index].name = null;
      data[index].quantity = "";
      setDetails(data);
    }
  };

  const handleQuantity = (e, index) => {
    if (e?.target?.value >= 0) {
      let data = [...details];

      data[index][e?.target?.name] = e?.target?.value;

      setDetails(data);
    }
  };

  const handleRemove = (index) => {
    let data = [...details];

    data?.splice(index, 1);

    setDetails(data);
  };

  const handleAdd = () => {
    setDetails((prevDetails) => [
      ...prevDetails,
      {
        id: "",
        name: null,
        options: "",
        quantity: "",
      },
    ]);
  };

  return (
    <div>
      <table className="w-full">
        <thead>
          <tr>
            <td className="border p-4 text-black">Name</td>
            <td className="border p-4 text-black">Quantity</td>
            <td className="border p-4 text-black">Remove</td>
          </tr>
        </thead>
        <tbody>
          {details?.map((value, index) => {
            focus.current[`name${index}`] = createRef();
            focus.current[`quantity${index}`] = createRef();
            return (
              <React.Fragment>
                <tr>
                  <td className="border p-4 text-left">
                    <Select
                      key={index}
                      name="name"
                      ref={focus.current[`name${index}`]}
                      className="w-full text-regular"
                      placeholder="Name"
                      allowClear={true}
                      showSearch={true}
                      filterOption={false}
                      options={value?.options}
                      value={value?.name}
                      onSearch={(e) => handleSearch(e, index)}
                      onClick={(e) => handleSearch("", index)}
                      onChange={(e) => handleName(e, index)}
                      autoComplete="off"
                    />
                    <Error id={`name${index}`} />
                  </td>
                  <td className="border p-4">
                    <Input
                      key={index}
                      name="quantity"
                      ref={focus.current[`quantity${index}`]}
                      type="number"
                      className="w-full text-regular"
                      placeholder="Quantity"
                      value={value?.quantity}
                      onChange={(e) => handleQuantity(e, index)}
                      autoComplete="off"
                    />
                    <Error id={`quantity${index}`} />
                  </td>
                  <td className="border p-4 text-center">
                    <button
                      type="button"
                      className="text-lg text-red-500 cursor-pointer hover:text-red-800"
                      onClick={() => handleRemove(index)}
                    >
                      <DeleteOutlined />
                    </button>
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <div className="flex justify-end p-3">
        <AddButton onClick={handleAdd} />
      </div>
    </div>
  );
});

export default Itemunittable;
