import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import toast from "react-hot-toast";
import Header from "../../../../Componets/Header";
import {
  create_project,
  get_project,
  update_project,
} from "../../../../Controllers/Projects/Projects";
import forms from "../../../../Data/Forms/Projects/Projects/forms";
import headers from "../../../../Data/Forms/Projects/Projects/headers";
import Loadercomponent from "../../../../Componets/Loader";
import { convert_to_file } from "../../../../Controllers/Global";

const ProjectsForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
      setButtonLoader(true);

      const project = id
        ? await update_project({ ...data?.data, id: id })
        : await create_project(data?.data);

      if (project?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(project?.message);
        setButtonLoader(false);
      } else {
        toast?.error(project?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = async (project) => {
    let image = project?.image ? project?.image : "";
    let file = await convert_to_file(image);
    let name = project?.name ? project?.name : "";
    let number = project?.number ? project?.number : "";
    let customer = project?.customer?._id ? project?.customer?._id : null;
    let start_date = project?.start_date ? project?.start_date : "";
    let end_date = project?.end_date ? project?.end_date : "";
    let estimated = project?.estimated ? project?.estimated : 0;
    let area = project?.area ? project?.area : "";
    let city = project?.city ? project?.city : "";
    let state = project?.state ? project?.state : "";
    let country = project?.country ? project?.country : "Oman";
    let project_status = project?.project_status
      ? project?.project_status
      : null;
    let status = project?.status ? project?.status : 0;
    let customer_option = project?.customer?._id
      ? [{ value: project?.customer?._id, label: project?.customer?.name }]
      : null;

    values[0].options[0].form.value = file;
    values[0].options[1].form.value = name;
    values[0].options[2].form.value = number;

    values[1].options[0].form.value = customer;
    values[1].options[0].form.options = customer_option;
    values[1].options[1].form.value = start_date;
    values[1].options[2].form.value = end_date;
    values[1].options[3].form.value = estimated;

    values[2].options[0].form.value = area;
    values[2].options[1].form.value = city;
    values[2].options[2].form.value = state;
    values[2].options[3].form.value = country;

    values[3].options[0].form.value = project_status;
    values[3].options[1].form.value = status;
    setLoader(false);
    setValues([...values]);
  };

  const fetchData = async () => {
    console.log(values, "values,values", forms, "values,forms");

    if (id) {
      setLoader(true);

      const data = {
        id: id,
      };

      const project = await get_project(data);
      if (project?.status) {
        handleData(project?.data);
      } else {
        toast?.error(project?.message);
      }
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        />
      </Loadercomponent>
    </Header>
  );
};

export default ProjectsForm;
