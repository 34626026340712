import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const update_user_auth = async (boq) => {
  try {
    const data = {
      name: boq?.name,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/update-user-auth`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export { update_user_auth };
