import {
  DownloadOutlined,
  FileOutlined,
  FilePdfOutlined,
  PrinterOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";

const BoqPage = () => {
  let componentRef = useRef();

  return (
    <div>
      {/* page */}
      <div className="flex justify-center">
        <div className="w-[8.27in]  text-right py-4">
          <div className="flex justify-end">
            <div className="bg-blue-100 hover:bg-blue-500 hover:text-white text-lg  px-3 py-1 rounded-md text-blue-700 cursor-pointer">
              <ReactToPrint
                trigger={() => (
                  <div className="">
                    <ShareAltOutlined className="text-medium" />
                  </div>
                )}
                content={() => componentRef}
              />
            </div>
            <div className="bg-blue-100 text-lg hover:bg-blue-500 hover:text-white px-3 py-1 rounded-md text-blue-700 cursor-pointer mx-3">
              <ReactToPrint
                trigger={() => (
                  <div className="">
                    <DownloadOutlined />
                  </div>
                )}
                content={() => componentRef}
              />
            </div>
            <div className="bg-blue-100 text-lg  hover:bg-blue-500 hover:text-white px-3 py-1 rounded-md text-blue-700 cursor-pointer mx-3">
              <ReactToPrint
                trigger={() => (
                  <div className="">
                    <FilePdfOutlined />
                  </div>
                )}
                content={() => componentRef}
              />
            </div>
            <div className="bg-blue-100 text-lg  hover:bg-blue-500 hover:text-white px-3 py-1 rounded-md text-blue-700 cursor-pointer">
              <ReactToPrint
                trigger={() => (
                  <div className="">
                    <PrinterOutlined />
                  </div>
                )}
                content={() => componentRef}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="page-a4" ref={(el) => (componentRef = el)}>
          {/*letter header */}
          <div className="text-black text-[0.65rem] text-center">
            <div className="text-center">
              <div className="text-medium text-xl">New Construction LLC</div>
              <div className="text-medium">Construction Company</div>
            </div>
          </div>
          <div className="text-black text-[0.65rem] text-center pt-4">
            <span>C.R.&nbsp;:&nbsp;1233305,&nbsp;</span>
            <span>P.O&nbsp;Box&nbsp;:&nbsp;5678,&nbsp;</span>
            <span>P.O&nbsp;Code&nbsp;:&nbsp;342,&nbsp;</span>
            <span>Fax&nbsp;:&nbsp;2132435,&nbsp;</span>
            <span>Tel&nbsp;:&nbsp;22876756,&nbsp;</span>
            <span>Email&nbsp;:&nbsp;info@aljazeera.com,&nbsp;</span>
            <span>F.Code&nbsp;:&nbsp;8756432,&nbsp;</span>
            <div>Al-Ansab,&nbsp;Muscat,&nbsp;Sultanate&nbsp;Of&nbsp;Oman</div>
            <div>VATIN&nbsp;:&nbsp;51234876543754</div>
          </div>
          {/* heading */}
          <hr className="my-4 border-t-black border-black" />
          <div className="text-medium text-xl text-center">
            Bill Of Quantity
          </div>
          <div className="text-xs pb-6">
            <div className="flex justify-between">
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td className="font-semibold text-sm">
                        Envol Engineering LLC
                      </td>
                    </tr>
                    <tr>
                      <td>18th November Street</td>
                    </tr>
                    <tr>
                      <td>Azaiba, Muscat</td>
                    </tr>
                    <tr>
                      <td>Sultanate of Oman</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td>BOQ No</td>
                      <td className="px-2">:</td>
                      <td className="text-right">#B0Q-24021901</td>
                    </tr>
                    <tr>
                      <td>Date</td>
                      <td className="px-2">:</td>
                      <td className="text-right"> 20/02/2024</td>
                    </tr>
                    <tr>
                      <td>Due</td>
                      <td className="px-2">:</td>
                      <td className="text-right"> 05/03/2024</td>
                    </tr>
                    <tr>
                      <td>Reference</td>
                      <td className="px-2">:</td>
                      <td className="text-right">Ven Thomas</td>
                    </tr>
                    <tr>
                      <td>Phone</td>
                      <td className="px-2">:</td>
                      <td className="text-right">784323456</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* table */}
          <div className="">
            <table className="w-full">
              <thead className="bg-black">
                <tr className="text-sm">
                  <th className=" font-normal  text-white  text-left py-2 pl-3">
                    S.No
                  </th>
                  <th className="font-normal text-white text-left">
                    Description
                  </th>
                  <th className="font-normal text-white text-left">Unit</th>
                  <th className="font-normal text-white text-left">Price</th>
                  <th className="font-normal text-white text-right pr-2">
                    Quantity
                  </th>
                  <th className="font-normal text-white text-right pr-2">
                    Total
                  </th>
                </tr>
              </thead>
              {/* table body */}
              <tbody className="text-sm">
                <tr className="uppercase border-b border-gray-300 ">
                  <td className="py-1 text-center border border-black">1</td>
                  <td className="border border-black">
                    <div className="">Earthwork in excavation</div>
                  </td>
                  <td className="py-1 text-center border border-black"></td>
                  <td className="text-right pr-2 border border-black"></td>
                  <td className="text-right pr-2 border border-black"></td>
                  <td className="text-right pr-2 border border-black"></td>
                </tr>

                <tr className="uppercase border-b border-gray-300 ">
                  <td className="py-1 text-center border border-black"></td>
                  <td className="border border-black">
                    <div className="">
                      a] Clearing grass, removal of the rubbish,roots or any
                      kind of dumped material within the construction premise
                      required to start the project and disposal of same aout of
                      site peri pheri, com plete{" "}
                    </div>
                  </td>
                  <td className="py-1 text-center border border-black">Sqm</td>
                  <td className="text-right pr-2 border border-black">9585</td>
                  <td className="text-right pr-2 border border-black">2</td>
                  <td className="text-right pr-2 border border-black">19170</td>
                </tr>

                <tr className="uppercase border-b border-gray-300 ">
                  <td className="py-1 text-center border border-black"></td>
                  <td className="border border-black">
                    <div className="">
                      b] CExtra for depth exceeding from 1.5 m to 3.0 m ( Rate
                      is over corresponding basic item for de pth u pto 1.5 m )
                    </div>
                  </td>
                  <td className="py-1 text-center border border-black">Cm</td>
                  <td className="text-right pr-2 border border-black">200</td>
                  <td className="text-right pr-2 border border-black">7</td>
                  <td className="text-right pr-2 border border-black">1400</td>
                </tr>

                <tr className="uppercase border-b border-gray-300 ">
                  <td className="py-1 text-center border border-black"></td>
                  <td className="border border-black">
                    <div className="">
                      c] CExtra for depth exceeding from 3.0 m to 4.5 m ( Rate
                      is over corresponding basic item for de pth u pto 3 m )
                    </div>
                  </td>
                  <td className="py-1 text-center border border-black">
                    meter
                  </td>
                  <td className="text-right pr-2 border border-black">3500</td>
                  <td className="text-right pr-2 border border-black">3</td>
                  <td className="text-right pr-2 border border-black">10500</td>
                </tr>

                <tr className="uppercase border-b border-gray-300 ">
                  <td className="py-1 text-center border border-black">2</td>
                  <td className="border border-black">
                    <div className="">Plain cement concrete works</div>
                  </td>
                  <td className="py-1 text-center border border-black"></td>
                  <td className="text-right pr-2 border border-black"></td>
                  <td className="text-right pr-2 border border-black"></td>
                  <td className="text-right pr-2 border border-black"></td>
                </tr>

                <tr className="uppercase border-b border-gray-300 ">
                  <td className="py-1 text-center border border-black"></td>
                  <td className="border border-black">
                    <div className="">
                      1 Cement : 3 coarse sand : 6 graded stone aggregate 40 mm
                      nominal size
                    </div>
                  </td>
                  <td className="py-1 text-center border border-black">Cum</td>
                  <td className="text-right pr-2 border border-black">1250</td>
                  <td className="text-right pr-2 border border-black">2</td>
                  <td className="text-right pr-2 border border-black">2500</td>
                </tr>

                <tr className="uppercase border-b border-gray-300 ">
                  <td className="py-1 text-center border border-black"></td>
                  <td className="border border-black">
                    <div className="">
                      1 Cement : 5 coarse sand : 10 graded stone aggregate 40 mm
                      nominal size.
                    </div>
                  </td>
                  <td className="py-1 text-center border border-black">Cum</td>
                  <td className="text-right pr-2 border border-black">1250</td>
                  <td className="text-right pr-2 border border-black">2</td>
                  <td className="text-right pr-2 border border-black">2500</td>
                </tr>

                <tr className="uppercase border-b border-gray-300 ">
                  <td className="py-1 text-center border border-black"></td>
                  <td className="border border-black">
                    <div className="">
                      Supplying and filling in plinth with fine sand under
                      floors including, watering, rammin g consolidatin g and
                      dressin g com plete.
                    </div>
                  </td>
                  <td className="py-1 text-center border border-black">Cum</td>
                  <td className="text-right pr-2 border border-black">500</td>
                  <td className="text-right pr-2 border border-black">4</td>
                  <td className="text-right pr-2 border border-black">200</td>
                </tr>
              </tbody>
              {/* table foot */}
              <tfoot className="text-sm ">
                {/* <tr className="">
                  <th colSpan={2}></th>
                  <th className="font-normal pt-1 text-right">Total</th>
                  <td className="font-normal text-right pr-2">350.000</td>
                </tr>
                <tr>
                  <th colSpan={2}></th>
                  <th className="font-normal text-right">VAT (5%)</th>
                  <td className="font-normal text-right pr-2">17.500</td>
                </tr> */}
                <tr>
                  <th className="" colSpan={2}></th>
                  <th
                    className="font-normal text-right text-white bg-black py-1 pr-3 "
                    colSpan={3}
                  >
                    Grand Total
                  </th>
                  <td className="font-normal text-right text-white bg-black pr-2">
                    367.500
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          {/* note */}
          {/* <div className="pt-4 text-sm">
            <div className="">
              <div className="font-semibold">Note&nbsp;&nbsp;</div>
              <li>
                Please feel free to contact us for any questions or concerns.
              </li>
            </div>
            <div className="pt-4">
              <div className="font-semibold">
                Terms & Conditions&nbsp;&nbsp;
              </div>
              <li>Quote validity for 30 days.</li>
            </div>
          </div> */}
          <div className="">
            <div
              className="page-footer mb-[1.5cm]"
              style={{ padding: " 1.6cm" }}
            >
              <div className="flex justify-between px-[0.5cm]">
                <div className="text-[0.65rem]">
                  Customer's Signature (Name/Designation/Co. Seal)
                </div>
                <div className="text-[0.65rem]">For New Construction LLC</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoqPage;
