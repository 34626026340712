const tables = [
  {
    name: "Name",
  },
  {
    name: "Number",
  },
  {
    name: "Area",
  },
  {
    name: "Est.expense",
  },
  {
    name: "Expense",
  },
  {
    name: "Progress",
  },
  {
    name: "Task Status",
  },
  {
    name: "Status",
  },
  {
    name: "Action",
  },
];

export default tables;
