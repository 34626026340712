import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import Header from "../../../../Componets/Header";
import forms from "../../../../Data/Forms/Settings/Branches/forms";
import headers from "../../../../Data/Forms/Settings/Branches/headers";
import {
  create_branch,
  get_branch,
  update_branch,
} from "../../../../Controllers/Settings/Branches";
import toast from "react-hot-toast";
import { convert_to_file } from "../../../../Controllers/Global";
import Loadercomponent from "../../../../Componets/Loader";

const BranchesForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
      setButtonLoader(true);

      const item = id
        ? await update_branch({ ...data?.data, id: id })
        : await create_branch(data?.data);

      if (item?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(item?.message);
        setButtonLoader(false);
      } else {
        toast?.error(item?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = async (item) => {
    let image = item?.image ? item?.image : "";
    let file = await convert_to_file(image);
    let name = item?.name ? item?.name : "";
    let email = item?.email ? item?.email : "";
    let phone = item?.phone ? item?.phone : "";
    let area = item?.area ? item?.area : "";
    let city = item?.city ? item?.city : "";
    let state = item?.state ? item?.state : "";
    let country = item?.country ? item?.country : "";
    let status = item?.status ? item?.status : 0;

    values[0].options[0].form.value = file;
    values[0].options[1].form.value = name;
    values[0].options[2].form.value = email;
    values[0].options[3].form.value = phone;

    values[1].options[0].form.value = area;
    values[1].options[1].form.value = city;
    values[1].options[2].form.value = state;
    values[1].options[3].form.value = country;

    values[2].options[0].form.value = status;

    setLoader(false);
    setValues([...values]);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      const data = {
        id: id,
      };

      const item = await get_branch(data);
      if (item?.status) {
        handleData(item?.data);
      } else {
        toast?.error(item?.message);
      }
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        />
      </Loadercomponent>
    </Header>
  );
};

export default BranchesForm;
