import { AutoComplete, DatePicker, Form, Input, Select } from "antd";
import React, { createRef, useEffect, useRef, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";
import AddButton from "../../../../Componets/Buttons/add";
import { select_search, select_id } from "../../../../Controllers/Global";
import moment from "moment";

const Projecttaskemployeestable = React.forwardRef((props, ref) => {
  const {
    employeeDetails,
    setEmployeeDetails,
    employeeTotals,
    setEmployeeTotals,
  } = props;

  const handleSearch = async (e, index) => {
    let data = [...employeeDetails];

    const select = {
      api: "get-all-employees",
      search: e,
      status: 1,
    };

    const search = await select_search(select);

    if (search?.status) {
      let options = [];
      search?.data?.map((value, index) => {
        options?.push({ value: JSON?.stringify(value), label: value?.name });
      });

      data[index].options = options;

      setEmployeeDetails(data);
    } else {
      toast?.error(search?.message);
    }
  };

  const handlePurchasePriceSearch = async (e, index) => {
    let data = [...employeeDetails];

    if (data[index].description) {
      const select = {
        api: "get-all-purchase-details",
        id: JSON?.parse(data[index].description)?._id,
      };

      const search = await select_id(select);
      if (search?.status) {
        let options = [];
        search?.data?.map((value, index) => {
          options?.push({
            value: JSON?.stringify(value),
            label:
              "" +
              value?.working_cost?.toFixed(3) +
              " (" +
              value?.purchase?.supplierorcontractor?.name +
              ", " +
              moment?.(value?.purchase?.date)?.format?.("DD/MM/YYYY") +
              ", #PO" +
              value?.purchase?.number +
              ")",
          });
        });

        data[index].purchase_options = options;
        setEmployeeDetails(data);
      } else {
        toast?.error(search?.message);
      }
    }
  };

  const handleDescription = (e, index) => {
    let data = [...employeeDetails];

    if (e) {
      let data_description = e ? e : "";
      let data_role = JSON?.parse(e)?.role?.name
        ? JSON?.parse(e)?.role?.name
        : "";
      let data_working_cost = JSON?.parse(e)?.working_cost
        ? parseFloat(JSON?.parse(e)?.working_cost)
        : "";

      if (data_description) {
        let ids = [];
        data?.map((v, i) => {
          if (v?.description) {
            ids?.push?.(JSON?.parse(v?.description)?._id);
          }
        });

        if (ids?.includes(JSON?.parse(e)?._id)) {
          let old_index = ids?.indexOf(JSON?.parse(e)?._id);
          let data_hours = data[old_index]?.hours ? data[old_index]?.hours : 0;

          let event = {
            target: {
              name: "hours",
              value: data_hours + 1,
            },
          };
          handleTotal(event, old_index);
        } else {
          let event = {
            target: {
              name: "working_cost",
              value: data_working_cost,
            },
          };
          data[index].description = data_description;
          data[index].role = data_role;
          setEmployeeDetails(data);
          handleTotal(event, index, "");
        }
      }
    } else {
      let event = {
        target: {
          name: "working_cost",
          value: "",
        },
      };
      data[index].description = null;
      data[index].role = "";
      setEmployeeDetails(data);
      handleTotal(event, index, "");
    }
  };

  const handlePurchase = (e, index, key) => {
    if (e) {
      let event = {
        target: {
          name: "working_cost",
          value: JSON?.parse(e)?.working_cost,
        },
      };

      handleTotal(event, index, key);
    }
  };

  const handleTotal = (e, index, key) => {
    let name = key ? key : e?.target?.name;
    let value = key && e ? e : !key && e?.target?.value ? e?.target?.value : "";

    if (value >= 0) {
      let data = [...employeeDetails];

      let data_working_cost = data[index]?.working_cost
        ? data[index]?.working_cost
        : 0;
      let data_hours = data[index]?.hours ? data[index]?.hours : 0;

      let working_cost = name === "working_cost" ? value : data_working_cost;
      let hours = name === "hours" ? value : data_hours;

      let total = parseFloat(working_cost) * parseFloat(hours ? hours : 1);
      data[index][name] = value;
      data[index].total = total ? total?.toFixed(3) : 0;

      let subtotal = data?.reduce(
        (acc, currentValue) =>
          parseFloat(acc) + parseFloat(currentValue?.total),
        0
      );

      let grandtotal = data?.reduce(
        (acc, currentValue) =>
          parseFloat(acc) + parseFloat(currentValue?.total),
        0
      );

      employeeTotals.subtotal = subtotal;
      employeeTotals.total = grandtotal;

      setEmployeeDetails(data);
      setEmployeeTotals({ ...employeeTotals });
    }
  };

  const handleRemove = (index) => {
    let data = [...employeeDetails];

    data?.splice(index, 1);

    setEmployeeDetails(data);
  };

  const handleAdd = () => {
    setEmployeeDetails((prevemployeeDetails) => [
      ...prevemployeeDetails,
      {
        id: "",
        description: null,
        role: "",
        working_cost: "",
        purchase_options: null,
        hours: "",
        total: 0,
      },
    ]);
  };

  return (
    <div>
      <table className="w-full">
        <thead>
          <tr>
            <td className="border p-4 text-black">S.No</td>
            <td className="border p-4 text-black">Description</td>
            <td className="border p-4 text-black">Role</td>
            <td className="border p-4 text-black">Working cost</td>
            <td className="border p-4 text-black">Hours</td>
            <td className="border p-4 text-black">Total</td>
            <td className="border p-4 text-black">Delete</td>
          </tr>
        </thead>
        <tbody>
          {employeeDetails?.map((value, index) => {
            return (
              <React.Fragment>
                <tr>
                  <td className="border p-4 text-center">{index + 1}</td>
                  <td className="border p-4">
                    <Select
                      className="w-[200px] text-regular"
                      name="description"
                      placeholder="Description"
                      allowClear={true}
                      showSearch={true}
                      filterOption={false}
                      options={value?.options}
                      value={value?.description}
                      onSearch={(e) => handleSearch(e, index)}
                      onClick={(e) => handleSearch("", index)}
                      onChange={(e) => handleDescription(e, index)}
                      autoComplete="off"
                    />
                  </td>
                  <td className="border p-4 text-center">{value?.role}</td>
                  <td className="border p-4 text-center">
                    {value?.working_cost}
                  </td>
                  <td className="border p-4 text-center">
                    <Input
                      type="number"
                      className="w-full"
                      name="hours"
                      placeholder="Hours"
                      value={value?.hours}
                      onChange={(e) => handleTotal(e, index)}
                      autoComplete="off"
                      required="required"
                    />
                  </td>
                  <td className="border p-4 text-center">
                    {parseFloat?.(value?.total)?.toFixed(3)}
                  </td>
                  <td className="border p-4 text-center">
                    <button
                      type="button"
                      className={
                        index == 0
                          ? "text-lg text-red-900 cursor-not-allowed"
                          : "text-lg text-red-500 cursor-pointer hover:text-red-800"
                      }
                      disabled={index == 0}
                      onClick={() => handleRemove(index)}
                    >
                      <DeleteOutlined />
                    </button>
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td className="border p-4" colSpan={4}></td>
            <td className="border p-4 text-black text-medium">Total</td>
            <td className="border p-4 text-black text-medium" colSpan={2}>
              {employeeTotals?.total > 0
                ? employeeTotals?.total?.toFixed(3)
                : "0.000"}
            </td>
          </tr>
        </tfoot>
      </table>
      <div className="flex justify-end p-3">
        <AddButton onClick={handleAdd} />
      </div>
    </div>
  );
});

export default Projecttaskemployeestable;
