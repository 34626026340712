const headers = {
  name: "Item",
  navigate: {
    path: [
      {
        name: "Items",
        path: "/app/items",
      },
      {
        name: "Add",
        path: "",
      },
    ],
  },
};

export default headers;
