import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import toast from "react-hot-toast";
import Header from "../../../../Componets/Header";
import {
  create_boq,
  get_boq,
  update_boq,
} from "../../../../Controllers/Sales/Boqs";
import forms from "../../../../Data/Forms/Sales/Boqs/forms";
import headers from "../../../../Data/Forms/Sales/Boqs/headers";
import Loadercomponent from "../../../../Componets/Loader";
import Bodycomponent from "../../../../Componets/Formcomponents/body";
import Titlecomponent from "../../../../Componets/Formcomponents/title";
import Formdata from "../../../../Componets/Formcomponents/formdata";
import Headercomponent from "../../../../Componets/Formcomponents/header";
import Boqtable from "../../../../Data/Forms/Sales/Boqs/table";

const BoqsForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const [details, setDetails] = useState([
    {
      id: "",
      description: null,
      unit: "",
      purchase_price: "",
      quantity: "",
      delivered: "",
      discount: "",
      delivery: "",
      tax: 0,
      total: 0,
    },
  ]);
  const [totals, setTotals] = useState({
    subtotal: "0.000",
    taxamount: "0.000",
    discount: 0,
    delivery: 0,
    delivery_status: 0,
    delivery_date: "",
    payment_status: 0,
    payment_types: null,
    payments: {
      Cash: "",
      Cheque: "",
      "Debit card": "",
      "Credit card": "",
      "Bank transfer": "",
      "Online payment": "",
    },
    paid: 0,
    remaining: 0,
    total: "0.000",
  });

  const formdata = async (data) => {
    if (data?.status) {
      setButtonLoader(true);

      const boq = id
        ? await update_boq({
            ...data?.data,
            id: id,
            details: details,
            subtotal: totals?.subtotal,
            taxamount: totals?.taxamount,
            discount: totals?.discount,
            delivery: totals?.delivery,
            delivery_status: totals?.delivery_status,
            delivery_date: totals?.delivery_date,
            payment_status: totals?.payment_status,
            payment_types: totals?.payment_types,
            payments: totals?.payments,
            total: totals?.total,
          })
        : await create_boq({
            ...data?.data,
            details: details,
            subtotal: totals?.subtotal,
            taxamount: totals?.taxamount,
            discount: totals?.discount,
            delivery: totals?.delivery,
            delivery_status: totals?.delivery_status,
            delivery_date: totals?.delivery_date,
            payment_status: totals?.payment_status,
            payment_types: totals?.payment_types,
            payments: totals?.payments,
            total: totals?.total,
          });

      if (boq?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(boq?.message);
        setButtonLoader(false);
      } else {
        toast?.error(boq?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = (boq) => {
    let customer = boq?.customer?._id ? boq?.customer?._id : null;
    let number = boq?.number ? boq?.number : "";
    let date = boq?.date ? boq?.date : "";
    let due_date = boq?.due_date ? boq?.due_date : "";
    let status = boq?.status ? boq?.status : 0;

    let customer_option = boq?.customer?._id
      ? [
          {
            value: boq?.customer?._id,
            label: boq?.customer?.name,
          },
        ]
      : null;

    values[0].options[0].form.value = customer;
    values[0].options[0].form.options = customer_option;
    values[0].options[1].form.value = number;
    values[0].options[2].form.value = date;
    values[0].options[3].form.value = due_date;
    values[0].options[4].form.value = status;

    let data = [];
    boq?.details?.map((value, index) => {
      data?.push({
        id: value?._id,
        description: JSON?.stringify(value?.description),
        options: [
          {
            value: JSON?.stringify(value?.description),
            label: value?.description?.name,
          },
        ],
        unit: value?.unit,
        purchase_price: value?.purchase_price,
        quantity: value?.quantity,
        delivered: value?.delivered,
        discount: value?.discount,
        delivery: value?.delivery,
        tax: value?.tax,
        total: value?.total,
      });
    });

    totals.subtotal = boq?.subtotal;
    totals.taxamount = boq?.taxamount;
    totals.discount = boq?.discount;
    totals.delivery = boq?.delivery;
    totals.delivery_status = boq?.delivery_status;
    totals.delivery_date = boq?.delivery_date;
    totals.payment_status = boq?.payment_status;
    totals.payment_types = JSON?.parse(boq?.payment_types);
    totals.payments = JSON?.parse(boq?.payments);
    totals.paid = boq?.paid;
    totals.remaining = boq?.remaining;
    totals.total = boq?.total;

    setValues([...values]);
    setDetails([...data]);
    setTotals({ ...totals });
    setLoader(false);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      const data = {
        id: id,
      };

      const boq = await get_boq(data);
      if (boq?.status) {
        handleData(boq?.data);
      } else {
        toast?.error(boq?.message);
      }
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        >
          <Bodycomponent>
            <Titlecomponent index={1} title={"Items"} />
            <Formdata>
              <Headercomponent index={0} name={"Details"} required={true} />
              <Boqtable
                details={details}
                setDetails={setDetails}
                totals={totals}
                setTotals={setTotals}
              />
            </Formdata>
          </Bodycomponent>
        </Forms>
      </Loadercomponent>
    </Header>
  );
};

export default BoqsForm;
