import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import toast from "react-hot-toast";
import Header from "../../../../Componets/Header";
import {
  create_project_task,
  get_project_task,
  update_project_task,
} from "../../../../Controllers/Projects/Tasks";
import forms from "../../../../Data/Forms/Projects/Tasks/forms";
import headers from "../../../../Data/Forms/Projects/Tasks/headers";
import Loadercomponent from "../../../../Componets/Loader";
import { convert_to_file } from "../../../../Controllers/Global";
import Headercomponent from "../../../../Componets/Formcomponents/header";
import Bodycomponent from "../../../../Componets/Formcomponents/body";
import Titlecomponent from "../../../../Componets/Formcomponents/title";
import Formdata from "../../../../Componets/Formcomponents/formdata";
import Projecttaskitemstable from "../../../../Data/Forms/Projects/Tasks/items";
import Projecttaskemployeestable from "../../../../Data/Forms/Projects/Tasks/employees";

const ProjectsTasksForm = () => {
  const { project, id } = useParams();
  let navigate = useNavigate();

  headers.navigate.path[0].path = `/app/projects/tasks/${project}`;

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const [itemDetails, setItemDetails] = useState([
    {
      id: "",
      description: null,
      unit: "",
      purchase_price: "",
      purchase_options: "",
      quantity: "",
      tax: 0,
      tax_amount: 0,
      total: 0,
    },
  ]);
  const [itemTotals, setItemTotals] = useState({
    subtotal: "0.000",
    taxamount: "0.000",
    total: "0.000",
  });

  const [employeeDetails, setEmployeeDetails] = useState([
    {
      id: "",
      description: null,
      role: "",
      working_cost: "",
      hours: "",
      total: 0,
    },
  ]);
  const [employeeTotals, setEmployeeTotals] = useState({
    total: "0.000",
  });

  const formdata = async (data) => {
    if (data?.status) {
      setButtonLoader(true);

      console.log(itemDetails, "itemDetailsitemDetails");

      const task = id
        ? await update_project_task({
            ...data?.data,
            id: id,
            project: project,
            item_details: itemDetails,
            employee_details: employeeDetails,
            item_subtotal: itemTotals?.subtotal,
            item_taxamount: itemTotals?.taxamount,
            item_total: itemTotals?.total,
            employee_total: employeeTotals?.total,
          })
        : await create_project_task({
            ...data?.data,
            project: project,
            item_details: itemDetails,
            employee_details: employeeDetails,
            item_subtotal: itemTotals?.subtotal,
            item_taxamount: itemTotals?.taxamount,
            item_total: itemTotals?.total,
            employee_total: employeeTotals?.total,
          });

      if (task?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(task?.message);
        setButtonLoader(false);
      } else {
        toast?.error(task?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = async (task) => {
    let image = task?.image ? task?.image : "";
    let file = await convert_to_file(image);
    let project_job = task?.project_job?._id ? task?.project_job?._id : null;
    let name = task?.name ? task?.name : "";
    let description = task?.description ? task?.description : "";
    let number = task?.number ? task?.number : "";
    let task_status = task?.task_status ? task?.task_status : null;
    let status = task?.status ? task?.status : 0;

    let project_job_option = task?.project_job?._id
      ? [
          {
            value: task?.project_job?._id,
            label: task?.project_job?.name,
          },
        ]
      : null;

    values[0].options[1].form.params.project = project;

    values[0].options[0].form.value = file;
    values[0].options[1].form.value = project_job;
    values[0].options[1].form.options = project_job_option;
    values[0].options[2].form.value = name;
    values[0].options[3].form.value = description;
    values[0].options[4].form.value = number;

    values[1].options[0].form.value = task_status;
    values[1].options[1].form.value = status;

    values[0].options[1].form.params = {
      api: "get-project-jobs",
      status: 1,
      project: project,
    };
    values[0].options[1].path = `/app/projects/jobs/add/${project}`;

    let item_data = [];
    task?.item_details?.map((value, index) => {
      item_data?.push({
        id: value?._id,
        description: JSON?.stringify(value?.description),
        options: [
          {
            value: JSON?.stringify(value?.description),
            label: value?.description?.name,
          },
        ],
        unit: value?.unit,
        purchase_price: value?.purchase_price,
        quantity: value?.quantity,
        used: value?.used,
        remaining: value?.remaining,
        tax: value?.tax,
        total: value?.total,
      });
    });
    itemTotals.subtotal = task?.item_subtotal;
    itemTotals.taxamount = task?.item_taxamount;
    itemTotals.total = task?.item_total;

    let employee_data = [];
    task?.employee_details?.map((value, index) => {
      employee_data?.push({
        id: value?._id,
        description: JSON?.stringify(value?.description),
        options: [
          {
            value: JSON?.stringify(value?.description),
            label: value?.description?.name,
          },
        ],
        role: value?.role,
        working_cost: value?.working_cost,
        hours: value?.hours,
        worked: value?.worked,
        remaining: value?.remaining,
        tax: value?.tax,
        total: value?.total,
      });
    });
    employeeTotals.total = task?.employee_total;

    setItemDetails([...item_data]);
    setItemTotals({ ...itemTotals });
    setEmployeeDetails([...employee_data]);
    setEmployeeTotals({ ...employeeTotals });
    setValues([...values]);
    setLoader(false);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      const data = {
        id: id,
      };

      const task = await get_project_task(data);
      if (task?.status) {
        handleData(task?.data);
      } else {
        toast?.error(task?.message);
      }
    } else {
      values[0].options[1].form.params = {
        api: "get-project-jobs",
        status: 1,
        project: project,
      };
      values[0].options[1].path = `/app/projects/jobs/add/${project}`;

      setLoader(false);
      setValues([...values]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        >
          <Bodycomponent>
            <Titlecomponent index={2} title={"Items"} />
            <Formdata>
              <Headercomponent index={0} name={"Details"} required={true} />
              <Projecttaskitemstable
                itemDetails={itemDetails}
                setItemDetails={setItemDetails}
                itemTotals={itemTotals}
                setItemTotals={setItemTotals}
              />
            </Formdata>
          </Bodycomponent>
          <Bodycomponent>
            <Titlecomponent index={3} title={"Employees"} />
            <Formdata>
              <Headercomponent index={0} name={"Details"} required={true} />
              <Projecttaskemployeestable
                employeeDetails={employeeDetails}
                setEmployeeDetails={setEmployeeDetails}
                employeeTotals={employeeTotals}
                setEmployeeTotals={setEmployeeTotals}
              />
            </Formdata>
          </Bodycomponent>
        </Forms>
      </Loadercomponent>
    </Header>
  );
};

export default ProjectsTasksForm;
