import { AutoComplete, DatePicker, Form, Input, Select } from "antd";
import React, { createRef, useEffect, useRef, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";
import AddButton from "../../../../Componets/Buttons/add";
import { select_search, select_id } from "../../../../Controllers/Global";
import moment from "moment";

const Projecttimesheetitemstable = React.forwardRef((props, ref) => {
  const { itemDetails, setItemDetails, itemTotals, setItemTotals } = props;

  const handleTotal = (e, index, key) => {
    let name = key ? key : e?.target?.name;
    let value = key && e ? e : !key && e?.target?.value ? e?.target?.value : "";

    if (value >= 0) {
      let data = [...itemDetails];

      let data_purchase_price = data[index]?.purchase_price
        ? data[index]?.purchase_price
        : 0;
      let data_quantity = data[index]?.quantity ? data[index]?.quantity : 0;
      let data_remaining = data[index]?.remaining ? data[index]?.remaining : 0;
      let data_stock = data[index]?.stock ? data[index]?.stock : 0;
      let data_stock_type = data[index]?.stock_type
        ? data[index]?.stock_type
        : 0;
      let data_used = data[index]?.used ? data[index]?.used : 0;
      let data_tax = data[index]?.tax ? data[index]?.tax : 0;

      let purchase_price =
        name === "purchase_price" ? value : data_purchase_price;
      let quantity = name === "quantity" ? value : data_quantity;
      let remaining = name === "remaining" ? value : data_remaining;
      let stock = name === "remaining" ? value : data_stock;
      let used = name === "used" ? value : data_used;
      let tax = name === "tax" ? value : data_tax;

      if (
        (!data_stock_type && parseFloat(used) <= parseFloat(remaining)) ||
        (data_stock_type &&
          parseFloat(used) <= parseFloat(stock) &&
          parseFloat(used) <= parseFloat(remaining)) ||
        isNaN(parseFloat(used))
      ) {
        let tax_amount = tax
          ? parseFloat(purchase_price) *
            parseFloat(used ? used : 0) *
            (parseFloat(tax) / 100)
          : 0;

        let total =
          parseFloat(purchase_price) * parseFloat(used ? used : 0) +
          parseFloat(tax_amount);

        data[index][name] = value;
        data[index].tax = tax ? tax : 0;
        data[index].tax_amount = tax_amount ? tax_amount?.toFixed(3) : 0;
        data[index].total = total ? total?.toFixed(3) : 0;
        data[index].tax_amount = tax_amount ? tax_amount?.toFixed(3) : 0;

        let subtotal =
          data?.reduce(
            (acc, currentValue) =>
              parseFloat(acc) + parseFloat(currentValue?.total),
            0
          ) -
          data?.reduce(
            (acc, currentValue) =>
              parseFloat(acc) + parseFloat(currentValue?.tax_amount),
            0
          );

        let taxamount = data?.reduce(
          (acc, currentValue) =>
            parseFloat(acc) + parseFloat(currentValue?.tax_amount),
          0
        );

        let grandtotal = data?.reduce(
          (acc, currentValue) =>
            parseFloat(acc) + parseFloat(currentValue?.total),
          0
        );

        itemTotals.subtotal = subtotal;
        itemTotals.taxamount = taxamount;
        itemTotals.total = grandtotal;

        setItemDetails(data);
        setItemTotals({ ...itemTotals });
      } else {
        if (data_stock_type && parseFloat(stock) < parseFloat(remaining)) {
          toast?.error(`Stock only ${stock}`);
        } else if (
          data_stock_type &&
          parseFloat(stock) > parseFloat(remaining)
        ) {
          toast?.error(`Remaining only ${remaining}`);
        } else {
          toast?.error(`Remaining only ${remaining}`);
        }
      }
    }
  };

  return (
    <div>
      <table className="w-full">
        <thead>
          <tr>
            <td className="border p-4 text-black">S.No</td>
            <td className="border p-4 text-black">Description</td>
            <td className="border p-4 text-black">Unit</td>
            <td className="border p-4 text-black">Purchase Price</td>
            <td className="border p-4 text-black">Quantity</td>
            <td className="border p-4 text-black">Remaining</td>
            <td className="border p-4 text-black">Stock</td>
            <td className="border p-4 text-black">Used</td>
            <td className="border p-4 text-black">Tax</td>
            <td className="border p-4 text-black">Total</td>
          </tr>
        </thead>
        <tbody>
          {itemDetails?.map((value, index) => {
            return (
              <React.Fragment>
                <tr>
                  <td className="border p-4 text-center">{index + 1}</td>
                  <td className="border p-4">
                    {JSON?.parse(value?.description)?.name}
                  </td>
                  <td className="border p-4 text-center">{value?.unit}</td>
                  <td className="border p-4 text-center">
                    {value?.purchase_price}
                  </td>
                  <td className="border p-4 text-center">{value?.quantity}</td>
                  <td className="border p-4 text-center">{value?.remaining}</td>
                  <td className="border p-4 text-center">
                    {value?.stock_type ? value?.stock : "Nonstockable"}
                  </td>
                  <td className="border p-4 text-center">
                    <Input
                      type="number"
                      className="w-full"
                      name="used"
                      placeholder="Used"
                      value={value?.used}
                      onChange={(e) => handleTotal(e, index)}
                      autoComplete="off"
                      required="required"
                    />
                  </td>
                  <td className="border p-4 text-center">{value?.tax}%</td>
                  <td className="border p-4 text-center">
                    {parseFloat?.(value?.total)?.toFixed(3)}
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td className="border p-4" colSpan={8}></td>
            <td className="border p-4">Subtotal</td>
            <td className="border p-4" colSpan={2}>
              {itemTotals?.subtotal > 0
                ? itemTotals?.subtotal?.toFixed(3)
                : "0.000"}
            </td>
          </tr>
          <tr>
            <td className="border p-4" colSpan={8}></td>
            <td className="border p-4">Tax&nbsp;amount</td>
            <td className="border p-4" colSpan={2}>
              {itemTotals?.taxamount > 0
                ? itemTotals?.taxamount?.toFixed(3)
                : "0.000"}
            </td>
          </tr>
          <tr>
            <td className="border p-4" colSpan={8}></td>
            <td className="border p-4 text-black text-medium">Total</td>
            <td className="border p-4 text-black text-medium" colSpan={2}>
              {itemTotals?.total > 0 ? itemTotals?.total?.toFixed(3) : "0.000"}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
});

export default Projecttimesheetitemstable;
