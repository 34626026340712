import {
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
  PrinterOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Button, DatePicker, Input, Radio, Select, Upload } from "antd";
import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactToPrint, { useReactToPrint } from "react-to-print";
// import QuoteTable from "./table";
import toast from "react-hot-toast";
import AddButton from "../../../Componets/Buttons/add";
import { select_search } from "../../../Controllers/Global";

const AddboqForm = (props) => {
  let navigate = useNavigate();
  let location = useLocation();

  let data = {
    from: "Supplier",
    number: "",
    date: "",
    due_date: null,
    supplier: null,
    supplier_options: [],
    contractor: null,
    contractor_options: [],
    items: [],
    details: [
      {
        item: "",
        units: "",
        price: "",
        quantity: "",
        total: "",
      },
    ],
    total: "",
    tax: "",
    amount: "",
  };

  const [form, setForm] = useState(data);

  // const { details, setDetails, totals, setTotals } = props;

  const [table, setTable] = useState([
    {
      sl: "",
      description: null,
      options: null,
      unit: "",
      purchase_price: "",
      quantity: "",
      discount: "",
      tax: 0,
      total: 0,
    },
  ]);

  const [delivery, setDelivery] = useState(0);
  const [discout, setDiscout] = useState(0);

  const handleDelivery = (e) => {
    if (e?.target?.value >= 0) {
      let total =
        parseFloat(
          table?.reduce(
            (acc, currentValue) =>
              parseFloat(acc) + parseFloat(currentValue?.total),
            0
          )
        ) +
        parseFloat(e?.target?.value) -
        parseFloat(discout);

      setDelivery(e?.target?.value);
      // setTotals((prev) => ({
      //   ...prev,
      //   delivery: parseFloat(e?.target?.value)?.toFixed(3),
      //   total: total?.toFixed(3),
      // }));
    }
  };

  const handleDiscount = (e) => {
    if (e?.target?.value >= 0) {
      let total =
        parseFloat(
          table?.reduce(
            (acc, currentValue) =>
              parseFloat(acc) + parseFloat(currentValue?.total),
            0
          )
        ) +
        parseFloat(delivery) -
        parseFloat(e?.target?.value);

      setDiscout(e?.target?.value);
      // setTotals((prev) => ({
      //   ...prev,
      //   discount: parseFloat(e?.target?.value)?.toFixed(3),
      //   total: total?.toFixed(3),
      // }));
    }
  };

  const handleSearch = async (e, index) => {
    let data = [...table];

    const select = {
      api: "get-all-items",
      search: e,
      status: 1,
    };

    const search = await select_search(select);

    if (search?.status) {
      let options = [];
      search?.data?.map((value, index) => {
        options?.push({ value: value?._id, label: value?.name });
      });

      data[index].options = options;

      setTable(data);
      // setDetails(data);
    } else {
      toast?.error(search?.message);
    }
  };

  const handlesl = (e, index) => {
    let data = [...table];
    data[index].sl = e?.target?.value;

    setTable(data);
    // setDetails(data);
  };

  const handleDescription = (e, index) => {
    let data = [...table];
    data[index].description = e?.target?.value;

    setTable(data);
    // setDetails(data);
  };

  const handleUnits = (e, index) => {
    let data = [...table];
    data[index].unit = e?.target?.value;

    setTable(data);
    // setDetails(data);
  };

  const handleTotal = (e, index, key) => {
    let name = key ? key : e?.target?.name;
    let value = key && e ? e : !key && e?.target?.value ? e?.target?.value : "";

    console.log(e, e);

    if (value >= 0) {
      let data = [...table];

      let data_purchase_price = data[index]?.purchase_price
        ? data[index]?.purchase_price
        : 0;
      let data_quantity = data[index]?.quantity ? data[index]?.quantity : 0;
      let data_free = data[index]?.free ? data[index]?.free : 0;
      let data_tax = data[index]?.tax ? data[index]?.tax : 0;

      let purchase_price =
        name === "purchase_price" ? value : data_purchase_price;
      let quantity = name === "quantity" ? value : data_quantity;
      let free = name === "free" ? value : data_free;
      let tax = name === "tax" ? value : data_tax;

      let tax_amount = tax
        ? parseFloat(purchase_price) *
          parseFloat(quantity) *
          (parseFloat(tax) / 100)
        : 0;

      let total =
        parseFloat(purchase_price) * parseFloat(quantity) +
        parseFloat(tax_amount);

      let per_unit =
        parseFloat(total) / (parseFloat(quantity) + parseFloat(free));

      data[index][name] = value;
      data[index].tax = tax;
      data[index].tax_amount = tax;
      data[index].total = total ? total?.toFixed(3) : 0;
      data[index].per_unit = per_unit ? per_unit?.toFixed(3) : 0;
      data[index].tax_amount = tax_amount ? tax_amount?.toFixed(3) : 0;

      let grandtotal =
        data?.reduce(
          (acc, currentValue) =>
            parseFloat(acc) + parseFloat(currentValue?.total),
          0
        ) +
        parseFloat(delivery) -
        parseFloat(discout);

      setTable(data);
      // setDetails(data);
      // setTotals((prev) => ({
      //   ...prev,
      //   subtotal: (
      //     data?.reduce(
      //       (acc, currentValue) =>
      //         parseFloat(acc) + parseFloat(currentValue?.total),
      //       0
      //     ) -
      //     data?.reduce(
      //       (acc, currentValue) =>
      //         parseFloat(acc) + parseFloat(currentValue?.tax_amount),
      //       0
      //     )
      //   )?.toFixed(3),
      //   taxamount: data
      //     ?.reduce(
      //       (acc, currentValue) =>
      //         parseFloat(acc) + parseFloat(currentValue?.tax_amount),
      //       0
      //     )
      //     ?.toFixed(3),
      //   total: grandtotal?.toFixed(3),
      // }));
    }
  };

  const handleRemove = (index) => {
    let data = [...table];
    data?.splice(index, 1);

    setTable(data);
    // setDetails(data);
  };

  const handleAdd = (product) => {
    setTable((prevProducts) => [
      ...prevProducts,
      {
        sl: "",
        description: "",
        purchase_price: "",
        unit: "",
        quantity: "",
        unit: "",
        free: "",
        tax: 0,
        total: 0,
      },
    ]);
    // setDetails((prevProducts) => [
    //   ...prevProducts,
    //   {
    //     description: product || "",
    //     purchase_price: "",
    //     quantity: "",
    //     free: "",
    //     tax: 0,
    //     total: 0,
    //   },
    // ]);
  };

  const subtotal = (
    table?.reduce(
      (acc, currentValue) => parseFloat(acc) + parseFloat(currentValue?.total),
      0
    ) -
    table?.reduce(
      (acc, currentValue) =>
        parseFloat(acc) + parseFloat(currentValue?.tax_amount),
      0
    )
  )?.toFixed(3);

  const totalTaxAmount = table
    ?.reduce(
      (acc, currentValue) =>
        parseFloat(acc) + parseFloat(currentValue?.tax_amount),
      0
    )
    ?.toFixed(3);

  const totalAmount = (
    table?.reduce(
      (acc, currentValue) =>
        parseFloat(acc) + parseFloat(currentValue?.total || 0),
      0
    ) -
    parseFloat(discout || 0) +
    parseFloat(delivery || 0)
  ).toFixed(3);

  return (
    <div>
      {/* Header Topbar Start */}
      <div className="p-4 bg-white border-b h-[60px] flex justify-between items-center">
        <div className="">
          <span
            className="text-gray-500 cursor-pointer"
            onClick={() => navigate("/app/quotes/boq")}
          >
            Boq
          </span>
          <span className="px-1">
            <RightOutlined className="text-xs text-gray-500" />
          </span>
          <span className="">Add</span>
        </div>
      </div>
      {/* </div> */}
      {/* Header Topbar Start */}
      <div class="my-8">
        <div class="bg-white border rounded-md p-8 w-[80%] mx-auto">
          {/* detail */}
          <div>
            <div className="flex justify-between pb-5">
              <div className="text-medium  ">Details</div>
              {/* <div className="text-medium text-2xl">
                <PrinterOutlined onClick={() => navigate("/app/quotes/view")} />
              </div> */}
            </div>
            <div className="border text-sm text-medium text-gray-500 rounded-md p-4">
              <div>Boq Numbers</div>
              <div>
                <Input
                  className="w-full my-3 text-medium"
                  placeholder="Boq Number"
                />
              </div>
              <div>Project</div>
              <div>
                <Select
                  className="w-full my-3 text-medium"
                  placeholder="Project number"
                  allowClear
                  showSearch
                  options={[
                    { value: "#PNO18501", label: "#PNO18501" },
                    { value: "#PNO18502", label: "#PNO18502" },
                    { value: "#PNO18503", label: "#PNO18503" },
                    { value: "#PNO18504", label: "#PNO18504" },
                    { value: "#PNO18505", label: "#PNO18505" },
                    { value: "#PNO18506", label: "#PNO18506" },
                  ]}
                />
              </div>
              <div>Date</div>
              <div>
                <DatePicker
                  className="w-full my-3 text-medium"
                  placeholder="Date"
                />
              </div>
              {/* <div>Due Date</div>
              <div>
                <DatePicker
                  className="w-full my-3 text-medium"
                  placeholder="Due Date"
                />
              </div> */}
              <div>Customer</div>
              <div>
                <Select
                  className="w-full my-3 text-medium"
                  placeholder="Select Customer"
                  options={[
                    {
                      value: "BrightWave Solutions",
                      name: "BrightWave Solutions",
                    },
                    { value: "NovaGenix Labs", name: "NovaGenix Labs" },
                    { value: "InfinityWorks Inc.", name: "InfinityWorks Inc." },
                    { value: "NexusGen Biotech", name: "NexusGen Biotech" },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="pt-6">
            <div className="text-medium  pb-3">Items</div>
            <div className="border text-sm text-medium text-gray-500 rounded-md p-4">
              {/* <QuoteTable form={form} setForm={setForm} /> */}
              <div>
                <table className="w-full">
                  <thead>
                    <tr>
                      <td className="border p-4 text-black">S.No</td>
                      <td className="border p-4 text-black">Description</td>
                      <td className="border p-4 text-black">Unit</td>
                      <td className="border p-4 text-black">Price</td>
                      <td className="border p-4 text-black">Quantity</td>
                      <td className="border p-4 text-black">Tax</td>
                      <td className="border p-4 text-black">Total</td>
                      <td className="border p-4 text-black">Delete</td>
                    </tr>
                  </thead>
                  <tbody>
                    {table?.map((value, index) => {
                      return (
                        <React.Fragment>
                          <tr>
                            <td className="border p-4 text-center">
                              {/* {index + 1} */}
                              <Input
                                type="text"
                                className="w-full"
                                name="sl"
                                placeholder="Sl.No"
                                value={value?.sl}
                                onChange={(e) => handlesl(e, index)}
                                autoComplete="off"
                                required="required"
                              />
                            </td>
                            <td className="border p-4">
                              {/* <Select
                                className="w-full"
                                name="description"
                                placeholder="Description"
                                allowClear={true}
                                showSearch={true}
                                filterOption={false}
                                options={value?.options}
                                value={value?.description}
                                onSearch={(e) => handleSearch(e, index)}
                                onClick={(e) => handleSearch("", index)}
                                onChange={(e) => handleDescription(e, index)}
                                autoComplete="off"
                              /> */}
                              <Input
                                type="text"
                                className="w-full"
                                name="description"
                                placeholder="Description"
                                value={value?.description}
                                onChange={(e) => handleDescription(e, index)}
                                autoComplete="off"
                                required="required"
                              />
                            </td>
                            <td className="border p-4">
                              <Input
                                type="text"
                                className="w-full"
                                name="unit"
                                placeholder="Unit"
                                value={value?.unit}
                                onChange={(e) => handleUnits(e, index)}
                                autoComplete="off"
                                required="required"
                              />
                            </td>
                            <td className="border p-4 text-center">
                              <Input
                                type="number"
                                className="w-full"
                                name="purchase_price"
                                placeholder="Price"
                                value={value?.purchase_price}
                                onChange={(e) => handleTotal(e, index)}
                                autoComplete="off"
                                required="required"
                              />
                            </td>
                            <td className="border p-4 text-center">
                              <Input
                                type="number"
                                className="w-full"
                                name="quantity"
                                placeholder="Quantity"
                                value={value?.quantity}
                                onChange={(e) => handleTotal(e, index)}
                                autoComplete="off"
                                required="required"
                              />
                            </td>
                            <td className="border p-4 text-center">
                              <Select
                                className="w-full"
                                name="tax"
                                placeholder="Tax"
                                options={[
                                  {
                                    value: 0,
                                    label: "0%",
                                  },
                                  {
                                    value: 5,
                                    label: "5%",
                                  },
                                ]}
                                value={value?.tax}
                                onChange={(e) => handleTotal(e, index, "tax")}
                              />
                            </td>
                            <td className="border p-4 text-center">
                              {parseFloat?.(value?.total)?.toFixed(3)}
                            </td>
                            <td className="border p-4 text-center">
                              <button
                                type="button"
                                className={
                                  index == 0
                                    ? "text-lg text-red-900 cursor-not-allowed"
                                    : "text-lg text-red-500 cursor-pointer hover:text-red-800"
                                }
                                disabled={index == 0}
                                onClick={() => handleRemove(index)}
                              >
                                <DeleteOutlined />
                              </button>
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className="border p-4" colSpan={5}></td>
                      <td className="border p-4">Subtotal</td>
                      <td className="border p-4" colSpan={2}>
                        {subtotal > 0 ? subtotal : "0.000"}
                        {/* {totals?.subtotal > 0 ? totals?.subtotal : "0.000"} */}
                      </td>
                    </tr>
                    <tr>
                      <td className="border p-4" colSpan={5}></td>
                      <td className="border p-4">Tax&nbsp;amount</td>
                      <td className="border p-4" colSpan={2}>
                        {totalTaxAmount > 0 ? totalTaxAmount : "0.000"}
                        {/* {totals?.taxamount > 0 ? totals?.taxamount : "0.000"} */}
                      </td>
                    </tr>
                    <tr>
                      <td className="border p-4" colSpan={5}></td>
                      <td className="border p-4">Discount&nbsp;</td>
                      <td className="border p-4" colSpan={2}>
                        <Input
                          type="number"
                          className="w-full"
                          placeholder="Discount amount"
                          name="discount"
                          value={discout}
                          autoComplete="off"
                          onChange={handleDiscount}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border p-4" colSpan={5}></td>
                      <td className="border p-4">Delivery&nbsp;</td>
                      <td className="border p-4" colSpan={2}>
                        <Input
                          type="number"
                          className="w-full"
                          placeholder="Delivery charges"
                          name="delivery"
                          value={delivery}
                          autoComplete="off"
                          onChange={handleDelivery}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border p-4" colSpan={5}></td>
                      <td className="border p-4 text-black text-medium">
                        Total
                      </td>
                      <td
                        className="border p-4 text-black text-medium"
                        colSpan={2}
                      >
                        {totalAmount > 0 ? totalAmount : "0.000"}
                        {/* {totals?.total > 0 ? totals?.total : "0.000"} */}
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <div className="flex justify-end p-3">
                  <AddButton onClick={handleAdd} />
                </div>
              </div>
            </div>
          </div>

          {/* submit */}
          <div className="py-6">
            <div className="float-end">
              <Button className="mr-2 text-medium" onClick={() => navigate(-1)}>
                Cancel
              </Button>
              <Button
                className="text-medium"
                type="primary"
                onClick={() => {
                  navigate(-1);
                  toast?.success(
                    location?.pathname == "/app/sales/add"
                      ? "Invoice created"
                      : "Quotation created"
                  );
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddboqForm;
