import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_project_issue = async (project_issues) => {
  try {
    let data = new FormData();
    data.append("project", project_issues?.project);
    data.append("image", project_issues?.image[0]);
    data.append("date", project_issues?.date);
    data.append("project_task", project_issues?.project_task);
    data.append("name", project_issues?.name);
    data.append("description", project_issues?.description);
    data.append("number", project_issues?.number);
    data.append("issue_status", project_issues?.issue_status);
    data.append("status", project_issues?.status);

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/create-project-issue`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_project_issue = async (project_issues) => {
  try {
    let data = new FormData();
    data.append("id", project_issues?.id);
    data.append("project", project_issues?.project);
    data.append("image", project_issues?.image[0]);
    data.append("date", project_issues?.date);
    data.append("project_task", project_issues?.project_task);
    data.append("description", project_issues?.description);
    data.append("name", project_issues?.name);
    data.append("number", project_issues?.number);
    data.append("issue_status", project_issues?.issue_status);
    data.append("status", project_issues?.status);

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/update-project-issue`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_project_issue = async (project_issues) => {
  try {
    const data = {
      id: project_issues?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-project-issue`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_project_issues = async (project_issues) => {
  try {
    const data = {
      project: project_issues?.project,
      search: project_issues?.search,
      item: project_issues?.item,
      employee: project_issues?.employee,
      project_issues_status: project_issues?.project_issues_status,
      status: project_issues?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-project-issues`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export {
  create_project_issue,
  update_project_issue,
  get_project_issue,
  get_all_project_issues,
};
