import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_project_timesheet = async (project_timesheet) => {
  try {
    let data = {
      project: project_timesheet?.project,
      date: project_timesheet?.date,
      project_task: project_timesheet?.project_task,
      status: 0,
      item_details: project_timesheet?.item_details,
      employee_details: project_timesheet?.employee_details,
      item_subtotal: project_timesheet?.item_subtotal,
      item_taxamount: project_timesheet?.item_taxamount,
      item_total: project_timesheet?.item_total,
      employee_total: project_timesheet?.employee_total,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/create-project-timesheet`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_project_timesheet = async (project_timesheet) => {
  try {
    let data = {
      id: project_timesheet?.id,
      project: project_timesheet?.project,
      date: project_timesheet?.date,
      project_task: project_timesheet?.project_task,
      status: 0,
      item_details: project_timesheet?.item_details,
      employee_details: project_timesheet?.employee_details,
      item_subtotal: project_timesheet?.item_subtotal,
      item_taxamount: project_timesheet?.item_taxamount,
      item_total: project_timesheet?.item_total,
      employee_total: project_timesheet?.employee_total,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/update-project-timesheet`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_project_timesheet = async (project_timesheet) => {
  try {
    const data = {
      id: project_timesheet?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-project-timesheet`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_project_timesheets = async (project_timesheet) => {
  try {
    const data = {
      project: project_timesheet?.project,
      search: project_timesheet?.search,
      item: project_timesheet?.item,
      employee: project_timesheet?.employee,
      project_timesheet_status: project_timesheet?.project_timesheet_status,
      status: project_timesheet?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-project-timesheets`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export {
  create_project_timesheet,
  update_project_timesheet,
  get_project_timesheet,
  get_all_project_timesheets,
};
