import { Select, Spin } from "antd";
import React, { useState } from "react";
import Error from "../Error";
import PlusButton from "../Buttons/plus";
import { useNavigate } from "react-router-dom";
import { select_search } from "../../Controllers/Global";
import toast from "react-hot-toast";

const Selectaddcomponent = React.forwardRef((props, focus) => {
  const { index, ind, val, form, setForm, getFormdata } = props;

  const [loader, setLoader] = useState(false);

  let navigate = useNavigate();

  const handleSelect = (e, index, ind) => {
    let data = [...form];

    data[index].options[ind].form.value = e;

    if (data[index]?.options[ind]?.show?.check) {
      data?.map((value, k) => {
        value?.options?.map((val, i) => {
          if (val?.show?.name == data[index].options[ind].form.name) {
            data[k].options[i].show.status = e ? true : false;
          }
        });
      });
    }

    setForm(data);
    getFormdata(data);
  };

  const handleSearch = async (e, index, ind) => {
    setLoader(true);

    let data = [...form];

    const select = !data[index].options[ind].form.api
      ? { ...data[index].options[ind].form.params }
      : {
          api: data[index].options[ind].form.api,
          search: e,
          status: 1,
        };

    const search = await select_search(select);

    if (search?.status) {
      let options = [];
      search?.data?.map((value, index) => {
        options?.push({ value: value?._id, label: value?.name });
      });

      data[index].options[ind].form.options = options;

      setForm(data);
      getFormdata(data);
      setLoader(false);
    } else {
      toast?.error(search?.message);
      setLoader(false);
    }
  };

  return (
    <React.Fragment>
      <div className="flex w-full">
        <div className="flex-grow w-full">
          <Select
            key={index}
            id={index?.toString()?.concat(ind)}
            name={val?.form?.name}
            placeholder={val?.form?.placeholder}
            className={
              val?.form?.className
                ? val?.form?.className
                : "text-regular w-full"
            }
            ref={focus.current[val?.form?.name?.concat(index + "" + ind)]}
            size={val?.form?.size}
            mode={val?.form?.mode}
            allowClear={val?.form?.allowClear}
            showSearch={val?.form?.showSearch}
            filterOption={val?.form?.filterOption}
            // disabled={loader}
            options={val?.form?.options}
            value={val?.form?.value}
            onClick={() => handleSearch("", index, ind)}
            onSearch={(e) => handleSearch(e, index, ind)}
            onChange={(e) => handleSelect(e, index, ind)}
          />
        </div>
        <div className="pl-3">
          <PlusButton onClick={() => navigate(val?.path)} />
        </div>
      </div>
      <Error id={val?.form?.name?.concat(index + "" + ind)} />
    </React.Fragment>
  );
});

export default Selectaddcomponent;
