import React from "react";
import ProfileButton from "../Buttons/profile";
import URL from "../../Main/url";
import DefaultImage from "../../Assets/Images/defaultimage.png";

const Topbar = (props) => {
  let { body } = props;
  return (
    <div className="bg-white fixed top-0 left-0 w-full z-50 h-16">
      <div className="mx-auto flex items-center h-full">
        <div className="w-64 border-b border-r flex items-center h-full">
          <ProfileButton body={body} />
        </div>
        <div className="px-4 flex-1 border-b flex items-center h-full">
          <div className="">
            <div className="text-medium flex">
              <div>
                <img
                  src={
                    body?.branch?.image
                      ? `${URL}/uploads${body?.branch?.image}`
                      : DefaultImage
                  }
                  className="w-[40px] h-[40px] rounded-md object-cover"
                />
              </div>
              <div className="my-auto pl-2">{body?.branch?.name}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
