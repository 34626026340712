import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_quote = async (quote) => {
  try {
    const data = {
      customer: quote?.customer,
      project: quote?.project,
      number: quote?.number,
      date: quote?.date,
      due_date: quote?.due_date,
      status: quote?.status,
      details: quote?.details,
      subtotal: quote?.subtotal,
      taxamount: quote?.taxamount,
      discount: quote?.discount,
      delivery: quote?.delivery,
      delivery_status: quote?.delivery_status,
      delivery_date: quote?.delivery_date,
      payment_status: quote?.payment_status,
      payment_types: JSON?.stringify(quote?.payment_types),
      payments: JSON?.stringify(quote?.payments),
      total: quote?.total,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/create-quote`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_quote = async (quote) => {
  try {
    const data = {
      id: quote?.id,
      customer: quote?.customer,
      project: quote?.project,
      number: quote?.number,
      date: quote?.date,
      due_date: quote?.due_date,
      status: quote?.status,
      details: quote?.details,
      subtotal: quote?.subtotal,
      taxamount: quote?.taxamount,
      discount: quote?.discount,
      delivery: quote?.delivery,
      delivery_status: quote?.delivery_status,
      delivery_date: quote?.delivery_date,
      payment_status: quote?.payment_status,
      payment_types: JSON?.stringify(quote?.payment_types),
      payments: JSON?.stringify(quote?.payments),
      total: quote?.total,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/update-quote`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const delete_quote = async (quote) => {
  try {
    const data = {
      id: quote?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/delete-quote`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_quote = async (quote) => {
  try {
    const data = {
      id: quote?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-quote`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_quotes = async (quote) => {
  try {
    const data = {
      search: quote?.search,
      status: quote?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-all-quotes`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_quote_log = async (quote) => {
  try {
    const data = {
      search: quote?.search,
      sort: quote?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-quote-log`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_quote_logs = async (quote) => {
  try {
    const data = {
      search: quote?.search,
      sort: quote?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-quote-logs`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export {
  create_quote,
  update_quote,
  delete_quote,
  get_quote,
  get_all_quotes,
  get_quote_log,
  get_all_quote_logs,
};
