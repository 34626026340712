import countries from "../../../countries.json";

const forms = [
  {
    name: "Employee",
    options: [
      {
        name: "Name",
        type: "input",
        form: {
          type: "text",
          name: "name",
          placeholder: "Employee name",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Role",
        type: "selectandadd",
        path: "/app/employees/roles/add",
        form: {
          name: "role",
          allowClear: true,
          showSearch: true,
          placeholder: "Employee role",
          api: "get-all-employee-roles",
          options: null,
          value: null,
          required: true,
        },
      },
    ],
  },
  {
    name: "Contacts",
    options: [
      {
        name: "Email",
        type: "input",
        form: {
          type: "text",
          name: "email",
          placeholder: "Employee email",
          class: "",
          value: "",
          required: false,
        },
      },
      {
        name: "Phone",
        type: "input",
        form: {
          type: "text",
          name: "phone",
          placeholder: "Employee phone",
          class: "",
          value: "",
          required: false,
        },
      },
    ],
  },
  {
    name: "Working Hours",
    options: [
      {
        name: "Hours",
        type: "input",
        form: {
          type: "number",
          name: "working_hours",
          placeholder: "Employee minimum working hours per day",
          value: "",
          required: false,
        },
      },
      {
        name: "Cost",
        type: "input",
        form: {
          type: "number",
          name: "working_cost",
          placeholder: "Employee working hours cost incurred",
          value: "",
          required: false,
        },
      },
      {
        name: "Charges",
        type: "input",
        form: {
          type: "number",
          name: "charges",
          placeholder: "Employee working hours charge applicable",
          value: "",
          required: false,
        },
      },
    ],
  },
  {
    name: "Address",
    options: [
      {
        name: "Area",
        type: "input",
        form: {
          type: "text",
          name: "area",
          placeholder: "Employee area address",
          class: "",
          value: "",
        },
      },
      {
        name: "City",
        type: "input",
        form: {
          type: "text",
          name: "city",
          placeholder: "Employee city address",
          class: "",
          value: "",
        },
      },
      {
        name: "State",
        type: "input",
        form: {
          type: "text",
          name: "state",
          placeholder: "Employee state address",
          class: "",
          value: "",
        },
      },
      {
        name: "Country",
        type: "select",
        form: {
          value: "Oman",
          name: "country",
          allowClear: false,
          showSearch: true,
          placeholder: "Oman",
          options: countries?.map((value, index) => ({
            value: value?.country,
            label: value?.country,
          })),
          required: false,
        },
      },
    ],
  },
  {
    name: "Status",
    options: [
      {
        name: "Status",
        type: "select",
        form: {
          name: "status",
          allowClear: false,
          placeholder: "Status",
          value: 1,
          options: [
            {
              value: 1,
              label: "Active",
            },
            {
              value: 0,
              label: "In-active",
            },
          ],
          required: false,
        },
      },
    ],
  },
];

export default forms;
