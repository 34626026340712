import React from "react";

// const Environment = "PRODUCTION";
const Environment = "DEVELOPMENT";

const URL =
  Environment === "PRODUCTION"
    ? "https://manageconstructs.com"
    : Environment === "DEVELOPMENT"
    ? "https://development.manageconstructs.com"
    : // "http://localhost:4001"
      "";

export default URL;
