import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import toast from "react-hot-toast";
import Header from "../../../../Componets/Header";
import {
  create_project_issue,
  get_project_issue,
  update_project_issue,
} from "../../../../Controllers/Projects/Issues";
import forms from "../../../../Data/Forms/Projects/Issues/forms";
import headers from "../../../../Data/Forms/Projects/Issues/headers";
import Loadercomponent from "../../../../Componets/Loader";
import { convert_to_file } from "../../../../Controllers/Global";

const ProjectsIssuesForm = () => {
  const { project, id } = useParams();
  let navigate = useNavigate();

  headers.navigate.path[0].path = `/app/projects/issues/${project}`;

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
      setButtonLoader(true);

      const issue = id
        ? await update_project_issue({
            ...data?.data,
            id: id,
            project: project,
          })
        : await create_project_issue({ ...data?.data, project: project });

      if (issue?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(issue?.message);
        setButtonLoader(false);
      } else {
        toast?.error(issue?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = async (issue) => {
    let image = issue?.image ? issue?.image : "";
    let file = await convert_to_file(image);
    let date = issue?.date ? issue?.date : "";
    let project_task = issue?.project_task?._id
      ? issue?.project_task?._id
      : null;
    let name = issue?.name ? issue?.name : "";
    let description = issue?.description ? issue?.description : "";
    let number = issue?.number ? issue?.number : "";
    let issue_status = issue?.issue_status ? issue?.issue_status : null;
    let status = issue?.status ? issue?.status : 0;

    let project_task_option = issue?.project_task?._id
      ? [
          {
            value: issue?.project_task?._id,
            label: issue?.project_task?.name,
          },
        ]
      : null;

    values[0].options[0].form.value = file;
    values[0].options[1].form.value = date;
    values[0].options[2].form.value = project_task;
    values[0].options[2].form.options = project_task_option;
    values[0].options[3].form.value = name;
    values[0].options[4].form.value = description;
    values[0].options[5].form.value = number;

    values[1].options[0].form.value = issue_status;
    values[1].options[1].form.value = status;

    values[0].options[2].form.params = {
      api: "get-project-tasks",
      status: 1,
      project: project,
    };
    values[0].options[2].path = `/app/projects/tasks/add/${project}`;

    setLoader(false);
    setValues([...values]);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      const data = {
        id: id,
      };

      const issue = await get_project_issue(data);
      if (issue?.status) {
        handleData(issue?.data);
      } else {
        toast?.error(issue?.message);
      }
    } else {
      values[0].options[2].form.params = {
        api: "get-project-tasks",
        status: 1,
        project: project,
      };
      values[0].options[2].path = `/app/projects/tasks/add/${project}`;

      setLoader(false);
      setValues([...values]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        />
      </Loadercomponent>
    </Header>
  );
};

export default ProjectsIssuesForm;
