import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import toast from "react-hot-toast";
import Header from "../../../../Componets/Header";
import {
  create_project_timesheet,
  get_project_timesheet,
  update_project_timesheet,
} from "../../../../Controllers/Projects/Timesheet";
import forms from "../../../../Data/Forms/Projects/Timesheet/forms";
import headers from "../../../../Data/Forms/Projects/Timesheet/headers";
import Loadercomponent from "../../../../Componets/Loader";
import { convert_to_file } from "../../../../Controllers/Global";
import Projecttimesheetitemstable from "../../../../Data/Forms/Projects/Timesheet/items";
import Projecttimesheetemployeestable from "../../../../Data/Forms/Projects/Timesheet/employees";
import Headercomponent from "../../../../Componets/Formcomponents/header";
import Bodycomponent from "../../../../Componets/Formcomponents/body";
import Titlecomponent from "../../../../Componets/Formcomponents/title";
import Formdata from "../../../../Componets/Formcomponents/formdata";
import { get_project_task } from "../../../../Controllers/Projects/Tasks";

const ProjectsTimesheetsForm = () => {
  const { project, id } = useParams();
  let navigate = useNavigate();

  headers.navigate.path[0].path = `/app/projects/timesheets/${project}`;

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const [itemDetails, setItemDetails] = useState([
    {
      id: "",
      description: null,
      unit: "",
      purchase_price: "",
      purchase_options: "",
      quantity: "",
      tax: 0,
      tax_amount: 0,
      total: 0,
    },
  ]);
  const [itemTotals, setItemTotals] = useState({
    subtotal: "0.000",
    taxamount: "0.000",
    total: "0.000",
  });

  const [employeeDetails, setEmployeeDetails] = useState([
    {
      id: "",
      description: null,
      role: "",
      working_cost: "",
      hours: "",
      total: 0,
    },
  ]);
  const [employeeTotals, setEmployeeTotals] = useState({
    total: "0.000",
  });

  const formdata = async (data) => {
    if (data?.status) {
      setButtonLoader(true);

      const timesheet = id
        ? await update_project_timesheet({
            ...data?.data,
            id: id,
            project: project,
            item_details: itemDetails,
            employee_details: employeeDetails,
            item_subtotal: itemTotals?.subtotal,
            item_taxamount: itemTotals?.taxamount,
            item_total: itemTotals?.total,
            employee_total: employeeTotals?.total,
          })
        : await create_project_timesheet({
            ...data?.data,
            project: project,
            item_details: itemDetails,
            employee_details: employeeDetails,
            item_subtotal: itemTotals?.subtotal,
            item_taxamount: itemTotals?.taxamount,
            item_total: itemTotals?.total,
            employee_total: employeeTotals?.total,
          });

      if (timesheet?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(timesheet?.message);
        setButtonLoader(false);
      } else {
        toast?.error(timesheet?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = async (timesheet) => {
    let date = timesheet?.date ? timesheet?.date : "";
    let project_task = timesheet?.project_task?._id
      ? timesheet?.project_task?._id
      : null;
    let project_task_option = timesheet?.project_task?._id
      ? [
          {
            value: timesheet?.project_task?._id,
            label: timesheet?.project_task?.name,
          },
        ]
      : null;

    values[0].options[0].form.value = date;
    values[0].options[1].form.value = project_task;
    values[0].options[1].form.options = project_task_option;

    let item_data = [];
    timesheet?.item_details?.map((value, index) => {
      item_data?.push({
        id: value?._id,
        task_item_detail: value?.task_item_detail,
        description: JSON?.stringify(value?.description),
        options: [
          {
            value: JSON?.stringify(value?.description),
            label: value?.description?.name,
          },
        ],
        unit: value?.unit,
        purchase_price: value?.purchase_price,
        quantity: value?.quantity,
        used: value?.used,
        remaining: value?.remaining,
        stock: value?.description?.stock,
        stock_type: value?.description?.type,
        tax: value?.tax,
        total: value?.total,
      });
    });
    itemTotals.subtotal = timesheet?.item_subtotal;
    itemTotals.taxamount = timesheet?.item_taxamount;
    itemTotals.total = timesheet?.item_total;

    let employee_data = [];
    timesheet?.employee_details?.map((value, index) => {
      employee_data?.push({
        id: value?._id,
        task_employee_detail: value?.task_employee_detail,
        description: JSON?.stringify(value?.description),
        options: [
          {
            value: JSON?.stringify(value?.description),
            label: value?.description?.name,
          },
        ],
        role: value?.role,
        working_cost: value?.working_cost,
        hours: value?.hours,
        worked: value?.worked,
        remaining: value?.remaining,
        tax: value?.tax,
        total: value?.total,
      });
    });
    employeeTotals.total = timesheet?.employee_total;

    setItemDetails([...item_data]);
    setItemTotals({ ...itemTotals });
    setEmployeeDetails([...employee_data]);
    setEmployeeTotals({ ...employeeTotals });
    setValues([...values]);
    setLoader(false);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      const data = {
        id: id,
      };

      const timesheet = await get_project_timesheet(data);
      if (timesheet?.status) {
        handleData(timesheet?.data);
      } else {
        toast?.error(timesheet?.message);
      }
    } else {
      values[0].options[0].form.value = new Date();
      values[0].options[1].form.params = {
        api: "get-project-tasks",
        status: 1,
        project: project,
      };
      values[0].options[1].path = `/app/projects/tasks/add/${project}`;

      setLoader(false);
      setValues([...values]);
    }
  };

  const getformdata = async (formdata) => {
    if (formdata[1]?.project_task && !id) {
      const data = {
        id: formdata[1]?.project_task,
      };

      const task = await get_project_task(data);
      if (task?.status) {
        let item_data = [];
        task?.data?.item_details?.map((value, index) => {
          item_data?.push({
            task_item_detail: value?._id,
            description: JSON?.stringify(value?.description),
            options: [
              {
                value: JSON?.stringify(value?.description),
                label: value?.description?.name,
              },
            ],
            unit: value?.unit,
            purchase_price: value?.purchase_price,
            quantity: value?.quantity,
            used: "",
            remaining: value?.remaining,
            stock: value?.description?.stock,
            stock_type: value?.description?.type,
            tax: value?.tax,
            total: "0.000",
          });
        });
        itemTotals.subtotal = "0.000";
        itemTotals.taxamount = "0.000";
        itemTotals.total = "0.000";

        let employee_data = [];
        task?.data?.employee_details?.map((value, index) => {
          employee_data?.push({
            task_employee_detail: value?._id,
            description: JSON?.stringify(value?.description),
            options: [
              {
                value: JSON?.stringify(value?.description),
                label: value?.description?.name,
              },
            ],
            role: value?.role,
            working_cost: value?.working_cost,
            hours: value?.hours,
            remaining: value?.remaining,
            worked: "",
            tax: value?.tax,
            total: "0.000",
          });
        });
        employeeTotals.total = "0.000";

        setItemDetails([...item_data]);
        setItemTotals({ ...itemTotals });
        setEmployeeDetails([...employee_data]);
        setEmployeeTotals({ ...employeeTotals });
      } else {
        toast?.error(task?.message);
      }
    } else if (formdata[1]?.project_task && id) {
      navigate(`/app/projects/timesheets/add/${project}`);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          getformdata={getformdata}
          buttonLoader={buttonLoader}
        >
          <Bodycomponent>
            <Titlecomponent index={2} title={"Items"} />
            <Formdata>
              <Headercomponent index={0} name={"Details"} required={true} />
              <Projecttimesheetitemstable
                itemDetails={itemDetails}
                setItemDetails={setItemDetails}
                itemTotals={itemTotals}
                setItemTotals={setItemTotals}
              />
            </Formdata>
          </Bodycomponent>
          <Bodycomponent>
            <Titlecomponent index={3} title={"Employees"} />
            <Formdata>
              <Headercomponent index={0} name={"Details"} required={true} />
              <Projecttimesheetemployeestable
                employeeDetails={employeeDetails}
                setEmployeeDetails={setEmployeeDetails}
                employeeTotals={employeeTotals}
                setEmployeeTotals={setEmployeeTotals}
              />
            </Formdata>
          </Bodycomponent>
        </Forms>
      </Loadercomponent>
    </Header>
  );
};

export default ProjectsTimesheetsForm;
