const forms = [
  {
    name: "Issue",
    options: [
      {
        name: "Image",
        type: "upload",
        form: {
          type: "text",
          name: "image",
          placeholder: "",
          class: "",
          value: "",
          required: false,
        },
      },
      {
        name: "Date",
        type: "date",
        form: {
          type: "text",
          name: "date",
          placeholder: "Issue date",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Task",
        type: "selectandadd",
        path: "/app/projects/tasks/add/",
        form: {
          name: "project_task",
          allowClear: true,
          showSearch: true,
          filterOption: false,
          placeholder: "Issue task",
          api: "",
          params: {
            api: "get-project-tasks",
            status: 1,
            project: "",
          },
          options: null,
          value: null,
          required: true,
        },
      },
      {
        name: "Name",
        type: "input",
        form: {
          type: "text",
          name: "name",
          placeholder: "Issue name",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Description",
        type: "input",
        form: {
          type: "text",
          name: "description",
          placeholder: "Issue description",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Number",
        type: "input",
        form: {
          type: "text",
          name: "number",
          placeholder: "Issue number",
          class: "",
          value: "",
          required: false,
        },
      },
    ],
  },
  {
    name: "Status",
    options: [
      {
        name: "Issue status",
        type: "select",
        form: {
          name: "issue_status",
          allowClear: false,
          placeholder: "Issue status",
          value: 0,
          options: [
            {
              value: 1,
              label: "Pending",
            },
            {
              value: 2,
              label: "Ongoing",
            },
            {
              value: 3,
              label: "Completed",
            },
            {
              value: 0,
              label: "Cancelled",
            },
          ],
          required: false,
        },
      },
      {
        name: "Status",
        type: "select",
        form: {
          name: "status",
          allowClear: false,
          placeholder: "Status",
          value: 1,
          options: [
            {
              value: 1,
              label: "Active",
            },
            {
              value: 0,
              label: "In-active",
            },
          ],
          required: false,
        },
      },
    ],
  },
];

export default forms;
