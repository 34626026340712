import { AutoComplete, DatePicker, Form, Input, Select } from "antd";
import React, { createRef, useEffect, useRef, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";
import AddButton from "../../../../Componets/Buttons/add";
import { select_id, select_search } from "../../../../Controllers/Global";
import dayjs from "dayjs";
import moment from "moment";
import taxes from "../../../taxes.json";

const Invoicetable = React.forwardRef((props, ref) => {
  const { details, setDetails, totals, setTotals } = props;

  const handleSearch = async (e, index) => {
    let data = [...details];

    const select = {
      api: "get-all-items",
      search: e,
      status: 1,
    };

    const search = await select_search(select);

    if (search?.status) {
      let options = [];
      search?.data?.map((value, index) => {
        options?.push({ value: JSON?.stringify(value), label: value?.name });
      });

      data[index].options = options;

      setDetails(data);
    } else {
      toast?.error(search?.message);
    }
  };

  const handlePurchasePriceSearch = async (e, index) => {
    let data = [...details];

    if (data[index].description) {
      const select = {
        api: "get-all-purchase-details",
        id: JSON?.parse(data[index].description)?._id,
      };

      const search = await select_id(select);
      if (search?.status) {
        let options = [];
        search?.data?.map((value, index) => {
          options?.push({
            value: JSON?.stringify(value),
            label:
              "" +
              value?.purchase_price?.toFixed(3) +
              " (" +
              value?.purchase?.supplierorcontractor?.name +
              ", " +
              moment?.(value?.purchase?.date)?.format?.("DD/MM/YYYY") +
              ", #PO" +
              value?.purchase?.number +
              ")",
          });
        });

        data[index].purchase_options = options;
        setDetails(data);
      } else {
        toast?.error(search?.message);
      }
    }
  };

  const handleDescription = (e, index) => {
    let data = [...details];

    if (e) {
      let data_description = e ? e : "";
      let data_unit = JSON?.parse(e)?.unit?.name
        ? JSON?.parse(e)?.unit?.name
        : "";
      let data_purchase_price = JSON?.parse(e)?.purchase_price
        ? parseFloat(JSON?.parse(e)?.purchase_price)
        : "";
      let data_tax = JSON?.parse(e)?.tax ? parseFloat(JSON?.parse(e)?.tax) : 0;

      if (data_description) {
        let ids = [];
        data?.map((v, i) => {
          if (v?.description) {
            ids?.push?.(JSON?.parse(v?.description)?._id);
          }
        });

        if (ids?.includes(JSON?.parse(e)?._id)) {
          let old_index = ids?.indexOf(JSON?.parse(e)?._id);
          let data_quantity = data[old_index]?.quantity
            ? data[old_index]?.quantity
            : 0;

          let event = {
            target: {
              name: "quantity",
              value: parseFloat(data_quantity) + 1,
            },
          };
          handleTotal(event, old_index);
        } else {
          let event = {
            target: {
              name: "purchase_price",
              value: data_purchase_price,
            },
          };
          data[index].description = data_description;
          data[index].unit = data_unit;
          setDetails(data);
          handleTotal(event, index, "");
          handleTotal(data_tax, index, "tax");
        }
      }
    } else {
      let event = {
        target: {
          name: "purchase_price",
          value: "",
        },
      };
      data[index].description = null;
      data[index].unit = "";
      setDetails(data);
      handleTotal(event, index, "");
      handleTotal(0, index, "tax");
    }
  };

  const handlePurchase = (e, index, key) => {
    if (e) {
      let event = {
        target: {
          name: "purchase_price",
          value: JSON?.parse(e)?.purchase_price,
        },
      };

      handleTotal(event, index, key);
      handleTotal(JSON?.parse(e)?.tax, index, "tax");
    }
  };

  const handleDelivered = (e, index) => {
    let data = [...details];

    let data_quantity = data[index]?.quantity
      ? parseFloat(data[index]?.quantity)
      : 0;
    let data_delivered = e?.target?.value ? parseFloat(e?.target?.value) : 0;

    if (data_delivered <= data_quantity) {
      data[index][e?.target?.name] = e?.target?.value;
      setDetails(data);
    } else {
      toast?.error(`Quantity only ${data_quantity}`);
    }
  };

  const handleTotal = (e, index, key) => {
    let name = key ? key : e?.target?.name;
    let value = key && e ? e : !key && e?.target?.value ? e?.target?.value : "";

    if (value >= 0) {
      let data = [...details];

      let data_purchase_price = data[index]?.purchase_price
        ? data[index]?.purchase_price
        : 0;
      let data_quantity = data[index]?.quantity ? data[index]?.quantity : 0;
      let data_tax = data[index]?.tax ? data[index]?.tax : 0;

      let purchase_price =
        name === "purchase_price" ? value : data_purchase_price;
      let quantity = name === "quantity" ? value : data_quantity;
      let tax = name === "tax" ? value : data_tax;

      let tax_amount = tax
        ? parseFloat(purchase_price) *
          parseFloat(quantity ? quantity : 1) *
          (parseFloat(tax) / 100)
        : 0;

      let total =
        parseFloat(purchase_price) * parseFloat(quantity ? quantity : 1) +
        parseFloat(tax_amount);

      data[index][name] = value;
      data[index].tax = tax ? tax : 0;
      data[index].tax_amount = tax_amount ? tax_amount?.toFixed(3) : 0;
      data[index].total = total ? total?.toFixed(3) : 0;
      data[index].tax_amount = tax_amount ? tax_amount?.toFixed(3) : 0;

      let subtotal =
        data?.reduce(
          (acc, currentValue) =>
            parseFloat(acc) + parseFloat(currentValue?.total),
          0
        ) -
        data?.reduce(
          (acc, currentValue) =>
            parseFloat(acc) + parseFloat(currentValue?.tax_amount),
          0
        );

      let taxamount = data?.reduce(
        (acc, currentValue) =>
          parseFloat(acc) + parseFloat(currentValue?.tax_amount),
        0
      );

      let grandtotal =
        data?.reduce(
          (acc, currentValue) =>
            parseFloat(acc) + parseFloat(currentValue?.total),
          0
        ) +
        parseFloat(totals?.delivery) -
        parseFloat(totals?.discount);

      totals.subtotal = subtotal;
      totals.taxamount = taxamount;
      totals.total = grandtotal;

      setDetails(data);
      setTotals({ ...totals });
    }
  };

  const handleRemove = (index) => {
    let data = [...details];

    data?.splice(index, 1);

    setDetails(data);
  };

  const handleAdd = () => {
    setDetails((prevDetails) => [
      ...prevDetails,
      {
        id: "",
        description: null,
        unit: "",
        purchase_price: "",
        quantity: "",
        delivered: "",
        free: "",
        tax: 0,
        total: 0,
      },
    ]);
  };

  const handleDelivery = (e) => {
    if (e?.target?.value >= 0) {
      let total_amount = parseFloat(
        details?.reduce(
          (acc, currentValue) =>
            parseFloat(acc) + parseFloat(currentValue?.total),
          0
        )
      );

      let total =
        parseFloat(total_amount ? total_amount : 0) +
        parseFloat(e?.target?.value ? e?.target?.value : 0) -
        parseFloat(totals?.discount ? totals?.discount : 0);

      totals.delivery = e?.target?.value;
      totals.total = total;

      setTotals({ ...totals });
    }
  };

  const handleDiscount = (e) => {
    if (e?.target?.value >= 0) {
      let total_amount = parseFloat(
        details?.reduce(
          (acc, currentValue) =>
            parseFloat(acc) + parseFloat(currentValue?.total),
          0
        )
      );

      let total =
        parseFloat(total_amount ? total_amount : 0) +
        parseFloat(totals?.delivery ? totals?.delivery : 0) -
        parseFloat(e?.target?.value ? e?.target?.value : 0);

      if (e?.target?.value <= total_amount) {
        totals.discount = e?.target?.value;
        totals.total = total;
      } else {
        toast?.error(`Discount exceeding`);
      }

      setTotals({ ...totals });
    }
  };

  const handleDeliveryStatus = (e) => {
    totals.delivery_status = e;

    if (!e) {
      totals.delivery_date = "";
    } else if (e == 1) {
      totals.delivery_date = "";
    }

    setTotals({ ...totals });
  };

  const handleDeliveryDate = (e) => {
    totals.delivery_date = e?.$d;
    setTotals({ ...totals });
  };

  const handlePaymentStatus = (e) => {
    totals.payment_status = e;

    if (!e) {
      totals.payment_types = null;
    }
    setTotals({ ...totals });
  };

  const handlePaymentTypes = (e) => {
    totals?.payment_types?.map((v, i) => {
      if (!e?.includes(v)) {
        totals.payments[v] = "";
      }
    });

    totals.payment_types = e;

    setTotals({ ...totals });
  };

  const handlePayments = (e) => {
    let name = e?.target?.name;
    let value = e?.target?.value ? e?.target?.value : "";

    if (e?.target?.value >= 0) {
      let data_Cash = totals?.payments?.Cash ? totals?.payments?.Cash : 0;
      let data_Cheque = totals?.payments?.Cheque ? totals?.payments?.Cheque : 0;
      let data_Debitcard = totals?.payments?.["Debit card"]
        ? totals?.payments?.["Debit card"]
        : 0;
      let data_Creditcard = totals?.payments?.["Credit card"]
        ? totals?.payments?.["Credit card"]
        : 0;
      let data_Banktransfer = totals?.payments?.["Bank transfer"]
        ? totals?.payments?.["Bank transfer"]
        : 0;
      let data_Onlinepayment = totals?.payments?.["Online payment"]
        ? totals?.payments?.["Online payment"]
        : 0;

      let Cash = name === "Cash" ? value : data_Cash;
      let Cheque = name === "Cheque" ? value : data_Cheque;
      let Debitcard = name === "Debit card" ? value : data_Debitcard;
      let Creditcard = name === "Credit card" ? value : data_Creditcard;
      let Banktransfer = name === "Bank transfer" ? value : data_Banktransfer;
      let Onlinepayment =
        name === "Online payment" ? value : data_Onlinepayment;

      let total =
        parseFloat(Cash) +
        parseFloat(Cheque) +
        parseFloat(Debitcard) +
        parseFloat(Creditcard) +
        parseFloat(Banktransfer) +
        parseFloat(Onlinepayment);

      let payment_total = total ? parseFloat(total) : 0;
      let grand_total = totals?.total ? parseFloat(totals?.total) : 0;

      if (payment_total <= grand_total) {
        totals.payments[name] = value;
        setTotals({ ...totals });
      } else {
        let previous_total =
          parseFloat(data_Cash) +
          parseFloat(data_Cheque) +
          parseFloat(data_Debitcard) +
          parseFloat(data_Creditcard) +
          parseFloat(data_Banktransfer) +
          parseFloat(data_Onlinepayment);

        let previous_remaining_amount =
          parseFloat(grand_total) - parseFloat(previous_total);

        toast?.error(`${previous_remaining_amount?.toFixed(3)}  remaining`);
      }
    }
  };

  return (
    <div>
      <table className="w-full">
        <thead>
          <tr>
            <td className="border p-4 text-black">S.No</td>
            <td className="border p-4 text-black">Description</td>
            <td className="border p-4 text-black">Unit</td>
            <td className="border p-4 text-black">Purchase Price</td>
            <td className="border p-4 text-black">Quantity</td>
            <td className="border p-4 text-black">Delivered</td>
            <td className="border p-4 text-black">Tax</td>
            <td className="border p-4 text-black">Total</td>
            <td className="border p-4 text-black">Delete</td>
          </tr>
        </thead>
        <tbody>
          {details?.map((value, index) => {
            return (
              <React.Fragment>
                <tr>
                  <td className="border p-4 text-center">{index + 1}</td>
                  <td className="border p-4">
                    <Select
                      className="w-[200px] text-regular"
                      name="description"
                      placeholder="Description"
                      allowClear={true}
                      showSearch={true}
                      filterOption={false}
                      options={value?.options}
                      value={value?.description}
                      onSearch={(e) => handleSearch(e, index)}
                      onClick={(e) => handleSearch("", index)}
                      onChange={(e) => handleDescription(e, index)}
                      autoComplete="off"
                    />
                  </td>
                  <td className="border p-4 text-center">{value?.unit}</td>
                  <td className="border p-4 text-center">
                    <AutoComplete
                      className="w-[350px] text-regular"
                      name="purchase_price"
                      placeholder="Purchase price"
                      allowClear={false}
                      showSearch={true}
                      filterOption={false}
                      options={value?.purchase_options}
                      value={value?.purchase_price}
                      onClick={(e) => handlePurchasePriceSearch("", index)}
                      onSelect={(e) => handlePurchase(e, index)}
                      autoComplete="off"
                    />
                  </td>
                  <td className="border p-4 text-center">
                    <Input
                      type="number"
                      className="w-full"
                      name="quantity"
                      placeholder="Quantity"
                      value={value?.quantity}
                      onChange={(e) => handleTotal(e, index)}
                      autoComplete="off"
                      required="required"
                    />
                  </td>
                  <td className="border p-4 text-center">
                    <Input
                      type="number"
                      className="w-full"
                      name="delivered"
                      placeholder="Delivered"
                      value={value?.delivered}
                      onChange={(e) => handleDelivered(e, index)}
                      autoComplete="off"
                    />
                  </td>
                  <td className="border p-4 text-center">
                    <Select
                      className="w-full"
                      name="tax"
                      placeholder="Tax"
                      options={taxes}
                      value={value?.tax}
                      onChange={(e) => handleTotal(e, index, "tax")}
                    />
                  </td>
                  <td className="border p-4 text-center">
                    {parseFloat?.(value?.total)?.toFixed(3)}
                  </td>
                  <td className="border p-4 text-center">
                    <button
                      type="button"
                      className={
                        index == 0
                          ? "text-lg text-red-900 cursor-not-allowed"
                          : "text-lg text-red-500 cursor-pointer hover:text-red-800"
                      }
                      disabled={index == 0}
                      onClick={() => handleRemove(index)}
                    >
                      <DeleteOutlined />
                    </button>
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td className="border p-4" colSpan={6}></td>
            <td className="border p-4">Subtotal</td>
            <td className="border p-4" colSpan={2}>
              {totals?.subtotal > 0 ? totals?.subtotal?.toFixed(3) : "0.000"}
            </td>
          </tr>
          <tr>
            <td className="border p-4" colSpan={6}></td>
            <td className="border p-4">Tax&nbsp;amount</td>
            <td className="border p-4" colSpan={2}>
              {totals?.taxamount > 0 ? totals?.taxamount?.toFixed(3) : "0.000"}
            </td>
          </tr>
          <tr>
            <td className="border p-4" colSpan={6}></td>
            <td className="border p-4">Discount&nbsp;</td>
            <td className="border p-4" colSpan={2}>
              <Input
                type="number"
                className="w-full"
                placeholder="Discount amount"
                name="discount"
                value={totals?.discount}
                autoComplete="off"
                onChange={handleDiscount}
              />
            </td>
          </tr>
          <tr>
            <td className="border p-4" colSpan={6}></td>
            <td className="border p-4">Delivery&nbsp;</td>
            <td className="border p-4" colSpan={2}>
              <Input
                type="number"
                className="w-full"
                placeholder="Delivery charges"
                name="delivery"
                value={totals?.delivery}
                autoComplete="off"
                onChange={handleDelivery}
              />
            </td>
          </tr>
          <tr>
            <td className="border p-4" colSpan={6}></td>
            <td className="border p-4">Delivery status&nbsp;</td>
            <td className="border p-4" colSpan={2}>
              <div>
                <div>
                  <Select
                    className="w-full"
                    name="tax"
                    placeholder="Delivery status"
                    value={totals?.delivery_status}
                    options={[
                      {
                        value: 0,
                        label: "Pending",
                      },
                      {
                        value: 1,
                        label: "Partial",
                      },
                      {
                        value: 2,
                        label: "Delivered",
                      },
                    ]}
                    onChange={handleDeliveryStatus}
                  />
                </div>
                {totals?.delivery_status == 2 && (
                  <div className="pt-4">
                    <DatePicker
                      type="number"
                      className="w-full"
                      placeholder="Delivered date"
                      name="delivery"
                      value={
                        totals?.delivery_date && dayjs(totals?.delivery_date)
                      }
                      onChange={handleDeliveryDate}
                      autoComplete="off"
                    />
                  </div>
                )}
              </div>
            </td>
          </tr>
          <tr>
            <td className="border p-4" colSpan={6}></td>
            <td className="border p-4">Payment status&nbsp;</td>
            <td className="border p-4" colSpan={2}>
              <div>
                <Select
                  className="w-full"
                  name="tax"
                  placeholder="Payment status"
                  value={totals?.payment_status}
                  options={[
                    {
                      value: 0,
                      label: "Unpaid",
                    },
                    {
                      value: 1,
                      label: "Partial",
                    },
                    {
                      value: 2,
                      label: "Paid",
                    },
                  ]}
                  onChange={handlePaymentStatus}
                />
              </div>
              {totals?.payment_status == 1 || totals?.payment_status == 2 ? (
                <div className="pt-4">
                  <Select
                    mode="multiple"
                    className="w-full"
                    name="tax"
                    placeholder="Payment status"
                    value={totals?.payment_types}
                    options={[
                      {
                        value: "Cash",
                        label: "Cash",
                      },
                      {
                        value: "Cheque",
                        label: "Cheque",
                      },
                      {
                        value: "Debit card",
                        label: "Debit card",
                      },
                      {
                        value: "Credit card",
                        label: "Credit card",
                      },
                      {
                        value: "Bank transfer",
                        label: "Bank transfer",
                      },
                      {
                        value: "Online payment",
                        label: "Online payment",
                      },
                    ]}
                    onChange={handlePaymentTypes}
                  />
                </div>
              ) : (
                ""
              )}
              {totals?.payment_types?.map((value, index) => {
                return (
                  <div className="pt-4">
                    <Input
                      type="number"
                      className="w-full"
                      placeholder={value}
                      name={value}
                      value={totals?.payments?.[value]}
                      onChange={handlePayments}
                      autoComplete="off"
                      required="required"
                    />
                  </div>
                );
              })}
            </td>
          </tr>
          <tr>
            <td className="border p-4" colSpan={6}></td>
            <td className="border p-4 text-black text-medium">Total</td>
            <td className="border p-4 text-black text-medium" colSpan={2}>
              {totals?.total > 0 ? totals?.total?.toFixed(3) : "0.000"}
            </td>
          </tr>
        </tfoot>
      </table>
      <div className="flex justify-end p-3">
        <AddButton onClick={handleAdd} />
      </div>
    </div>
  );
});

export default Invoicetable;
