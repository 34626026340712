const headers = {
  name: "Sales Quote",
  navigate: {
    path: [
      {
        name: "Quotes",
        path: "/app/sales/Quotes",
      },
      {
        name: "Add",
        path: "",
      },
    ],
  },
};

export default headers;
