import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_project_task = async (project_task) => {
  try {
    let data = new FormData();
    data.append("project", project_task?.project);
    data.append("image", project_task?.image[0]);
    data.append("project_job", project_task?.project_job);
    data.append("name", project_task?.name);
    data.append("description", project_task?.description);
    data.append("number", project_task?.number);
    data.append("task_status", project_task?.task_status);
    data.append("status", project_task?.status);
    data.append("item_details", JSON?.stringify(project_task?.item_details));
    data.append(
      "employee_details",
      JSON?.stringify(project_task?.employee_details)
    );
    data.append("item_subtotal", project_task?.item_subtotal);
    data.append("item_taxamount", project_task?.item_taxamount);
    data.append("item_total", project_task?.item_total);
    data.append("employee_total", project_task?.employee_total);

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/create-project-task`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_project_task = async (project_task) => {
  try {
    let data = new FormData();
    data.append("id", project_task?.id);
    data.append("project", project_task?.project);
    data.append("image", project_task?.image[0]);
    data.append("project_job", project_task?.project_job);
    data.append("name", project_task?.name);
    data.append("description", project_task?.description);
    data.append("number", project_task?.number);
    data.append("task_status", project_task?.task_status);
    data.append("status", project_task?.status);
    data.append("item_details", JSON?.stringify(project_task?.item_details));
    data.append(
      "employee_details",
      JSON?.stringify(project_task?.employee_details)
    );
    data.append("item_subtotal", project_task?.item_subtotal);
    data.append("item_taxamount", project_task?.item_taxamount);
    data.append("item_total", project_task?.item_total);
    data.append("employee_total", project_task?.employee_total);

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/update-project-task`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_project_task = async (project_task) => {
  try {
    const data = {
      id: project_task?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-project-task`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_project_tasks = async (project_task) => {
  try {
    const data = {
      project: project_task?.project,
      search: project_task?.search,
      item: project_task?.item,
      employee: project_task?.employee,
      project_task_status: project_task?.project_task_status,
      status: project_task?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-project-tasks`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export {
  create_project_task,
  update_project_task,
  get_project_task,
  get_all_project_tasks,
};
