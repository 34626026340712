import React from "react";
import LogoutIcon from "../../Assets/Svg/logout";
import { Signout } from "../../Controllers/Auth";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const LogoutButton = (props) => {
  let navigate = useNavigate();

  const handleLogout = async () => {
    const logout = await Signout();

    if (logout?.status) {
      localStorage.clear();
      navigate("/signin");
      toast?.success(logout?.message);
    } else {
      localStorage.clear();
      navigate("/signin");
      toast?.error(logout?.message);
    }
  };

  return (
    <div className="border-t bg-white hover:bg-[#2D53DA] text-[#353F4F] hover:text-white text-medium w-full cursor-pointer">
      <button
        className="w-full  focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-500  py-2 px-4"
        onClick={handleLogout}
      >
        <div className="flex justify-center">
          <div>Logout</div>
          <div className="px-2 pt-0.5">
            <LogoutIcon />
          </div>
        </div>
      </button>
    </div>
  );
};

export default LogoutButton;
