import React, { useEffect, useState } from "react";
import Header from "../../../../Componets/Header";
import TableImage from "../../../../Componets/Image";
import Status from "../../../../Componets/Status";
import ActionButton from "../../../../Componets/Buttons/action";
import { get_all_projects } from "../../../../Controllers/Projects/Projects";
import toast from "react-hot-toast";
import Projecttable from "../../../../Data/Tables/Projects/Projects/tables";
import headers from "../../../../Data/Tables/Projects/Projects/headers";
import ValueTag from "../../../../Componets/ValueTag";
import Loadercomponent from "../../../../Componets/Loader";
import { useParams } from "react-router-dom";

const ProjectsTable = () => {
  let { id } = useParams();

  const [loader, setLoader] = useState(false);

  const [body, setBody] = useState();

  let tag = (stock, notify) => {
    let data = "Stocked";

    if (stock == 0) {
      data = "Out of Stock";
    } else if (stock <= notify) {
      data = "Low";
    }

    return data;
  };

  let color = (stock, notify) => {
    let data = "green";

    if (stock == 0) {
      data = "red";
    } else if (stock <= notify) {
      data = "orange";
    }

    return data;
  };

  const formdata = (data) => {
    fetchData(data);
  };

  const actiondata = (data) => {
    fetchData(data);
  };

  const fetchData = async (tabledata) => {
    try {
      setLoader(true);

      const data = {
        search: tabledata?.search,
        sort: tabledata?.sort,
        customer: tabledata?.customer,
        project_status: tabledata?.project_status,
        start_date: tabledata?.start_date,
        end_date: tabledata?.end_date,
        status: tabledata?.status,
      };

      const projects = await get_all_projects(data);
      if (projects?.status) {
        let data = [];

        projects?.data?.map((value, index) => {
          data?.push({
            Name: <TableImage image={value?.image} name={value?.name} />,
            Unit: value?.unit?.name,
            Category: value?.category?.name,
            Brand: value?.brand?.name,
            Price: value?.sale_price,
            Stock: (
              <ValueTag
                value={value?.stock}
                tag={tag(value?.stock, value?.notify)}
                color={color(value?.stock, value?.notify)}
              />
            ),
            Status: <Status status={value?.status} />,
            Action: (
              <ActionButton
                edit={`/app/projects/edit/${value?._id}`}
                id={value?._id}
                api="delete-project"
                actiondata={actiondata}
              />
            ),
          });
        });

        setBody(projects?.data);
        setLoader(false);
      } else {
        toast?.error(projects?.message);
        setLoader(false);
      }
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers} formdata={formdata}>
      <Loadercomponent loader={loader}>
        <Projecttable body={body} actiondata={actiondata} />
      </Loadercomponent>
    </Header>
  );
};

export default ProjectsTable;
