const forms = [
  {
    name: "Project",
    options: [
      {
        name: "Image",
        type: "upload",
        form: {
          type: "text",
          name: "image",
          placeholder: "",
          class: "",
          value: "",
          required: false,
        },
      },
      {
        name: "Name",
        type: "input",
        form: {
          type: "text",
          name: "name",
          placeholder: "Project name",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Number",
        type: "input",
        form: {
          type: "text",
          name: "number",
          placeholder: "Project number",
          class: "",
          value: "",
          required: false,
        },
      },
    ],
  },
  {
    name: "Details",
    options: [
      {
        name: "Customer",
        type: "selectandadd",
        path: "/app/customers/add",
        form: {
          name: "customer",
          allowClear: true,
          showSearch: true,
          filterOption: false,
          placeholder: "Cutomer",
          api: "get-all-customers",
          options: null,
          value: null,
          required: true,
        },
      },
      {
        name: "Start date",
        type: "date",
        form: {
          type: "text",
          name: "start_date",
          placeholder: "Project start date",
          class: "",
          value: "",
          required: false,
        },
      },
      {
        name: "End date",
        type: "date",
        form: {
          type: "text",
          name: "end_date",
          placeholder: "Project end date",
          class: "",
          value: "",
          required: false,
        },
      },
      {
        name: "Estimated",
        type: "input",
        form: {
          type: "number",
          name: "estimated",
          placeholder: "Project estimated cost",
          value: "",
          required: false,
        },
      },
    ],
  },
  {
    name: "Address",
    options: [
      {
        name: "Area",
        type: "input",
        form: {
          type: "text",
          name: "area",
          placeholder: "Project area",
          class: "",
          value: "",
          required: false,
        },
      },
      {
        name: "City",
        type: "input",
        form: {
          type: "text",
          name: "city",
          placeholder: "Project city",
          class: "",
          value: "",
          required: false,
        },
      },
      {
        name: "State",
        type: "input",
        form: {
          type: "text",
          name: "state",
          placeholder: "Project state",
          class: "",
          value: "",
          required: false,
        },
      },
      {
        name: "Country",
        type: "select",
        form: {
          value: "Oman",
          name: "country",
          allowClear: false,
          showSearch: true,
          placeholder: "Oman",
          options: [
            { value: "Afghanistan", label: "Afghanistan" },
            { value: "Albania", label: "Albania" },
            { value: "Algeria", label: "Algeria" },
            { value: "Andorra", label: "Andorra" },
            { value: "Angola", label: "Angola" },
            { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
            { value: "Argentina", label: "Argentina" },
            { value: "Armenia", label: "Armenia" },
            { value: "Australia", label: "Australia" },
            { value: "Austria", label: "Austria" },
            { value: "Azerbaijan", label: "Azerbaijan" },
            { value: "Bahamas", label: "Bahamas" },
            { value: "Bahrain", label: "Bahrain" },
            { value: "Bangladesh", label: "Bangladesh" },
            { value: "Barbados", label: "Barbados" },
            { value: "Belarus", label: "Belarus" },
            { value: "Belgium", label: "Belgium" },
            { value: "Belize", label: "Belize" },
            { value: "Benin", label: "Benin" },
            { value: "Bhutan", label: "Bhutan" },
            { value: "Bolivia", label: "Bolivia" },
            {
              value: "Bosnia and Herzegovina",
              label: "Bosnia and Herzegovina",
            },
            { value: "Botswana", label: "Botswana" },
            { value: "Brazil", label: "Brazil" },
            { value: "Brunei", label: "Brunei" },
            { value: "Bulgaria", label: "Bulgaria" },
            { value: "Burkina Faso", label: "Burkina Faso" },
            { value: "Burundi", label: "Burundi" },
            { value: "Cabo Verde", label: "Cabo Verde" },
            { value: "Cambodia", label: "Cambodia" },
            { value: "Cameroon", label: "Cameroon" },
            { value: "Canada", label: "Canada" },
            {
              value: "Central African Republic",
              label: "Central African Republic",
            },
            { value: "Chad", label: "Chad" },
            { value: "Chile", label: "Chile" },
            { value: "China", label: "China" },
            { value: "Colombia", label: "Colombia" },
            { value: "Comoros", label: "Comoros" },
            {
              value: "Congo (Congo-Brazzaville)",
              label: "Congo (Congo-Brazzaville)",
            },
            { value: "Congo (DRC)", label: "Congo (DRC)" },
            { value: "Costa Rica", label: "Costa Rica" },
            { value: "Croatia", label: "Croatia" },
            { value: "Cuba", label: "Cuba" },
            { value: "Cyprus", label: "Cyprus" },
            { value: "Czechia", label: "Czechia" },
            { value: "Denmark", label: "Denmark" },
            { value: "Djibouti", label: "Djibouti" },
            { value: "Dominica", label: "Dominica" },
            { value: "Dominican Republic", label: "Dominican Republic" },
            { value: "Ecuador", label: "Ecuador" },
            { value: "Egypt", label: "Egypt" },
            { value: "El Salvador", label: "El Salvador" },
            { value: "Equatorial Guinea", label: "Equatorial Guinea" },
            { value: "Eritrea", label: "Eritrea" },
            { value: "Estonia", label: "Estonia" },
            { value: "Eswatini", label: "Eswatini" },
            { value: "Ethiopia", label: "Ethiopia" },
            { value: "Fiji", label: "Fiji" },
            { value: "Finland", label: "Finland" },
            { value: "France", label: "France" },
            { value: "Gabon", label: "Gabon" },
            { value: "Gambia", label: "Gambia" },
            { value: "Georgia", label: "Georgia" },
            { value: "Germany", label: "Germany" },
            { value: "Ghana", label: "Ghana" },
            { value: "Greece", label: "Greece" },
            { value: "Grenada", label: "Grenada" },
            { value: "Guatemala", label: "Guatemala" },
            { value: "Guinea", label: "Guinea" },
            { value: "Guinea-Bissau", label: "Guinea-Bissau" },
            { value: "Guyana", label: "Guyana" },
            { value: "Haiti", label: "Haiti" },
            { value: "Honduras", label: "Honduras" },
            { value: "Hungary", label: "Hungary" },
            { value: "Iceland", label: "Iceland" },
            { value: "India", label: "India" },
            { value: "Indonesia", label: "Indonesia" },
            { value: "Iran", label: "Iran" },
            { value: "Iraq", label: "Iraq" },
            { value: "Ireland", label: "Ireland" },
            { value: "Israel", label: "Israel" },
            { value: "Italy", label: "Italy" },
            { value: "Jamaica", label: "Jamaica" },
            { value: "Japan", label: "Japan" },
            { value: "Jordan", label: "Jordan" },
            { value: "Kazakhstan", label: "Kazakhstan" },
            { value: "Kenya", label: "Kenya" },
            { value: "Kiribati", label: "Kiribati" },
            { value: "Kuwait", label: "Kuwait" },
            { value: "Kyrgyzstan", label: "Kyrgyzstan" },
            { value: "Laos", label: "Laos" },
            { value: "Latvia", label: "Latvia" },
            { value: "Lebanon", label: "Lebanon" },
            { value: "Lesotho", label: "Lesotho" },
            { value: "Liberia", label: "Liberia" },
            { value: "Libya", label: "Libya" },
            { value: "Liechtenstein", label: "Liechtenstein" },
            { value: "Lithuania", label: "Lithuania" },
            { value: "Luxembourg", label: "Luxembourg" },
            { value: "Madagascar", label: "Madagascar" },
            { value: "Malawi", label: "Malawi" },
            { value: "Malaysia", label: "Malaysia" },
            { value: "Maldives", label: "Maldives" },
            { value: "Mali", label: "Mali" },
            { value: "Malta", label: "Malta" },
            { value: "Marshall Islands", label: "Marshall Islands" },
            { value: "Mauritania", label: "Mauritania" },
            { value: "Mauritius", label: "Mauritius" },
            { value: "Mexico", label: "Mexico" },
            { value: "Micronesia", label: "Micronesia" },
            { value: "Moldova", label: "Moldova" },
            { value: "Monaco", label: "Monaco" },
            { value: "Mongolia", label: "Mongolia" },
            { value: "Montenegro", label: "Montenegro" },
            { value: "Morocco", label: "Morocco" },
            { value: "Mozambique", label: "Mozambique" },
            { value: "Myanmar (Burma)", label: "Myanmar (Burma)" },
            { value: "Namibia", label: "Namibia" },
            { value: "Nauru", label: "Nauru" },
            { value: "Nepal", label: "Nepal" },
            { value: "Netherlands", label: "Netherlands" },
            { value: "New Zealand", label: "New Zealand" },
            { value: "Nicaragua", label: "Nicaragua" },
            { value: "Niger", label: "Niger" },
            { value: "Nigeria", label: "Nigeria" },
            { value: "North Korea", label: "North Korea" },
            { value: "North Macedonia", label: "North Macedonia" },
            { value: "Norway", label: "Norway" },
            { value: "Oman", label: "Oman" },
            { value: "Pakistan", label: "Pakistan" },
            { value: "Palau", label: "Palau" },
            { value: "Panama", label: "Panama" },
            { value: "Papua New Guinea", label: "Papua New Guinea" },
            { value: "Paraguay", label: "Paraguay" },
            { value: "Peru", label: "Peru" },
            { value: "Philippines", label: "Philippines" },
            { value: "Poland", label: "Poland" },
            { value: "Portugal", label: "Portugal" },
            { value: "Qatar", label: "Qatar" },
            { value: "Romania", label: "Romania" },
            { value: "Russia", label: "Russia" },
            { value: "Rwanda", label: "Rwanda" },
            { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
            { value: "Saint Lucia", label: "Saint Lucia" },
            {
              value: "Saint Vincent and the Grenadines",
              label: "Saint Vincent and the Grenadines",
            },
            { value: "Samoa", label: "Samoa" },
            { value: "San Marino", label: "San Marino" },
            { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
            { value: "Saudi Arabia", label: "Saudi Arabia" },
            { value: "Senegal", label: "Senegal" },
            { value: "Serbia", label: "Serbia" },
            { value: "Seychelles", label: "Seychelles" },
            { value: "Sierra Leone", label: "Sierra Leone" },
            { value: "Singapore", label: "Singapore" },
            { value: "Slovakia", label: "Slovakia" },
            { value: "Slovenia", label: "Slovenia" },
            { value: "Solomon Islands", label: "Solomon Islands" },
            { value: "Somalia", label: "Somalia" },
            { value: "South Africa", label: "South Africa" },
            { value: "South Korea", label: "South Korea" },
            { value: "South Sudan", label: "South Sudan" },
            { value: "Spain", label: "Spain" },
            { value: "Sri Lanka", label: "Sri Lanka" },
            { value: "Sudan", label: "Sudan" },
            { value: "Suriname", label: "Suriname" },
            { value: "Sweden", label: "Sweden" },
            { value: "Switzerland", label: "Switzerland" },
            { value: "Syria", label: "Syria" },
            { value: "Taiwan", label: "Taiwan" },
            { value: "Tajikistan", label: "Tajikistan" },
            { value: "Tanzania", label: "Tanzania" },
            { value: "Thailand", label: "Thailand" },
            { value: "Timor-Leste", label: "Timor-Leste" },
            { value: "Togo", label: "Togo" },
            { value: "Tonga", label: "Tonga" },
            { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
            { value: "Tunisia", label: "Tunisia" },
            { value: "Turkey", label: "Turkey" },
            { value: "Turkmenistan", label: "Turkmenistan" },
            { value: "Tuvalu", label: "Tuvalu" },
            { value: "Uganda", label: "Uganda" },
            { value: "Ukraine", label: "Ukraine" },
            { value: "United Arab Emirates", label: "United Arab Emirates" },
            { value: "United Kingdom", label: "United Kingdom" },
            { value: "United States", label: "United States" },
            { value: "Uruguay", label: "Uruguay" },
            { value: "Uzbekistan", label: "Uzbekistan" },
            { value: "Vanuatu", label: "Vanuatu" },
            { value: "Vatican City", label: "Vatican City" },
            { value: "Venezuela", label: "Venezuela" },
            { value: "Vietnam", label: "Vietnam" },
            { value: "Yemen", label: "Yemen" },
            { value: "Zambia", label: "Zambia" },
            { value: "Zimbabwe", label: "Zimbabwe" },
          ],
          required: false,
        },
      },
    ],
  },
  {
    name: "Status",
    options: [
      {
        name: "Project status",
        type: "select",
        form: {
          name: "project_status",
          allowClear: false,
          placeholder: "Project status",
          value: 0,
          options: [
            {
              value: 1,
              label: "Pending",
            },
            {
              value: 2,
              label: "Ongoing",
            },
            {
              value: 3,
              label: "Completed",
            },
            {
              value: 0,
              label: "Cancelled",
            },
          ],
          required: false,
        },
      },
      {
        name: "Status",
        type: "select",
        form: {
          name: "status",
          allowClear: false,
          placeholder: "Status",
          value: 1,
          options: [
            {
              value: 1,
              label: "Active",
            },
            {
              value: 0,
              label: "In-active",
            },
          ],
          required: false,
        },
      },
    ],
  },
];

export default forms;
