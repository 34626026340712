import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_item = async (item) => {
  try {
    let data = new FormData();
    data.append("image", item?.image[0]);
    data.append("name", item?.name);
    data.append("barcode", item?.barcode);
    data.append("unit", item?.unit);
    data.append("category", item?.category);
    data.append("brand", item?.brand);
    data.append("stock", item?.stock);
    data.append("expiry", item?.expiry);
    data.append("type", item?.type);
    data.append("status", item?.status);
    data.append("details", JSON?.stringify(item?.details));

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/create-item`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_item = async (item) => {
  try {
    let data = new FormData();
    data.append("id", item?.id);
    data.append("image", item?.image[0]);
    data.append("name", item?.name);
    data.append("barcode", item?.barcode);
    data.append("unit", item?.unit);
    data.append("category", item?.category);
    data.append("brand", item?.brand);
    data.append("stock", item?.stock);
    data.append("expiry", item?.expiry);
    data.append("type", item?.type);
    data.append("status", item?.status);
    data.append("details", JSON?.stringify(item?.details));

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/update-item`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_item = async (item) => {
  try {
    const data = {
      id: item?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-item`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_items = async (item) => {
  try {
    const data = {
      search: item?.search,
      sort: item?.sort,
      unit: item?.unit,
      category: item?.category,
      brand: item?.brand,
      type: item?.type,
      status: item?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-all-items`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_item_unit_detail = async (item) => {
  try {
    const data = {
      id: item?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-item-unit-detail`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export {
  create_item,
  update_item,
  get_item,
  get_all_items,
  get_item_unit_detail,
};
