import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_item_unit = async (unit) => {
  try {
    const data = {
      name: unit?.name,
      status: unit?.status,
      branch: unit?.branch,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/create-item-unit`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_item_unit = async (unit) => {
  try {
    const data = {
      id: unit?.id,
      name: unit?.name,
      status: unit?.status,
      branch: unit?.branch,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/update-item-unit`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_item_unit = async (unit) => {
  try {
    const data = {
      id: unit?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-item-unit`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_item_units = async (unit) => {
  try {
    const data = {
      search: unit?.search,
      status: unit?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-item-units`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_item_unit_log = async (unit) => {
  try {
    const data = {
      search: unit?.search,
      sort: unit?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-item-unit-log`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_item_unit_logs = async (unit) => {
  try {
    const data = {
      search: unit?.search,
      sort: unit?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-item-unit-logs`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export {
  create_item_unit,
  update_item_unit,
  get_item_unit,
  get_all_item_units,
  get_item_unit_log,
  get_all_item_unit_logs,
};
