import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import toast from "react-hot-toast";
import Header from "../../../../Componets/Header";
import {
  create_project_job,
  get_project_job,
  update_project_job,
} from "../../../../Controllers/Projects/Jobs";
import forms from "../../../../Data/Forms/Projects/Jobs/forms";
import headers from "../../../../Data/Forms/Projects/Jobs/headers";
import Loadercomponent from "../../../../Componets/Loader";
import { convert_to_file } from "../../../../Controllers/Global";

const ProjectsjobsForm = () => {
  const { project, id } = useParams();
  let navigate = useNavigate();

  headers.navigate.path[0].path = `/app/projects/jobs/${project}`;

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
      setButtonLoader(true);

      const job = id
        ? await update_project_job({ ...data?.data, id: id, project: project })
        : await create_project_job({ ...data?.data, project: project });

      if (job?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(job?.message);
        setButtonLoader(false);
      } else {
        toast?.error(job?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = async (job) => {
    let image = job?.image ? job?.image : "";
    let file = await convert_to_file(image);
    let name = job?.name ? job?.name : "";
    let description = job?.description ? job?.description : "";
    let number = job?.number ? job?.number : "";
    let status = job?.status ? job?.status : 0;

    values[0].options[0].form.value = file;
    values[0].options[1].form.value = name;
    values[0].options[2].form.value = description;
    values[0].options[3].form.value = number;

    values[1].options[0].form.value = status;

    setLoader(false);
    setValues([...values]);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      const data = {
        id: id,
      };

      const job = await get_project_job(data);
      if (job?.status) {
        handleData(job?.data);
      } else {
        toast?.error(job?.message);
      }
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        />
      </Loadercomponent>
    </Header>
  );
};

export default ProjectsjobsForm;
