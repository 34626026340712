import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_employee = async (employee) => {
  try {
    let data = {
      name: employee?.name,
      email: employee?.email,
      phone: employee?.phone,
      role: employee?.role,
      working_hours: employee?.working_hours,
      working_cost: employee?.working_cost,
      area: employee?.area,
      city: employee?.city,
      state: employee?.state,
      country: employee?.country,
      status: employee?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/create-employee`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_employee = async (employee) => {
  try {
    let data = {
      id: employee?.id,
      name: employee?.name,
      email: employee?.email,
      phone: employee?.phone,
      role: employee?.role,
      working_hours: employee?.working_hours,
      working_cost: employee?.working_cost,
      area: employee?.area,
      city: employee?.city,
      state: employee?.state,
      country: employee?.country,
      status: employee?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/update-employee`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_employee = async (employee) => {
  try {
    let data = {
      id: employee?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-employee`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_employees = async (employee) => {
  try {
    const data = {
      search: employee?.search,
      role: employee?.role,
      status: employee?.status,
      sort: employee?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-employees`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_employee_log = async (employee) => {
  try {
    const data = {
      id: employee?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-employee-log`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_employee_log = async (employee) => {
  try {
    const data = {
      employee: employee?.employee,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-employee-log`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export {
  create_employee,
  update_employee,
  get_employee,
  get_all_employees,
  get_employee_log,
  get_all_employee_log,
};
