import React from "react";
import axios from "axios";
import APIURL from "../../Main/apiurl";

const Signup = async (signup) => {
  try {
    const data = {
      name: signup?.name,
      phone: signup?.phone,
      email: signup?.email,
      password: signup?.password,
      company: signup?.company,
      country: signup?.country,
    };

    const response = await axios.post(`${APIURL}/signup`, data);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const Signin = async (signup) => {
  try {
    const data = {
      email: signup?.email,
      password: signup?.password,
    };

    const response = await axios.post(`${APIURL}/signin`, data);

    if (response?.data?.status) {
      localStorage.setItem("user", response?.data?.data?.token);
    }
    return response?.data;
  } catch (error) {
    return error;
  }
};

const Signout = async (signout) => {
  try {
    const data = {};

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/signout`, data, config);

    return response?.data;
  } catch (errors) {
    return errors;
  }
};

export { Signup, Signin, Signout };
