import React from "react";

const ValueTag = (props) => {
  let value = props?.value;
  let color = props?.color;
  let tag = props?.tag;

  return (
    <div>
      <span>{value}</span>
      <span
        className={`ml-2 bg-${color}-200 text-${color}-800 text-xs text-medium rounded-lg py-1 px-2`}
      >
        {tag}
      </span>
    </div>
  );
};

export default ValueTag;
