import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import toast from "react-hot-toast";
import Header from "../../../../Componets/Header";
import forms from "../../../../Data/Forms/Contractors/Contractors/forms";
import headers from "../../../../Data/Forms/Contractors/Contractors/headers";
import Loadercomponent from "../../../../Componets/Loader";
import {
  create_contractor,
  get_contractor,
  update_contractor,
} from "../../../../Controllers/Contractors";

const ContractorsForm = () => {
  let { id } = useParams();

  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
      setButtonLoader(true);

      const contractors = id
        ? await update_contractor({ id: id, ...data?.data })
        : await create_contractor(data?.data);

      if (contractors?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(contractors?.message);
        setButtonLoader(false);
      } else {
        toast?.error(contractors?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = (contractor) => {
    let name = contractor?.name ? contractor?.name : "";
    let email = contractor?.email ? contractor?.email : "";
    let phone = contractor?.phone ? contractor?.phone : "";
    let tax = contractor?.tax ? contractor?.tax : 0;
    let area = contractor?.area ? contractor?.area : "";
    let city = contractor?.city ? contractor?.city : "";
    let state = contractor?.state ? contractor?.state : "";
    let country = contractor?.country ? contractor?.country : "";
    let status = contractor?.status ? contractor?.status : 0;

    let catalog = [];
    let catalog_options = [];
    if (contractor?.catalog?.length > 0) {
      contractor?.catalog?.map((value, index) => {
        catalog?.push(value?._id);
        catalog_options?.push({
          value: value?._id,
          label: value?.name,
        });
      });
    } else {
      catalog_options = null;
      catalog = null;
    }

    values[0].options[0].form.value = name;

    values[1].options[0].form.value = email;
    values[1].options[1].form.value = phone;

    values[2].options[0].form.value = catalog;
    values[2].options[0].form.options = catalog_options;

    values[3].options[0].form.value = tax;

    values[4].options[0].form.value = area;
    values[4].options[1].form.value = city;
    values[4].options[2].form.value = state;
    values[4].options[3].form.value = country;

    values[5].options[0].form.value = status;

    setLoader(false);
    setValues([...values]);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      headers.navigate.path[1].name = "Edit";

      const data = {
        id: id,
      };

      const contractors = await get_contractor(data);

      if (contractors?.status) {
        handleData(contractors?.data);
      } else {
        toast?.error(contractors?.message);
      }
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [buttonLoader]);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        />
      </Loadercomponent>
    </Header>
  );
};

export default ContractorsForm;
