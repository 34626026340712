import { AutoComplete, DatePicker, Form, Input, Select } from "antd";
import React, { createRef, useEffect, useRef, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";
import AddButton from "../../../../Componets/Buttons/add";
import { select_search, select_id } from "../../../../Controllers/Global";
import moment from "moment";

const Projecttimesheetemployeestable = React.forwardRef((props, ref) => {
  const {
    employeeDetails,
    setEmployeeDetails,
    employeeTotals,
    setEmployeeTotals,
  } = props;

  const handleTotal = (e, index, key) => {
    let name = key ? key : e?.target?.name;
    let value = key && e ? e : !key && e?.target?.value ? e?.target?.value : "";

    if (value >= 0) {
      let data = [...employeeDetails];

      let data_working_cost = data[index]?.working_cost
        ? data[index]?.working_cost
        : 0;
      let data_hours = data[index]?.hours ? data[index]?.hours : 0;
      let data_remaining = data[index]?.remaining ? data[index]?.remaining : 0;
      let data_worked = data[index]?.worked ? data[index]?.worked : 0;

      let working_cost = name === "working_cost" ? value : data_working_cost;
      let hours = name === "hours" ? value : data_hours;
      let remaining = name === "remaining" ? value : data_remaining;
      let worked = name === "worked" ? value : data_worked;

      if (
        parseFloat(worked) <= parseFloat(remaining) ||
        isNaN(parseFloat(worked))
      ) {
        let total = parseFloat(working_cost) * parseFloat(worked ? worked : 0);
        data[index][name] = value;
        data[index].total = total ? total?.toFixed(3) : 0;

        let grandtotal = data?.reduce(
          (acc, currentValue) =>
            parseFloat(acc) + parseFloat(currentValue?.total),
          0
        );

        employeeTotals.total = grandtotal;

        setEmployeeDetails(data);
        setEmployeeTotals({ ...employeeTotals });
      } else {
        toast?.error(`Remaining only ${remaining}`);
      }
    }
  };

  return (
    <div>
      <table className="w-full">
        <thead>
          <tr>
            <td className="border p-4 text-black">S.No</td>
            <td className="border p-4 text-black">Description</td>
            <td className="border p-4 text-black">Role</td>
            <td className="border p-4 text-black">Working cost</td>
            <td className="border p-4 text-black">Hours</td>
            <td className="border p-4 text-black">Remaining</td>
            <td className="border p-4 text-black">Worked</td>
            <td className="border p-4 text-black">Total</td>
          </tr>
        </thead>
        <tbody>
          {employeeDetails?.map((value, index) => {
            return (
              <React.Fragment>
                <tr>
                  <td className="border p-4 text-center">{index + 1}</td>
                  <td className="border p-4">
                    {JSON?.parse(value?.description)?.name}
                  </td>
                  <td className="border p-4 text-center">{value?.role}</td>
                  <td className="border p-4 text-center">
                    {value?.working_cost}
                  </td>
                  <td className="border p-4 text-center">{value?.hours}</td>
                  <td className="border p-4 text-center">{value?.remaining}</td>
                  <td className="border p-4 text-center">
                    <Input
                      type="number"
                      className="w-full"
                      name="worked"
                      placeholder="Worked"
                      value={value?.worked}
                      onChange={(e) => handleTotal(e, index)}
                      autoComplete="off"
                      required="required"
                    />
                  </td>
                  <td className="border p-4 text-center">
                    {parseFloat?.(value?.total)?.toFixed(3)}
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td className="border p-4" colSpan={6}></td>
            <td className="border p-4 text-black text-medium">Total</td>
            <td className="border p-4 text-black text-medium" colSpan={2}>
              {employeeTotals?.total > 0
                ? employeeTotals?.total?.toFixed(3)
                : "0.000"}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
});

export default Projecttimesheetemployeestable;
