import React, { useEffect, useState } from "react";
import Header from "../../../../Componets/Header";
import TableImage from "../../../../Componets/Image";
import Status from "../../../../Componets/Status";
import ActionButton from "../../../../Componets/Buttons/action";
import Table from "../../../../Componets/Table";
import { get_all_items } from "../../../../Controllers/Items/Items";
import toast from "react-hot-toast";
import tables from "../../../../Data/Tables/Items/Items/tables";
import headers from "../../../../Data/Tables/Items/Items/headers";
import ValueTag from "../../../../Componets/ValueTag";
import Loadercomponent from "../../../../Componets/Loader";
import { type } from "@testing-library/user-event/dist/type";

const ItemsTable = () => {
  const [loader, setLoader] = useState(false);
  const [body, setBody] = useState();

  let tag = (stock, notify) => {
    let data = "Stocked";

    if (stock == 0) {
      data = "Out of Stock";
    } else if (stock <= notify) {
      data = "Low";
    }

    return data;
  };
  let color = (stock, notify) => {
    let data = "green";

    if (stock == 0) {
      data = "red";
    } else if (stock <= notify) {
      data = "orange";
    }

    return data;
  };

  const formdata = (data) => {
    fetchData(data);
  };

  const actiondata = (data) => {
    fetchData(data);
  };

  const fetchData = async (tabledata) => {
    try {
      setLoader(true);

      const data = {
        search: tabledata?.search,
        sort: tabledata?.sort,
        unit: tabledata?.unit,
        category: tabledata?.category,
        brand: tabledata?.brand,
        type: tabledata?.type,
        status: tabledata?.status,
      };

      const items = await get_all_items(data);
      if (items?.status) {
        let data = [];

        items?.data?.map((value, index) => {
          data?.push({
            Name: <TableImage image={value?.image} name={value?.name} />,
            Unit: value?.unit?.name,
            Category: value?.category?.name,
            Brand: value?.brand?.name,
            Price: value?.sale_price,
            Stock: value?.type ? (
              // <ValueTag
              //   value={value?.stock}
              //   tag={tag(value?.stock, value?.notify)}
              //   color={color(value?.stock, value?.notify)}
              // />
              <ValueTag value={0} tag={tag(0, 0)} color={color(0, 0)} />
            ) : (
              <ValueTag value={""} tag={"Nonstockable"} color={"indigo"} />
            ),
            Status: <Status status={value?.status} />,
            Action: (
              <ActionButton
                edit={`/app/items/edit/${value?._id}`}
                id={value?._id}
                api="delete-item"
                actiondata={actiondata}
              />
            ),
          });
        });

        setBody(data);
        setLoader(false);
      } else {
        toast?.error(items?.message);
        setLoader(false);
      }
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers} formdata={formdata}>
      <Loadercomponent loader={loader}>
        <Table head={tables} body={body} />
      </Loadercomponent>
    </Header>
  );
};

export default ItemsTable;
