import {
  CalendarOutlined,
  EnvironmentOutlined,
  FormOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Progress } from "antd";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import URL from "../../../../Main/url";
import moment from "moment";

const ProjectsTimesheetsTab = (props) => {
  const { projectData } = props;

  let { project } = useParams();
  let navigate = useNavigate();

  return (
    <div>
      {/* Header Topbar End*/}
      <div className="">
        <div className="bg-white p-4 rounded-md">
          <div className="flex justify-between">
            <div>
              <div className="flex">
                <img
                  src={`${URL}/uploads${projectData?.image}`}
                  className="w-[125px] h-[125px] rounded-md"
                />
                <div className="pl-4 text-sm text-gray-700">
                  <span className="text-base text-medium text-black pr-2">
                    {projectData?.name}
                  </span>
                  <span className="bg-gray-200 text-sm px-2 py-1 rounded-lg text-gray-500">
                    {`#PNO${projectData?.number}`}
                  </span>
                  <div className="pt-1">
                    <UserOutlined />
                    <span className="pl-2 ">{projectData?.customer?.name}</span>
                  </div>
                  <div>
                    <PhoneOutlined style={{ transform: "rotate(90deg)" }} />
                    <span className="pl-2">{projectData?.customer?.phone}</span>
                  </div>
                  <div>
                    <MailOutlined />
                    <span className="pl-2">{projectData?.customer?.email}</span>
                  </div>
                  <div>
                    <EnvironmentOutlined />
                    <span className="pl-2">
                      {projectData?.area && projectData?.area + ", "}
                      {projectData?.city && projectData?.city + ", "}
                      {projectData?.state && projectData?.state + ", "}
                      {projectData?.country && projectData?.country}
                    </span>
                  </div>
                  <div>
                    <CalendarOutlined />
                    <span className="px-2">
                      {moment?.(projectData?.start_date)?.format?.(
                        "MMMM Do YYYY"
                      )}
                      &nbsp;-&nbsp;
                      {moment?.(projectData?.end_date)?.format?.(
                        "MMMM Do YYYY"
                      )}
                    </span>
                    {new Date(projectData?.end_date) < new Date() ? (
                      <span className="px-2 text-xs text-medium bg-rose-500 text-white rounded-full">
                        Delayed
                      </span>
                    ) : (
                      <span className="px-2 text-xs text-medium bg-green-500 text-white rounded-full">
                        Ontime
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div>
                <FormOutlined
                  className="text-gray-500 text-xl hover:text-[#2D53DA]"
                  onClick={() =>
                    navigate(`/app/projects/edit/${projectData?._id}`)
                  }
                />
              </div>
            </div>
          </div>
          {/* Progress */}
          <div className="pt-4">
            <Progress
              className="text-medium"
              strokeColor="#10B981"
              percent={project?.progress}
              strokeWidth={6}
            />
          </div>
        </div>
      </div>

      {/* tabs */}
      <div className="py-4">
        <div className="bg-white rounded-md">
          <div className="flex justify-between">
            <div
              className="text-medium text-sm w-full hover:bg-gray-100 p-4 cursor-pointer"
              onClick={() => navigate(`/app/projects/jobs/${project}`)}
            >
              <div className="text-center uppercase">Jobs (5)</div>
            </div>
            <div
              className="text-medium text-sm w-full hover:bg-gray-100 p-4 cursor-pointer"
              onClick={() => navigate(`/app/projects/tasks/${project}`)}
            >
              <div className="text-center uppercase">Tasks (5)</div>
            </div>
            <div
              className="text-medium text-sm w-full bg-gray-300 p-4 cursor-pointer"
              onClick={() => navigate(`/app/projects/timesheets/${project}`)}
            >
              <div className="text-center uppercase">Timesheet (4)</div>
            </div>
            <div
              className="text-medium text-sm w-full hover:bg-gray-100 p-4 cursor-pointer"
              onClick={() => navigate(`/app/projects/issues/${project}`)}
            >
              <div className="text-center uppercase">Issues (2)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsTimesheetsTab;
