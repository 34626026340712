import { createBrowserRouter } from "react-router-dom";
import Layout from "../Componets/Layout";
import Dashboard from "../Pages/Dashboard";
import Reports from "../Pages/Reports";
import Quotes from "../Pages/Quotes";
import AddQuotes from "../Pages/Quotes/AddQuotes";
import QuotationPage from "../Pages/Quotes/AddQuotes/page";
import ViewReports from "../Pages/Reports/ViewReports";
import SuppliersForm from "../Pages/Suppliers/Suppliers/Form";
import SuppliersTable from "../Pages/Suppliers/Suppliers/Table";
import CustomersTable from "../Pages/Customers/Customers/Table";
import CustomersForms from "../Pages/Customers/Customers/Form";
import ContractorsTable from "../Pages/Contractors/Contractors/Table";
import ContractorsForm from "../Pages/Contractors/Contractors/Form";
import EmployeesForm from "../Pages/Employees/Employees/Form";
import EmployeesTable from "../Pages/Employees/Employees/Table";
import Home from "../Pages/Home";
import SignupForm from "../Pages/Signup/Form";
import SigninForm from "../Pages/Signin/Form";
import CategoriesTable from "../Pages/Items/Categories/Table";
import CategoriesForm from "../Pages/Items/Categories/Form";
import UnitsTable from "../Pages/Items/Units/Table";
import UnitsForm from "../Pages/Items/Units/Form";
import BrandsTable from "../Pages/Items/Brands/Table";
import BrandsForm from "../Pages/Items/Brands/Form";
import ItemsTable from "../Pages/Items/Items/Table";
import ItemsForm from "../Pages/Items/Items/Form";
import RolesForm from "../Pages/Employees/Roles/Form";
import RolesTable from "../Pages/Employees/Roles/Table";
import Page404 from "../Pages/Results/Page404";
import SettingsTable from "../Pages/Settings/Settings/Table";
import GeneralForm from "../Pages/Settings/General/Form";
import UsersTable from "../Pages/Settings/Users/Table";
import UsersForm from "../Pages/Settings/Users/Form";
import BranchesTable from "../Pages/Settings/Branches/Table";
import BranchesForm from "../Pages/Settings/Branches/Form";
import SettingRolesTable from "../Pages/Settings/Roles/Table";
import SettingRolesForm from "../Pages/Settings/Roles/Form";
import ExpensesTable from "../Pages/Expenses/Expenses/Table";
import ExpensesForm from "../Pages/Expenses/Expenses/Form";
import ExpensecategoriesForm from "../Pages/Expenses/Categories/Form";
import ExpensecategoriesTable from "../Pages/Expenses/Categories/Table";
import Boq from "../Pages/Quotes/Boq";
import AddboqForm from "../Pages/Quotes/Boq/form";
import BoqsForm from "../Pages/Sales/Boqs/Form";
import BoqsTable from "../Pages/Sales/Boqs/Table";
import QuotesTable from "../Pages/Sales/Quotes/Table";
import QuotesForm from "../Pages/Sales/Quotes/Form";
import InvoicesTable from "../Pages/Sales/Invoices/Table";
import InvoicesForm from "../Pages/Sales/Invoices/Form";
import PurchasesOrderTable from "../Pages/Purchases/Orders/Table";
import PurchasesOrderForm from "../Pages/Purchases/Orders/Form";
import BoqPage from "../Pages/Quotes/Boq/view";
import PurchasePage from "../Pages/Purchases/Addpurchases";
import ProjectsTable from "../Pages/Projects/Projects/Table";
import ProjectsForm from "../Pages/Projects/Projects/Form";
import ProjectsjobsTable from "../Pages/Projects/Jobs/Table";
import ProjectsjobsForm from "../Pages/Projects/Jobs/Form";
import ProjectsTasksTable from "../Pages/Projects/Tasks/Table";
import ProjectsTasksForm from "../Pages/Projects/Tasks/Form";
import ProjectsTimesheetsForm from "../Pages/Projects/Timesheet/Form";
import ProjectsTimesheetsTable from "../Pages/Projects/Timesheet/Table";
import ProjectsIssuesTable from "../Pages/Projects/Issues/Table";
import ProjectsIssuesForm from "../Pages/Projects/Issues/Form";

const router = createBrowserRouter([
  {
    path: "*",
    element: <Page404 />,
  },
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/signup",
    element: <SignupForm />,
  },
  {
    path: "/signin",
    element: <SigninForm />,
  },
  {
    path: "/app",
    element: <Layout />,
    children: [
      {
        path: "/app/dashboard",
        element: <Dashboard />,
      },

      //projects
      {
        path: "/app/projects",
        element: <ProjectsTable />,
      },
      {
        path: "/app/projects/add",
        element: <ProjectsForm />,
      },
      {
        path: "/app/projects/edit/:id",
        element: <ProjectsForm />,
      },

      {
        path: "/app/projects/jobs/:project",
        element: <ProjectsjobsTable />,
      },
      {
        path: "/app/projects/jobs/add/:project",
        element: <ProjectsjobsForm />,
      },
      {
        path: "/app/projects/jobs/edit/:project/:id",
        element: <ProjectsjobsForm />,
      },

      {
        path: "/app/projects/tasks/:project",
        element: <ProjectsTasksTable />,
      },
      {
        path: "/app/projects/tasks/add/:project",
        element: <ProjectsTasksForm />,
      },
      {
        path: "/app/projects/tasks/edit/:project/:id",
        element: <ProjectsTasksForm />,
      },

      {
        path: "/app/projects/timesheets/:project",
        element: <ProjectsTimesheetsTable />,
      },
      {
        path: "/app/projects/timesheets/add/:project",
        element: <ProjectsTimesheetsForm />,
      },
      {
        path: "/app/projects/timesheets/edit/:project/:id",
        element: <ProjectsTimesheetsForm />,
      },

      {
        path: "/app/projects/issues/:project",
        element: <ProjectsIssuesTable />,
      },
      {
        path: "/app/projects/issues/add/:project",
        element: <ProjectsIssuesForm />,
      },
      {
        path: "/app/projects/issues/edit/:project/:id",
        element: <ProjectsIssuesForm />,
      },

      // sales
      {
        path: "/app/sales",
        element: <InvoicesTable />,
      },
      {
        path: "/app/sales/invoices/add",
        element: <InvoicesForm />,
      },
      {
        path: "/app/sales/invoices/edit/:id",
        element: <InvoicesForm />,
      },
      {
        path: "/app/sales/quotes",
        element: <QuotesTable />,
      },
      {
        path: "/app/sales/quotes/add",
        element: <QuotesForm />,
      },
      {
        path: "/app/sales/quotes/edit/:id",
        element: <QuotesForm />,
      },
      {
        path: "/app/sales/boqs",
        element: <BoqsTable />,
      },
      {
        path: "/app/sales/boqs/add",
        element: <BoqsForm />,
      },
      {
        path: "/app/sales/boqs/edit/:id",
        element: <BoqsForm />,
      },

      {
        path: "/app/items",
        element: <ItemsTable />,
      },
      {
        path: "/app/items/add",
        element: <ItemsForm />,
      },
      {
        path: "/app/items/edit/:id",
        element: <ItemsForm />,
      },
      {
        path: "/app/items/units",
        element: <UnitsTable />,
      },
      {
        path: "/app/items/units/add",
        element: <UnitsForm />,
      },
      {
        path: "/app/items/units/edit/:id",
        element: <UnitsForm />,
      },
      {
        path: "/app/items/categories",
        element: <CategoriesTable />,
      },
      {
        path: "/app/items/categories/add",
        element: <CategoriesForm />,
      },
      {
        path: "/app/items/categories/edit/:id",
        element: <CategoriesForm />,
      },
      {
        path: "/app/items/brands",
        element: <BrandsTable />,
      },
      {
        path: "/app/items/brands/add",
        element: <BrandsForm />,
      },
      {
        path: "/app/items/brands/edit/:id",
        element: <BrandsForm />,
      },

      {
        path: "/app/employees",
        element: <EmployeesTable />,
      },
      {
        path: "/app/employees/add",
        element: <EmployeesForm />,
      },
      {
        path: "/app/employees/edit/:id",
        element: <EmployeesForm />,
      },
      {
        path: "/app/employees/roles",
        element: <RolesTable />,
      },
      {
        path: "/app/employees/roles/add",
        element: <RolesForm />,
      },
      {
        path: "/app/employees/roles/edit/:id",
        element: <RolesForm />,
      },

      {
        path: "/app/customers",
        element: <CustomersTable />,
      },
      {
        path: "/app/customers/add",
        element: <CustomersForms />,
      },
      {
        path: "/app/customers/edit/:id",
        element: <CustomersForms />,
      },
      {
        path: "/app/suppliers",
        element: <SuppliersTable />,
      },
      {
        path: "/app/suppliers/add",
        element: <SuppliersForm />,
      },
      {
        path: "/app/suppliers/edit/:id",
        element: <SuppliersForm />,
      },

      {
        path: "/app/quotes",
        element: <Quotes />,
      },
      {
        path: "/app/quotes/add",
        element: <AddQuotes />,
      },
      {
        path: "/app/quotes/boq",
        element: <Boq />,
      },
      {
        path: "/app/quotes/boq/add",
        element: <AddboqForm />,
      },

      {
        path: "/app/quotes/boq/view",
        element: <BoqPage />,
      },
      {
        path: "/app/quotes/view",
        element: <QuotationPage />,
      },

      {
        path: "/app/purchases",
        element: <PurchasesOrderTable />,
      },
      {
        path: "/app/purchases/orders/add",
        element: <PurchasesOrderForm />,
      },
      {
        path: "/app/purchases/orders/edit/:id",
        element: <PurchasesOrderForm />,
      },
      {
        path: "/app/purchases/view",
        element: <PurchasePage />,
      },

      {
        path: "/app/expenses",
        element: <ExpensesTable />,
      },
      {
        path: "/app/expenses/add",
        element: <ExpensesForm />,
      },
      {
        path: "/app/expenses/edit/:id",
        element: <ExpensesForm />,
      },
      {
        path: "/app/expenses/categories",
        element: <ExpensecategoriesTable />,
      },
      {
        path: "/app/expenses/categories/add",
        element: <ExpensecategoriesForm />,
      },
      {
        path: "/app/expenses/categories/edit/:id",
        element: <ExpensecategoriesForm />,
      },
      {
        path: "/app/contractors",
        element: <ContractorsTable />,
      },
      {
        path: "/app/contractors/add",
        element: <ContractorsForm />,
      },
      {
        path: "/app/contractors/edit/:id",
        element: <ContractorsForm />,
      },

      {
        path: "/app/reports",
        element: <Reports />,
      },
      {
        path: "/app/reports/projects",
        element: <ViewReports />,
      },
      {
        path: "/app/reports/inventory",
        element: <ViewReports />,
      },
      {
        path: "/app/reports/employees",
        element: <ViewReports />,
      },
      {
        path: "/app/reports/customers",
        element: <ViewReports />,
      },
      {
        path: "/app/reports/suppliers",
        element: <ViewReports />,
      },
      {
        path: "/app/reports/quotes",
        element: <ViewReports />,
      },
      {
        path: "/app/reports/sales",
        element: <ViewReports />,
      },
      {
        path: "/app/reports/purchases",
        element: <ViewReports />,
      },
      {
        path: "/app/reports/contractors",
        element: <ViewReports />,
      },

      {
        path: "/app/settings",
        element: <SettingsTable />,
      },
      {
        path: "/app/settings/general",
        element: <GeneralForm />,
      },
      {
        path: "/app/settings/users",
        element: <UsersTable />,
      },
      {
        path: "/app/settings/users/add",
        element: <UsersForm />,
      },
      {
        path: "/app/settings/branches",
        element: <BranchesTable />,
      },
      {
        path: "/app/settings/branches/add",
        element: <BranchesForm />,
      },
      {
        path: "/app/settings/branches/edit/:id",
        element: <BranchesForm />,
      },
      {
        path: "/app/settings/roles",
        element: <SettingRolesTable />,
      },
      {
        path: "/app/settings/roles/add",
        element: <SettingRolesForm />,
      },
    ],
  },
]);

export default router;
