const headers = {
  name: "Project jobs",
  components: [
    {
      type: "search",
      name: "search",
      placeholder: "Search",
      value: "",
    },
    {
      type: "select",
      name: "sort",
      placeholder: "Sort",
      allowClear: true,
      showSearch: false,
      filterOption: true,
      value: null,
      options: [
        { value: 0, label: "Name (A-Z)" },
        { value: 1, label: "Name (Z-A)" },
        { value: 2, label: "Est.cost (Low)" },
        { value: 3, label: "Est.cost (High)" },
        { value: 4, label: "Start (Old)" },
        { value: 5, label: "Start (New)" },
        { value: 6, label: "End (Old)" },
        { value: 7, label: "End (New)" },
      ],
    },
    {
      type: "filter",
      name: "job_status",
      placeholder: "Job status",
      value: null,
      allowClear: true,
      showSearch: false,
      filterOption: true,
      options: [
        {
          value: 1,
          label: "Pending",
        },
        {
          value: 2,
          label: "Ongoing",
        },
        {
          value: 3,
          label: "Completed",
        },
        {
          value: 0,
          label: "Cancelled",
        },
      ],
    },
    {
      type: "filter",
      name: "status",
      placeholder: "Status",
      value: null,
      allowClear: true,
      showSearch: false,
      filterOption: true,
      options: [
        { value: 0, label: "Inactive" },
        { value: 1, label: "Active" },
      ],
    },

    {
      type: "range",
      name: "start_date",
      value: {
        start: "",
        end: "",
      },
    },
    {
      type: "range",
      name: "end_date",
      value: {
        start: "",
        end: "",
      },
    },
    {
      type: "add",
      path: "/app/projects/jobs/add",
    },
  ],
  navigate: {
    tabs: [
      {
        name: "Projects",
        path: "/app/projects",
      },
      {
        name: "Jobs",
        path: "",
      },
    ],
    total: [
      { value: 0, label: "Total" },
      { value: 0, label: "Active" },
      { value: 0, label: "Inactive" },
      { value: 0, label: "Stockable" },
      { value: 0, label: "Non-Stockable" },
    ],
  },
};

export default headers;
