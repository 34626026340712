import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_boq = async (boq) => {
  try {
    const data = {
      customer: boq?.customer,
      project: boq?.project,
      number: boq?.number,
      date: boq?.date,
      due_date: boq?.due_date,
      status: boq?.status,
      details: boq?.details,
      subtotal: boq?.subtotal,
      taxamount: boq?.taxamount,
      discount: boq?.discount,
      delivery: boq?.delivery,
      delivery_status: boq?.delivery_status,
      delivery_date: boq?.delivery_date,
      payment_status: boq?.payment_status,
      payment_types: JSON?.stringify(boq?.payment_types),
      payments: JSON?.stringify(boq?.payments),
      total: boq?.total,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/create-boq`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_boq = async (boq) => {
  try {
    const data = {
      id: boq?.id,
      customer: boq?.customer,
      project: boq?.project,
      number: boq?.number,
      date: boq?.date,
      due_date: boq?.due_date,
      status: boq?.status,
      details: boq?.details,
      subtotal: boq?.subtotal,
      taxamount: boq?.taxamount,
      discount: boq?.discount,
      delivery: boq?.delivery,
      delivery_status: boq?.delivery_status,
      delivery_date: boq?.delivery_date,
      payment_status: boq?.payment_status,
      payment_types: JSON?.stringify(boq?.payment_types),
      payments: JSON?.stringify(boq?.payments),
      total: boq?.total,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/update-boq`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const delete_boq = async (boq) => {
  try {
    const data = {
      id: boq?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/delete-boq`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_boq = async (boq) => {
  try {
    const data = {
      id: boq?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-boq`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_boqs = async (boq) => {
  try {
    const data = {
      search: boq?.search,
      status: boq?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-all-boqs`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_boq_log = async (boq) => {
  try {
    const data = {
      search: boq?.search,
      sort: boq?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-boq-log`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_boq_logs = async (boq) => {
  try {
    const data = {
      search: boq?.search,
      sort: boq?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-boq-logs`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export {
  create_boq,
  update_boq,
  delete_boq,
  get_boq,
  get_all_boqs,
  get_boq_log,
  get_all_boq_logs,
};
