import {
  LoadingOutlined,
  PlusOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { Button, DatePicker, Input, Radio, Select, Upload } from "antd";
import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import QuoteTable from "./table";
import toast from "react-hot-toast";

const AddQuotesForm = () => {
  let navigate = useNavigate();
  let location = useLocation();

  let data = {
    from: "Supplier",
    number: "",
    date: "",
    due_date: null,
    supplier: null,
    supplier_options: [],
    contractor: null,
    contractor_options: [],
    items: [],
    details: [
      {
        item: "",
        units: "",
        price: "",
        quantity: "",
        total: "",
      },
    ],
    total: "",
    tax: "",
    amount: "",
  };

  const [form, setForm] = useState(data);

  return (
    <div class="my-8">
      <div class="bg-white border rounded-md p-8 w-[80%] mx-auto">
        {/* detail */}
        <div>
          <div className="flex justify-between pb-5">
            <div className="text-medium  ">Details</div>
            {/* <div className="text-medium text-2xl">
              <PrinterOutlined onClick={() => navigate("/app/quotes/view")} />
            </div> */}
          </div>
          <div className="border text-sm text-medium text-gray-500 rounded-md p-4">
            <div>
              {location?.pathname == "/app/sales/add"
                ? "Invoice Number"
                : "Quote Number"}
            </div>
            <div>
              <Input
                className="w-full my-3 text-medium"
                placeholder={
                  location?.pathname == "/app/sales/add"
                    ? "Invoice Number"
                    : "Quotation Number"
                }
              />
            </div>
            <div>Project</div>
            <div>
              <Select
                className="w-full my-3 text-medium"
                placeholder="Project number"
                allowClear
                showSearch
                options={[
                  { value: "#PNO18501", label: "#PNO18501" },
                  { value: "#PNO18502", label: "#PNO18502" },
                  { value: "#PNO18503", label: "#PNO18503" },
                  { value: "#PNO18504", label: "#PNO18504" },
                  { value: "#PNO18505", label: "#PNO18505" },
                  { value: "#PNO18506", label: "#PNO18506" },
                ]}
              />
            </div>
            <div>Date</div>
            <div>
              <DatePicker
                className="w-full my-3 text-medium"
                placeholder="Date"
              />
            </div>
            <div>Due Date</div>
            <div>
              <DatePicker
                className="w-full my-3 text-medium"
                placeholder="Due Date"
              />
            </div>
            <div>Customer</div>
            <div>
              <Select
                className="w-full my-3 text-medium"
                placeholder="Select Customer"
                options={[
                  {
                    value: "BrightWave Solutions",
                    name: "BrightWave Solutions",
                  },
                  { value: "NovaGenix Labs", name: "NovaGenix Labs" },
                  { value: "InfinityWorks Inc.", name: "InfinityWorks Inc." },
                  { value: "NexusGen Biotech", name: "NexusGen Biotech" },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="pt-6">
          <div className="text-medium  pb-3">Items</div>
          <div className="border text-sm text-medium text-gray-500 rounded-md p-4">
            <QuoteTable form={form} setForm={setForm} />
          </div>
        </div>
        {/* Payment */}
        {/* <div className="pt-6">
          <div className="text-medium  pb-3">Payment</div>
          <div className="border text-sm text-medium text-gray-500 rounded-md p-4">
            <Radio.Group
              className="w-full"
              defaultValue="a"
              buttonStyle="solid"
            >
              <Radio.Button className="text-medium" value="a">
                Paid
              </Radio.Button>
              <Radio.Button className="text-medium" value="b">
                Unpaid
              </Radio.Button>
            </Radio.Group>
          </div>
        </div> */}

        {/* Status */}
        {/* <div className="pt-6">
          <div className="text-medium  pb-3">Satus</div>
          <div className="border text-sm text-medium text-gray-500 rounded-md p-4">
            <Radio.Group
              className="w-full"
              defaultValue="a"
              buttonStyle="solid"
            >
              <Radio.Button className="text-medium" value="a">
                Received
              </Radio.Button>
              <Radio.Button className="text-medium" value="b">
                Pending
              </Radio.Button>
            </Radio.Group>
          </div>
        </div> */}

        {/* submit */}
        <div className="py-6">
          <div className="float-end">
            <Button className="mr-2 text-medium" onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button
              className="text-medium"
              type="primary"
              onClick={() => {
                navigate(-1);
                toast?.success(
                  location?.pathname == "/app/sales/add"
                    ? "Invoice created"
                    : "Quotation created"
                );
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddQuotesForm;
