import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_project = async (project) => {
  try {
    let data = new FormData();
    data.append("image", project?.image[0]);
    data.append("number", project?.number);
    data.append("name", project?.name);
    data.append("customer", project?.customer);
    data.append("start_date", project?.start_date);
    data.append("end_date", project?.end_date);
    data.append("estimated", project?.estimated);
    data.append("area", project?.area);
    data.append("city", project?.city);
    data.append("state", project?.state);
    data.append("country", project?.country);
    data.append("project_status", project?.project_status);
    data.append("status", project?.status);

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/create-project`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_project = async (project) => {
  try {
    let data = new FormData();
    data.append("id", project?.id);
    data.append("image", project?.image[0]);
    data.append("number", project?.number);
    data.append("name", project?.name);
    data.append("customer", project?.customer);
    data.append("start_date", project?.start_date);
    data.append("end_date", project?.end_date);
    data.append("estimated", project?.estimated);
    data.append("area", project?.area);
    data.append("city", project?.city);
    data.append("state", project?.state);
    data.append("country", project?.country);
    data.append("project_status", project?.project_status);
    data.append("status", project?.status);

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/update-project`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_project = async (project) => {
  try {
    const data = {
      id: project?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-project`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_projects = async (project) => {
  try {
    const data = {
      search: project?.search,
      sort: project?.sort,
      customer: project?.customer,
      project_status: project?.project_status,
      start_date: project?.start_date,
      end_date: project?.end_date,
      status: project?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-projects`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export { create_project, update_project, get_project, get_all_projects };
