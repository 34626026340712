import React, { useEffect, useState } from "react";
import Sidebar from "./sidebar";
import Topbar from "./topbar";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import Authorization from "../../Main/authorization";
import toast from "react-hot-toast";
import { get_user_auth } from "../../Controllers/Layouts/Layouts";

const Layout = () => {
  let location = useLocation();

  let isAuthenticate = Authorization();

  const [loader, setLoader] = useState(false);
  const [body, setBody] = useState();

  const fetchData = async (tabledata) => {
    try {
      setLoader(true);

      const layouts = await get_user_auth();
      if (layouts?.status) {
        console.log(layouts?.data, "layoutslayouts");
        setBody(layouts?.data);
        setLoader(false);
      } else {
        toast?.error(layouts?.message);
        setLoader(false);
      }
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  useEffect(() => {
    if (isAuthenticate) {
      fetchData();
    }
  }, []);

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar body={body} />
      <div className="flex flex-col flex-1 overflow-hidden ml-64">
        <Topbar body={body} />
        <div className="flex-1 overflow-y-auto ">
          <h2 className="text-lg font-semibold mb-9">Main Content</h2>
          <div className="">
            {isAuthenticate && location?.pathname != "/app" ? (
              <Outlet />
            ) : isAuthenticate && location?.pathname == "/app" ? (
              <Navigate to={"/signin"} />
            ) : isAuthenticate && location?.pathname == "/app/" ? (
              <Navigate to={"/signin"} />
            ) : (
              <Navigate to={"/signin"} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
